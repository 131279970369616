<template>
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <div class="header-row">
      <h1 class="root-ctab-panel-h1">Transakcie klienta</h1>
      <div class="filters flex pb-2">
        <CDropdown show-clear v-model="selectedStrategy" :options="agentStrategy" optionLabel="name"
                   placeholder="Vyberte stratégiu" class="md:w-14rem custom-item-mobile custom-item-mobile-custom"/>
        <div class="calendar-filters flex">
          <Calendar placeholder="Dátum od" v-model="dateFrom" dateFormat="dd.mm.yy"
                    class="mr-2 ml-2 no-margin width-mobile-inputs" ref="calendarFromRef" showIcon/>
          <Calendar placeholder="Dátum do" v-model="dateTo" dateFormat="dd.mm.yy" class="width-mobile-inputs"
                    ref="calendarToRef" showIcon/>
        </div>
      </div>
    </div>
    <hr>
    <div class="root-ctab-panel-items space-x-5 mt-7 table-row-desktop">
      <Table
          v-if="filteredTransactions && filteredTransactions.length"
          :columns="transactionsColumns"
          :data="paginatedTransactions"
          :pagination="true"
      >
      </Table>
      <h1 v-if="!filteredTransactions || !filteredTransactions.length" class="text-center">Žiadne záznamy</h1>
    </div>
    <div class="table-row-tab-mobile">
      <div class="custom-accordion">
        <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTransactions($event)">
          <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                         v-for="(item, index) in paginatedTransactions" :key="index">
            <template #header>
              <div class="customer-header-row">
                <span>Typ transakcie</span>
                <p>{{ item?.transaction_type?.long_name }}</p>
              </div>
              <i :id="'pi-plus-transaction-' + index" class="pi pi-plus"></i>
            </template>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Stratégia</h3>
                <p>{{ item?.investment_strategy?.name }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Dátum</h3>
                <p>{{ formatDate(item?.transaction_date) }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Objem</h3>
                <p>{{ formatToSkFormat(item?.value) }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Mena</h3>
                <p>{{ item?.currency?.name }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Poznámka</h3>
                <p>{{ item?.note }}</p>
              </div>
            </div>
          </CAccordionTab>
        </CAccordion>
      </div>
      <Paginator v-model:first="valueInPaginatorTransactions" :rows="10" :totalRecords="filteredTransactions.length"
                 template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
    </div>
  </div>
</template>


<script>
import {useClientsStore} from "@/stores/clientsStore";
import {computed, ref} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import Paginator from 'primevue/paginator';
import Calendar from "@/components/CustomUIComponents/Calendar/Calendar.vue";

export default {
  name: 'ClientTransactions',
  components: {Table, Paginator, Calendar},
  setup() {
    const clientStore = useClientsStore();
    const transactions = computed(() => clientStore.getTransactions);
    const valueInPaginatorTransactions = ref(0);
    const previousIndexTransactions = ref(null);

    const selectedStrategy = ref(null);
    const dateFrom = ref(null);
    const dateTo = ref(null);

    const agentStrategy = computed(() => {
      const strategies = new Set();
      if (transactions.value && transactions.value.content) {
        // console.log('Trans', transactions.value.content);
        transactions.value.content.forEach(item => {
          // console.log('Item', item);
          if (item?.investment_strategy && item?.investment_strategy.name) {
            strategies.add(item.investment_strategy.name);
          }
        });
      }
      return Array.from(strategies).map(strategy => ({ name: strategy }));
    });

    const formatToSkFormat = (number) => {
      if (number === undefined) {
        return ''; // Handle the undefined case
      }
      return number.toLocaleString('sk-SK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replace('.', ','); // Replace decimal point with comma
    };

    const formatDate = (transactionTime) => {
      const date = new Date(transactionTime);
      return date.toLocaleDateString("sk-SK"); // Format date as DD.MM.YYYY
    };

    const filteredTransactions = computed(() => {
      let filtered = transactions.value.content || [];

      if (selectedStrategy.value) {
        filtered = filtered.filter(transaction => transaction.investment_strategy?.name === selectedStrategy.value.name);
      }

      if (dateFrom.value) {
        filtered = filtered.filter(transaction => new Date(transaction.transaction_date) >= new Date(dateFrom.value));
      }

      if (dateTo.value) {
        filtered = filtered.filter(transaction => new Date(transaction.transaction_date) <= new Date(dateTo.value));
      }

      return filtered.map(transaction => ({
        ...transaction,
        transaction_date: formatDate(transaction.transaction_date),
        value: formatToSkFormat(transaction.value)
      }));
    });

    const paginatedTransactions = computed(() => {
      const start = valueInPaginatorTransactions.value;
      const end = start + 1000000;
      return filteredTransactions.value.slice(start, end);
    });

    function updateIndexTransactions(event) {
      const element = document.getElementById("pi-plus-transaction-" + event);
      if (element === null) {
        previousIndexTransactions.value.classList.add("pi-plus");
        previousIndexTransactions.value.classList.remove("pi-minus");
      } else if (previousIndexTransactions.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTransactions.value) {
        previousIndexTransactions.value.classList.add("pi-plus");
        previousIndexTransactions.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTransactions.value = element;
    }


    return {
      transactions,
      transactionsColumns: [
        { field: "transaction_date", header: "Dátum"},
        { field: "investment_strategy.name", header: "Stratégia", sortable: true },
        { field: "transaction_type.long_name", header: "Typ transakcie", sortable: true },
        { field: "value", header: "Objem", sortable: true, class: "font-bold !text-right !justify-end" },
        { field: "currency.name", header: "Mena", sortable: true },
        { field: "note", header: "Poznámka", sortable: true },
      ],
      valueInPaginatorTransactions,
      updateIndexTransactions,
      selectedStrategy,
      dateFrom,
      dateTo,
      filteredTransactions,
      paginatedTransactions,
      formatToSkFormat,
      formatDate,
      agentStrategy,
    };
  },
}
</script>


<style lang="scss" scoped>
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filters {
  display: flex;
  gap: 10px;
}

.calendar-filters {
  display: flex;
  gap: 10px;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

</style>
