<template>
  <apexchart
      v-if="data.length > 0 && options"
      type="donut"
      :options="chartOptions"
      :series="series"
      ref="apexChart"
      :height="heightValue"
  >
  </apexchart>
  <h3 v-else>Žiadne dáta k dispozícii</h3>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, defineProps } from "vue";

const props = defineProps<{
  data: any;
  options: any;
  type: any;
}>();

const heightValue = ref("400");
const series = ref(props.data);
const chartOptions = ref({
  labels: props.options,
  colors: ['#FF5C33', '#00362E', '#9EC4D6', "#ADABA6", "#000000"],
  dataLabels: {
    enabled: true,
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return formatter(value);
      }
    }
  },
  legend: {
    show: false,
  },
});

watch(() => {
  if (window.innerWidth < 550) {
    heightValue.value = "300";
  }
}, { immediate: true });

const formatter = (value) => {
  // Format numbers in thousands, millions, etc.
  const numberFormat = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
  return numberFormat.format(value);
};

watch(() => props.data, (newData) => {
  series.value = newData;
});

watch(() => props.options, (newOptions) => {
  chartOptions.value.labels = newOptions;
});
</script>
