<template>
  <div>
    <div class="px-7 py-3 bg-white rounded-lg shadow-md m-3 mb-8">
      <div class="flex items-center justify-between">
        <h1 class="root-ctab-panel-h1">Majetok klienta</h1>
        <div class="flex items-center space-x-4 ml-auto">
            <span class="p-input-icon-left mx-2 ml-4 custom-header-assets-item-first">
              <i class="pi pi-search"/>
              <CInputText v-model="searchValue" placeholder="Vyhľadať"/>
            </span>
          <CDropdown filter show-clear v-model="selectedStrategy" :options="agentStrategies" optionLabel="name"
                     placeholder="Vyberte stratégiu" class="md:w-14rem ml-3 custom-header-assets-item-second"
                     :pt="{
                          panel: { style: 'width:350px;' },
                      }"
          />
          <div class="left-bar-panel m-2 text-white custom-header-assets-item-third">
            <h6>Objem majetku</h6>
            <div class="left-bar-panel-item">
              <p>{{ totalValueEur }}</p> <!-- Display the computed total sum_value_eur here -->
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <div class="flex items-center justify-between">
        <h1 class="root-ctab-panel-h1">Hotovosť</h1>
      </div>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7 table-row-desktop">
        <Table
            :columns="cashColumns"
            :data="filteredCash"
            :pagination="true"
        >
        </Table>
      </div>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexCash($event)">
            <CAccordionTab
                v-for="(item, index) in cashListPaginator"
                :key="index"
                :pt="accordionStyle"
            >
              <template #header>
                <div class="customer-header-row">
                  <span>Stratégia</span>
                  <p>{{ item?.strategy }}</p>
                </div>
                <i :id="'pi-plus-cash-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Banka</h3>
                  <p>{{ item?.bank }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Objem</h3>
                  <p>{{ item?.value }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Mena</h3>
                  <p>{{ item?.currency }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Objem v EUR</h3>
                  <p>{{ item?.value_eur }}</p>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator
            v-model:first="valueInPaginatorCash"
            :rows="10"
            :totalRecords="filteredCash.length"
            template="PrevPageLink CurrentPageReport NextPageLink"
        ></Paginator>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3">

        <h1 class="root-ctab-panel-h1">Aktíva</h1>

      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7 table-row-desktop">
        <Table
            :columns="assetsColumns"
            :data="filteredAssets"
            :pagination="true"
        >
        </Table>
      </div>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexAssets($event)">
            <CAccordionTab
                v-for="(item, index) in assetsListPaginator"
                :key="index"
                :pt="accordionStyle"
            >
              <template #header>
                <div class="customer-header-row">
                  <span>Stratégia</span>
                  <p>{{ item?.strategy }}</p>
                </div>
                <i :id="'pi-plus-assets-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Cenný papier</h3>
                  <p>{{ item?.name }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>ISIN</h3>
                  <p>{{ item?.isin }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Počet</h3>
                  <p>{{ item?.amount }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Objem</h3>
                  <p>{{ item?.sum_value }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Mena</h3>
                  <p>{{ item?.currency }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Objem v EUR</h3>
                  <p>{{ item?.sum_value_eur }}</p>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator
            v-if="assetsListPaginatorLength"
            v-model:first="valueInPaginatorAssets"
            :rows="10"
            :totalRecords="filteredAssets.length"
            template="PrevPageLink CurrentPageReport NextPageLink"
        ></Paginator>
      </div>

    </div>

  </div>
</template>

<script>
import {useClientsStore} from "@/stores/clientsStore";
import {computed, ref} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import Paginator from 'primevue/paginator';

export default {
  name: 'ClientAssets',
  components: {Table, Paginator},
  setup() {
    const clientStore = useClientsStore();
    const assets = computed(() => clientStore.getFormattedSecurities || []);
    const cash = computed(() => clientStore.getFormattedCashAccounts || []);
    const searchValue = ref('');
    const selectedStrategy = ref(null);
    const valueInPaginatorCash = ref(0);
    const valueInPaginatorAssets = ref(0);

    const agentStrategies = computed(() => {
      const strategies = new Set();
      assets.value.forEach(item => strategies.add(item.strategy));
      cash.value.forEach(item => strategies.add(item.strategy));
      return Array.from(strategies).map(strategy => ({name: strategy}));
    });

    const filteredCash = computed(() => {
      return cash.value.filter(item => {
        const matchSearch = !searchValue.value || item.bank.toLowerCase().includes(searchValue.value.toLowerCase()) || item.strategy.toLowerCase().includes(searchValue.value.toLowerCase());
        const matchStrategy = !selectedStrategy.value || item.strategy === selectedStrategy.value.name;
        return matchSearch && matchStrategy;
      });
    });

    const filteredAssets = computed(() => {
      return assets.value.filter(item => {
        const matchSearch = !searchValue.value || item.name.toLowerCase().includes(searchValue.value.toLowerCase()) || item.strategy.toLowerCase().includes(searchValue.value.toLowerCase());
        const matchStrategy = !selectedStrategy.value || item.strategy === selectedStrategy.value.name;
        return matchSearch && matchStrategy;
      });
    });

    const totalValueEur = computed(() => {
      const totalCash = filteredCash.value.reduce((acc, item) => {
        const cleanValue = parseFloat(item.value_eur.replace(/\s/g, '').replace(',', '.'));
        return acc + (isNaN(cleanValue) ? 0 : cleanValue);
      }, 0);

      const totalAssets = filteredAssets.value.reduce((acc, item) => {
        const cleanValue = parseFloat(item.sum_value_eur.replace(/\s/g, '').replace(',', '.'));
        return acc + (isNaN(cleanValue) ? 0 : cleanValue);
      }, 0);

      const total = totalCash + totalAssets;
      return total.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR' });
    });



    return {
      assetsColumns: [
        { field: "strategy", header: "Stratégia", sortable: true },
        { field: "name", header: "Cenný papier", sortable: true },
        { field: "isin", header: "ISIN", sortable: true },
        { field: "amount", header: "Počet", sortable: true, class: "!text-right !justify-end" },
        { field: "sum_value", header: "Objem", sortable: true, class: "!text-right !justify-end font-bold" },
        { field: "currency", header: "Mena", sortable: true },
        { field: "sum_value_eur", header: "Objem v EUR", sortable: true, class: "!text-right !justify-end font-bold" },
      ],
      cashColumns: [
        { field: "strategy", header: "Stratégia", sortable: true },
        { field: "bank", header: "Banka", sortable: true },
        { field: "value", header: "Objem", sortable: true, class: "!text-right !justify-end font-bold" },
        { field: "currency", header: "Mena", sortable: true },
        { field: "value_eur", header: "Objem v EUR", sortable: true, class: "!text-right !justify-end font-bold" },
      ],
      assets,
      cash,
      searchValue,
      selectedStrategy,
      filteredCash,
      filteredAssets,
      totalValueEur,
      valueInPaginatorCash,
      valueInPaginatorAssets,
      agentStrategies
    };
  },
}
</script>


<style lang="scss" scoped>

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 5px;
  padding-top: 7px;
  padding-left: 50px;
  padding-right: 10px;
  padding-bottom: 7px;

  h6 {
    font-size: 12px;
  }

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
}


.custom-header-assets-item-third {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}


@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

</style>
