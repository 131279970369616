import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';
// import {useToast} from 'primevue/usetoast';
import {formatDate} from "@vueuse/core";


interface Item {
    date: string;
    value: number;
    // Other properties...
}

export const useAgentStore = defineStore({
    id: 'agent',

    state: () => ({
        agentInfo: undefined as Agent | undefined,
        agentClients: {content: [] as any[]},
        agentNoOfClientsLabels: [],
        agentNoOfClientsData: [],
        agentAumLabels: [],
        agentAumData: [],
        agentProvisionLabel: [],
        agentProvisionData: [],
        agentAumByStrategyLabel: [],
        agentAumByStrategyDataAssets: [],
        agentAumByStrategyDataCash: [],
        agentAumByStrategyDataAum: [],
        agentTopClientsLabel: [],
        agentTopClientsDataAssets: [],
        agentTopClientsDataCash: [],
        agentTopClientsDataAum: [],
    }),

    getters: {
        getAgentInfo: (state) => state.agentInfo,
        getAgentClients: (state) => state.agentClients,
        getFormattedAgentClients: (state) => {
            // Check if agentClients is empty
            if (!state.agentClients || state.agentClients.length === 0) {
                console.warn("agentClients is empty or not an array:", state.agentClients);
                return [];
            }

            const formatDate = (transactionTime: string) => {
                const date = new Date(transactionTime);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            // Map over agentClients directly
            return state.agentClients.content.map((item) => {
                const uuid = item.uuid;
                const name = item.name;
                const surname = item.surname;
                const email = item.contact.email;
                const phone = item.contact.phone;
                const birthday = formatDate(item.birthday);
                const idcard_validity = formatDate(item.idcard_validity);

                // Returning the formatted object
                return {
                    uuid,
                    name,
                    surname,
                    email,
                    phone,
                    birthday,
                    idcard_validity,
                };
            });
        },
        getAgentNoOfClientsData: (state) => state.agentNoOfClientsData,
        getAgentNoOfClientsLabels: (state) => state.agentNoOfClientsLabels,
        getAgentAumLabels: (state) => state.agentAumLabels,
        getAgentAumData: (state) => state.agentAumData,
        getAgentProvisionLabel: (state) => state.agentProvisionLabel,
        getAgentProvisionData: (state) => state.agentProvisionData,
        getAgentAumByStrategyLabel: (state) => state.agentAumByStrategyLabel,
        getAgentAumByStrategyDataAssets: (state) => state.agentAumByStrategyDataAssets,
        getAgentAumByStrategyDataCash: (state) => state.agentAumByStrategyDataCash,
        getAgentAumByStrategyDataAum: (state) => state.agentAumByStrategyDataAum,
        getAgentTopClientsLabel: (state) => state.agentTopClientsLabel,
        getAgentTopClientsDataAssets: (state) => state.agentTopClientsDataAssets,
        getAgentTopClientsDataCash: (state) => state.agentTopClientsDataCash,
        getAgentTopClientsDataAum: (state) => state.agentTopClientsDataAum,
    },

    actions: {
        async fetchAgentInfo(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/get/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the api key in the state
                this.agentInfo = response;

                return response;
            } catch (error) {
                throw error;
            }
        },
        async fetchAgentClients(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/clients/list/${uuid}`;

                const formData = new FormData();
                formData.append('status', "client");

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        "status": "client"
                    }
                });

                // Store the api key in the state
                this.agentClients = response;

                return response;
            } catch (error) {
                throw error;
            }
        },
        async fetchAgentNoOfClients(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/stats/number_of_clients_line`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        agent_uuid: uuid,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });

                // Store the api key in the state
                this.agentNoOfClientsLabels = response.map((item: Item) => item.date.toString());
                this.agentNoOfClientsData = response.map((item: Item) => item.value);


                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchAgentAum(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/stats/agent_aum_line`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        agent_uuid: uuid,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });

                this.agentAumLabels = response.map((item: Item) => item.date.toString());
                this.agentAumData = response.map((item: Item) => item.value);

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchAgentProvision(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/stats/agent_provision_line`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        agent_uuid: uuid,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });

                // Store the api key in the state
                this.agentProvisionLabel = response.map((item: Item) => item.date.toString());
                this.agentProvisionData = response.map((item: Item) => item.value);

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchAgentAumByStrategy(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/stats/agent_aum_by_strategy`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        agent_uuid: uuid,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });

                // Store the api key in the state
                this.agentAumByStrategyLabel = response.map((item: Item) => item.investment_strategy.name.toString());
                this.agentAumByStrategyDataAssets = response.map((item: Item) => item.assets);
                this.agentAumByStrategyDataCash = response.map((item: Item) => item.cash);
                this.agentAumByStrategyDataAum = response.map((item: Item) => item.aum);

                return response;
            } catch (error) {
                throw error;
            }
        },

        async fetchAgentTopClients(uuid: string) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = `/v1/flip/agents/stats/agent_top_10_clients`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        agent_uuid: uuid,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });

                // Modify the response to include the 'fullname' property
                const modifiedResponse = response.map((item: Item) => ({
                    ...item,
                    fullname: item.client.name !== '' && item.client.name !== null ? `${item.client.name} ${item.client.surname}` : item.client.company_name || '',
                }));

                // Store the modified response in the state
                this.agentTopClientsLabel = modifiedResponse.map((item: Item) => item.fullname.toString() || item.company_name.toString());
                this.agentTopClientsDataAssets = modifiedResponse.map((item: Item) => item.assets);
                this.agentTopClientsDataCash = modifiedResponse.map((item: Item) => item.cash);
                this.agentTopClientsDataAum = modifiedResponse.map((item: Item) => item.aum);

                return modifiedResponse;
            } catch (error) {
                throw error;
            }
        },

        async deleteAgent(agent_uuid) {
            // const toast = useToast();
            try {
                const apiStore = useApiStore();
                const method = 'PUT';
                const url = '/v1/flip/agents/deactivate/' + agent_uuid;
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    showToast: true,
                });

                if (response.status === 200) {
                    // toast.add({
                    //     severity: 'success',
                    //     summary: 'Success Message',
                    //     detail: 'Agent deactivated successfully!',
                    //     life: 3000,
                    // });
                }
                return response;
            } catch (error) {
                // toast.add({
                //     severity: 'error',
                //     summary: 'Chyba',
                //     detail: error.response.data.error,
                //     life: 5000,
                // });
                throw error;
            }
        },

        async moveClients(agent_uuid, target_agent_uuid, clients) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'PUT';
                const url = `/v1/flip/agents/move_clients`;

                // Create FormData object
                const formData = new FormData();
                formData.append('agent_uuid', agent_uuid);
                formData.append('target_agent_uuid', target_agent_uuid);
                formData.append('clients_array', JSON.stringify(clients));  // Convert clients array to JSON string


                // Make the API request with FormData
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: formData,
                    contentType: false,
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },

        async setFlipAccess(agent_uuid, access) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'PUT';
                const url = `/v1/flip/agents/change_access/${agent_uuid}`;


                // Make the API request with FormData
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        access: access,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    showToast: true,
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },

        async setFlipAdmin(agent_uuid, admin) {
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'PUT';
                const url = `/v1/flip/agents/set_admin/${agent_uuid}`;


                // Make the API request with FormData
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        admin: admin,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                    showToast: true,
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },


        deleteAgentInfo() {
            this.agentInfo = undefined;
            this.agentClients = undefined;
        }
    },
});
