<template>
  <div class="root-client-view xl:!h-full">
    <div class="root-client-view-first w-3/12 bg-white h-full p-4 border-r-gray-200 border-r-2">
      <div className="flex flex-col items-center">
        <div className="items-center flex w-[283px] xl:w-full sm:max-v-full flex-col mt-4">
          <div class="left-bar-buttons pt-10">
            <button class="black-button mb-4" @click="handleButtonClick">Vytvoriť klienta</button>
            <!--            <button class="border-button" >Vytvoriť klienta</button>-->
            <!--<div class="left-bar-buttons-item mt-4">
              <button class="gray-button">Predaj</button>
              <button class="black-button ml-4">Nákup</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- Content area (10/12 width) -->
    <div class="root-client-view-second w-10/12 h-full ">
      <CTabView v-model:activeIndex="active" :pt="{
                panelContainer:{
                   style: 'background-color: #E5E4E3;',
                    class:[
                        'padding-for-mobile'
                    ]}}">
        <CTabPanel header="Profil" class="bg-black root-ctab-panel"
                   :pt="{headerAction:{class:['padding-for-mobile']}}"
        >
          <Form ref="createClientForm" :validation-schema="formSchema">
            <CreateClientInformation @client-information="handleClientInformation"/>
          </Form>
        </CTabPanel>
        <CTabPanel header="Zmluvy" :pt="{headerAction:{class:['padding-for-mobile']}}" class="custom-gray-background">
          <CreateClientContracts @createdContractUpdate="handleCreatedContractUpdate"/>
        </CTabPanel>
        <CTabPanel header="Dokumenty" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <CreateClientDocuments @uploadedDocumentsUpdate="handleDocumentsUpdate"/>
        </CTabPanel>
      </CTabView>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateClientInformation from "@/components/Clients/createClient/CreateClientInformation.vue";
import CreateClientContracts from "@/components/Clients/createClient/CreateClientContracts.vue";
import CreateClientDocuments from "@/components/Clients/createClient/CreateClientDocuments.vue";
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import {useClientsStore} from "@/stores/clientsStore";
import router from "@/router";
import * as Yup from "yup";
import {Form, useForm, validate} from "vee-validate";
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const active = ref(0);
const clientInformation = ref({});
const childData = ref('');
const clientsStore = useClientsStore();
const createdContracts = ref([]);
const uploadedDocuments = ref([]);

const formatDateToYYYYMMDD = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const formSchema = Yup.object().shape({
  // external_id: Yup.string().required('Zadajte Vaše ID klienta!'),
  academical_title_before: Yup.string(),
  name: Yup.string().required('Zadajte meno!'),
  surname: Yup.string().required('Zadajte priezvisko!'),
  // academical_title_after: Yup.string(),
  email: Yup.string().email('Nesprávny formát e-mailu!').required('Zadajte e-mail!'),
  phone: Yup.string().required('Zadajte telefón!'),
  city: Yup.string().required('Zadajte mesto!'),
  street: Yup.string().required('Zadajte ulicu!'),
  zip: Yup.string().required('Zadajte PSČ!'),
  country_id: Yup.string().required('Vyberte krajinu!'),
  city_correspondence: Yup.string(),
  street_correspondence: Yup.string(),
  zip_correspondence: Yup.string(),
  country_id_correspondence: Yup.string(),
  tax_residence_id: Yup.string().required('Vyberte daňovú rezidenciu!'),
  nationality: Yup.string().required('Zadajte štátnu príslušnosť!'),
  birthnumber: Yup.string().required('Zadajte rodné číslo!'),
  birthday: Yup.string().required('Vyberte dátum narodenia!'),
  birthname: Yup.string().required('Zadajte rodné číslo!'),
  iban: Yup.string().required('Zadajte IBAN účtu!'),
  bank_account_currency_id: Yup.number().required('Zadajte menu účtu!'),
  bank_account_owner_name: Yup.string().required('Zadajte meno majiteľa účtu!'),
  idcard_type_id: Yup.string().required('Vyberte typ dokladu totožnosti!'),
  idcard_no: Yup.string().required('Zadajte číslo dokladu totožnosti!'),
  idcard_validity: Yup.string().required('Vyberte platnosť dokladu!'),
  risk_profile_id: Yup.string().required('Vyberte rizikový profil klienta!'),
  intermediary_person_uuid: Yup.string().required('Intermediary Person ID is required'),
});


const {resetForm, handleSubmit} = useForm({
  validationSchema: formSchema,
  initialValues: clientInformation.value
});

const handleButtonClick = () => {
  // handleSubmit(formSchema)();
  // validate(clientInformation.value, formSchema).then((result) => {
  //   if (result.valid) {
  console.log('clientInformation', clientInformation.value);
  if (allFieldsAreFilled(clientInformation.value)) {
    // router.push('/clients');
    const response = clientsStore.createClient(clientInformation.value.external_id,
        clientInformation.value.academical_title_before,
        clientInformation.value.name,
        clientInformation.value.surname,
        clientInformation.value.academic_title_after,
        clientInformation.value.email,
        clientInformation.value.phone,
        clientInformation.value.city,
        clientInformation.value.street,
        clientInformation.value.zip,
        clientInformation.value.country_id,
        clientInformation.value.city_correspondence,
        clientInformation.value.street_correspondence,
        clientInformation.value.zip_correspondence,
        clientInformation.value.country_id_correspondence,
        clientInformation.value.tax_residence_id,
        clientInformation.value.nationality,
        clientInformation.value.birthnumber,
        formatDateToYYYYMMDD(clientInformation.value.birthday),
        clientInformation.value.iban,
        clientInformation.value.bank_account_currency_id,
        clientInformation.value.bank_account_owner_name,
        clientInformation.value.idcard_type_id,
        clientInformation.value.idcard_no,
        formatDateToYYYYMMDD(clientInformation.value.idcard_validity),
        clientInformation.value.risk_profile_id,
        clientInformation?.value?.intermediary_person_uuid,
    ).then((response) => {
      if (createdContracts.value.length != 0) {
        clientsStore.createLeadContract(response, JSON.stringify(createdContracts.value));
      }
      if (uploadedDocuments.value.length !== 0) {
        console.log('uploadedDocuments', uploadedDocuments.value)
        for (const document of uploadedDocuments.value) {
          console.log('document', document)
          clientsStore.leadUploadFile(
              response,
              document.type,
              document.data,
          );
        }
      }
    });
  }
  // } else {
  //   console.log('Form is not valid');
  // }
  // });

};

const allFieldsAreFilled = (clientInformation) => {
  // Check if clientInformation is an empty object or a Proxy object
  if (clientInformation === null || typeof clientInformation !== 'object' || (typeof clientInformation === 'object' && Object.keys(clientInformation).length === 0)) {
    toast.error('Please fill in all required fields.', {
      theme: 'colored',
      autoClose: 1000,
    });
    return false; // Return false if clientInformation is null, not an object, or an empty object
  } else {

    // Check each field of clientInformation for emptiness or null
    if (
        clientInformation.external_id === null ||
        clientInformation.academical_title_before === null ||
        clientInformation.name === null ||
        clientInformation.surname === null ||
        clientInformation.academic_title_after === null ||
        clientInformation.email === null ||
        clientInformation.phone === null ||
        clientInformation.city === null ||
        clientInformation.street === null ||
        clientInformation.zip === null ||
        clientInformation.country_id === null ||
        clientInformation.city_correspondence === null ||
        clientInformation.street_correspondence === null ||
        clientInformation.zip_correspondence === null ||
        clientInformation.country_id_correspondence === null ||
        clientInformation.tax_residence_id === null ||
        clientInformation.nationality === null ||
        clientInformation.birthnumber === null ||
        clientInformation.birthday === null ||
        clientInformation.iban === null ||
        clientInformation.bank_account_currency_id === null ||
        clientInformation.bank_account_owner_name === null ||
        clientInformation.idcard_type_id === null ||
        clientInformation.idcard_no === null ||
        clientInformation.idcard_validity === null ||
        clientInformation.risk_profile_id === null ||
        clientInformation.intermediary_person_uuid === null
    ) {
      console.log('Some fields are empty')
      toast.error("Vyplňte vsšetky povinneé polia", {
        theme: 'colored',
        autoClose: 1000,
      });
      return false; // Return false if any field is empty or null
    }

    return true; // Otherwise, return true indicating all fields are filled
  }
};


const isButtonDisabled = computed(() => {
  const info = clientInformation.value;
  console.log('info', info);
  return !(
      info.name &&
      info.surname &&
      info.email &&
      info.phone &&
      info.city &&
      info.street &&
      info.zip &&
      info.country_id &&
      info.city_correspondence &&
      info.street_correspondence &&
      info.zip_correspondence &&
      info.country_id_correspondence &&
      info.tax_residence_id &&
      info.nationality &&
      info.birthnumber &&
      formatDateToYYYYMMDD(info.birthday) &&
      info.iban &&
      info.bank_account_currency_id &&
      info.bank_account_owner_name &&
      info.idcard_type_id &&
      info.idcard_no &&
      formatDateToYYYYMMDD(info.idcard_validity) &&
      info.risk_profile_id &&
      info.intermediary_person_uuid
  );
});


onMounted(() => {
  // clientInformation.value.external_id = 4,
  //     clientInformation.value.academical_title_before = "Ing.",
  //     clientInformation.value.name = "Jozef",
  //     clientInformation.value.surname = "Mrkvicka",
  //     clientInformation.value.academic_title_after = "PhD.",
  //     clientInformation.value.email = "test@tets.sk",
  //     clientInformation.value.phone = "+421 911 111 111",
  //     clientInformation.value.city = "Bratislava",
  //     clientInformation.value.street = "Hlavna 1",
  //     clientInformation.value.zip = "811 01",
  //     clientInformation.value.country_id = 1,
  //     clientInformation.value.city_correspondence = "Bratislava",
  //     clientInformation.value.street_correspondence = "Hlavna 1",
  //     clientInformation.value.zip_correspondence = "811 01",
  //     clientInformation.value.country_id_correspondence = 1,
  //     clientInformation.value.tax_residence_id = 1,
  //     clientInformation.value.nationality = "Slovenska",
  //     clientInformation.value.birthnumber = "1234567890",
  //     clientInformation.value.birthday = "1990-01-01",
  //     clientInformation.value.iban = "SK1234567890",
  //     clientInformation.value.bank_account_currency_id = 1,
  //     clientInformation.value.bank_account_owner_name = "Jozef Mrkvicka",
  //     clientInformation.value.idcard_type_id = 1,
  //     clientInformation.value.idcard_no = "1234567890",
  //     clientInformation.value.idcard_validity = "2021-01-01",
  //     clientInformation.value.risk_profile_id = 1,
  //     clientInformation.value.intermediary_person_uuid = "1234567890"
});
const handleClientInformation = (info) => {
  clientInformation.value = info;
};

const handleCreatedContractUpdate = (updatedContracts) => {
  createdContracts.value = updatedContracts;
};

const handleDocumentsUpdate = (updatedDocuments) => {
  uploadedDocuments.value = updatedDocuments;
};

</script>

<style lang="scss" scoped>
.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.background-color-input {
  background-color: #F2F1F1 !important;
  color: black;
}

.tab-panel-custom {
  background-color: red !important;
}

@media only screen and (max-width: 1372px) {
  .padding-for-mobile {
    padding: 5px !important;
  }
}

.custom-gray-background {
  background-color: black;
}

.left-bar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .left-bar-buttons-item {
    display: flex;
    justify-content: space-between;
  }

  button {
    padding: 10px 0;
    border-radius: 10px;
    min-width: 85px;
    font-size: 14px;
    width: 100%;
  }

  .delete-button {
    margin-top: 300px;
    border: 1px solid #949494;
  }
}

.left-bar-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    font-weight: bold;
  }

  .left-bar-table-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center !important;

    h3 {
      color: #949494;
    }

    p {
      font-weight: bold;
    }
  }
}

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.left-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    height: 115px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    color: #949494;
  }
}

hr {
  margin: 10px 0;
}

.root-client-view {
  display: flex;
}

@media only screen and (max-width: 1372px) {
  .root-client-view {
    display: block !important;
    height: 550px;
  }
  .root-client-view-first {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .root-client-view-second {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}

.delete-button {
  margin-top: 10px !important;
}

.left-bar-panel, .left-bar-table, .left-bar-buttons {
  width: 90% !important;
}
</style>
