<template>
  <div>


    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Osobné údaje</h1>

      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="title" class="input-label">Titul</label>
          <CInputText id="title" v-model="client.academic_title_before" aria-describedby="title-help"
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="name">
            <label for="name" class="input-label">Meno</label>
            <CInputText id="name" v-model="client.name" aria-describedby="name-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="name"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="surname">
            <label for="surname" class="input-label">Priezvisko</label>
            <CInputText id="surname" v-model="client.surname" aria-describedby="surname-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="surname"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="birthday">
            <label for="birthday" class="input-label">Dátum narodenia</label>
            <CCalendar
                id="birthday"
                v-model="client.birthday"
                dateFormat="dd.mm.yy"
                aria-describedby="username-help"
                :pt="{ root: { class: ['background-color-input'] } }"
                show-icon
            />
            <ErrorMessage class="error-message" name="birthday"/>
          </Field>
        </div>
        <!--          <div class="flex flex-column gap-2 column-item">-->
        <!--            <label for="birthname" class="input-label">Rodné priezvisko</label>-->
        <!--            <CInputText id="birthname" v-model="client.birthname" aria-describedby="birthname-help"-->
        <!--                        :pt="{root: {class:['background-color-input']}}"/>-->
        <!--            <ErrorMessage class="error-message" name="birthname"/>-->
        <!--          </div>-->
      </div>
      <div class="root-ctab-panel-items space-x-5 mt-3">
        <div class="flex flex-column gap-2 column-item">
          <Field name="birthnumber">
            <label for="birthnumber" class="input-label">Rodné číslo</label>
            <CInputText id="birthnumber" v-model="client.birthnumber" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="birthnumber"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="idcard_type_id">
            <label for="country" class="input-label">Typ dokladu</label>
            <CDropdown show-clear v-model="selectedDocumentType" :options="documentTypes" optionLabel="name"
                       placeholder="Vyberte typ dokumentu" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="idcard_type_id"/>
            <h6>{{errors.idcard_type_id}}</h6>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="idcard_no">
            <label for="id_number" class="input-label">Číslo dokladu</label>
            <CInputText id="id_number" v-model="client.idcard_no" aria-describedby="username-help"
            />
            <ErrorMessage class="error-message" name="idcard_no"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="idcard_validity">
            <label for="id_validity" class="input-label">Platnosť dokladu</label>
            <CCalendar id="id_validity" v-model="client.idcard_validity" dateFormat="dd.mm.yy"
                       aria-describedby="username-help"
                       :pt="{root: {class:['background-color-input']}}" show-icon/>
            <ErrorMessage class="error-message" name="idcard_validity"/>
          </Field>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Adresa</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <Field name="street">
            <label for="street" class="input-label">Ulica a číslo</label>
            <CInputText id="street" v-model="client.street" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="street"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="city">
            <label for="city" class="input-label">Mesto</label>
            <CInputText id="city" v-model="client.city" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="city"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="zip">
            <label for="zip" class="input-label">PSČ</label>
            <CInputText id="zip" v-model="client.zip" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="zip"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="country_id">
            <label for="country" class="input-label">Krajina</label>
            <CDropdown show-clear v-model="selectedCountry" :options="countryList" optionLabel="name"
                       placeholder="Vyberte krajinu" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="country_id"/>
          </Field>
        </div>
      </div>
      <div class="flex align-items-center">
        <CCheckbox v-model="postAddressComputed" inputId="postAdress" name="postAddress" binary :value="postAddress"/>
        <label for="ingredient1" class="ml-2">Korešpondečná adresa je odlišná</label>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8" v-if="postAddress">
      <h1 class="root-ctab-panel-h1">Korešpondečná adresa</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="street" class="input-label">Ulica a číslo</label>
          <CInputText id="street" v-model="client.street_correspondence" aria-describedby="username-help"
                      :pt="{root: {class:['background-color-input']}}"/>
          <ErrorMessage class="error-message" name="street_correspondence"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="city" class="input-label">Mesto</label>
          <CInputText id="city" v-model="client.city_correspondence" aria-describedby="username-help"
                      :pt="{root: {class:['background-color-input']}}"/>
          <ErrorMessage class="error-message" name="city_correspondence"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="zip" class="input-label">PSČ</label>
          <CInputText id="zip" v-model="client.zip_correspondence" aria-describedby="username-help"
                      :pt="{root: {class:['background-color-input']}}"/>
          <ErrorMessage class="error-message" name="zip_correspondence"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="country" class="input-label">Krajina</label>
          <CDropdown show-clear v-model="selectedCountryCorrespondence" :options="countryList" optionLabel="name"
                     placeholder="Vyberte krajinu" class="md:w-14rem mr-2"/>
          <ErrorMessage class="error-message" name="country_id_correspondence"/>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Ďalšie informácie</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <Field name="nationality">
            <label for="state" class="input-label">Štátna príslušnosť</label>
            <CInputText id="state" v-model="client.nationality" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="nationality"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="tax_residence_id">
            <label for="state" class="input-label">Daňová rezidencia</label>
            <CDropdown show-clear v-model="selecteVatResidence" :options="countryList" optionLabel="name"
                       placeholder="Vyberte daňovú rezidenciu" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="tax_residence_id"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="phone">
            <label for="phone" class="input-label">Tel. číslo</label>
            <CInputText id="phone" v-model="client.phone" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="phone"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="email">
            <label for="email" class="input-label">E-mail</label>
            <CInputText id="email" v-model="client.email" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="email"/>
          </Field>
        </div>
      </div>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <Field name="external_id">
            <label for="state" class="input-label">Vaše ID klienta</label>
            <CInputText id="state" v-model="client.external_id" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="external_id"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="risk_profile_id">
            <label for="phone" class="input-label">Rizikový profil</label>
            <CDropdown show-clear v-model="selectedRiskProfile" :options="formattedRiskProfiles" optionLabel="name"
                       placeholder="Vyberte rizikový profil" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="risk_profile_id"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item" v-if="isAdmin == 'true'">
          <Field name="intermediary_person_id">
            <label for="state" class="input-label">Agent</label>
            <CDropdown show-clear v-model="selectedAgent" :options="formattedAgentsList" optionLabel="fullName"
                       placeholder="Vyberte agenta" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="intermediary_person_id"/>
          </Field>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3">
      <h1 class="root-ctab-panel-h1">Bankové účty</h1>
      <hr>
      <div class="root-ctab-panel-items root-ctab-panel-items-desktop">
        <div class="flex flex-column gap-2 column-item">
          <Field name="bank_account_owner_name">
            <label for="state" class="input-label">Vlastnik účtu</label>
            <CInputText id="state" v-model="client.bank_account_owner_name" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="bank_account_owner_name"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="iban">
            <label for="state" class="input-label">IBAN</label>
            <CInputText id="state" v-model="client.iban" aria-describedby="username-help"
                        :pt="{root: {class:['background-color-input']}}"/>
            <ErrorMessage class="error-message" name="iban"/>
          </Field>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <Field name="bank_account_currency_id">
            <label for="phone" class="input-label">Mena</label>
            <CDropdown show-clear v-model="selectedCurrency" :options="currencyList" optionLabel="name"
                       placeholder="Vyberte menu" class="md:w-14rem mr-2"/>
            <ErrorMessage class="error-message" name="bank_account_currency_id"/>
          </Field>
        </div>
      </div>
    </div>
    <!--    </Form>-->
  </div>
</template>


<script setup>
import Table from "@/components/CustomUIComponents/Table.vue";
import {ref, computed, defineEmits, watch, onMounted, onBeforeMount} from "vue";
import {useForm, useField, Field, Form, ErrorMessage} from 'vee-validate';
import * as Yup from "yup";
import {useEnumsStore} from "@/stores/enumsStore";
import {useAgentsStore} from "@/stores/agentsStore";
import * as formSchema from "vee-validate";

const enumsStore = useEnumsStore();
const agentsStore = useAgentsStore();

const riskProfiles = computed(() => enumsStore.getRiskProfiles);
const formattedAgentsList = computed(() => agentsStore.getFormattedAgentsList);
const countryList = computed(() => enumsStore.getCountries);
const currencyList = computed(() => enumsStore.getCurrencies);
const documentTypes = computed(() => enumsStore.getIdcardTypes);

const emits = defineEmits(['client-information']);
const isAdmin = ref("");
const selectedRiskProfile = ref(null);
const selectedAgent = ref(null);
const selecteVatResidence = ref(null);
const selectedCountry = ref(null);
const selectedCountryCorrespondence = ref(null);
const selectedCurrency = ref(null);
const selectedDocumentType = ref(null);

const postAddress = ref(false);

const postAddressComputed = computed({
  get: () => postAddress.value,
  set: (value) => {
    postAddress.value = value;
  },
});

watch(selectedRiskProfile, (newValue) => {
  client.value.risk_profile_id = newValue.value;
}, {deep: true});

watch(selectedAgent, (newValue) => {
  client.value.intermediary_person_uuid = newValue.uuid;
}, {deep: true});

watch(selecteVatResidence, (newValue) => {
  client.value.tax_residence_id = newValue.id;
}, {deep: true});

watch(selectedCountry, (newValue) => {
  client.value.country_id = newValue.id;
}, {deep: true});

watch(selectedCountryCorrespondence, (newValue) => {
  client.value.country_id_correspondence = newValue.id;
}, {deep: true});

watch(selectedCurrency, (newValue) => {
  client.value.bank_account_currency_id = newValue.id;
}, {deep: true});

watch(selectedDocumentType, (newValue) => {
  client.value.idcard_type_id = newValue.id;
}, {deep: true});

const client = ref({
  external_id: "",
  academic_title_before: "",
  name: "",
  surname: "",
  academic_title_after: "",
  email: "",
  phone: "",
  city: "",
  street: "",
  zip: "",
  country_id: "",
  city_correspondence: "",
  street_correspondence: "",
  zip_correspondence: "",
  country_id_correspondence: 0,
  tax_residence_id: "",
  nationality: "",
  birthnumber: "",
  birthday: null,
  iban: "",
  bank_account_currency_id: 0,
  bank_account_owner_name: "",
  idcard_type_id: 0,
  idcard_no: "",
  idcard_validity: null,
  risk_profile_id: 0,
  intermediary_person_uuid: "",
});

const { errors, handleSubmit, defineField } = useForm({
  validationSchema: Yup.object({
    // external_id: Yup.string().required('Zadajte Vaše ID klienta!'),
    // academical_title_before: Yup.string(),
    name: Yup.string().required('Zadajte meno!'),
    surname: Yup.string().required('Zadajte priezvisko!'),
    // academical_title_after: Yup.string(),
    email: Yup.string().email('Nesprávny formát e-mailu!').required('Zadajte e-mail!'),
    phone: Yup.string().required('Zadajte telefón!'),
    city: Yup.string().required('Zadajte mesto!'),
    street: Yup.string().required('Zadajte ulicu!'),
    zip: Yup.string().required('Zadajte PSČ!'),
    country_id: Yup.string().required('Vyberte krajinu!'),
    city_correspondence: Yup.string(),
    street_correspondence: Yup.string(),
    zip_correspondence: Yup.string(),
    country_id_correspondence: Yup.string(),
    tax_residence_id: Yup.string().required('Vyberte daňovú rezidenciu!'),
    nationality: Yup.string().required('Zadajte štátnu príslušnosť!'),
    birthnumber: Yup.string().required('Zadajte rodné číslo!'),
    birthday: Yup.string().required('Vyberte dátum narodenia!'),
    birthname: Yup.string().required('Zadajte rodné číslo!'),
    iban: Yup.string().required('Zadajte IBAN účtu!'),
    bank_account_currency_id: Yup.number().required('Zadajte menu účtu!'),
    bank_account_owner_name: Yup.string().required('Zadajte meno majiteľa účtu!'),
    idcard_type_id: Yup.string().required('Vyberte typ dokladu totožnosti!'),
    idcard_no: Yup.string().required('Zadajte číslo dokladu totožnosti!'),
    idcard_validity: Yup.string().required('Vyberte platnosť dokladu!'),
    risk_profile_id: Yup.string().required('Vyberte rizikový profil klienta!'),
    intermediary_person_uuid: Yup.string().required('Intermediary Person ID is required'),
  }),
});

const onSubmit = handleSubmit(values => {
  alert(JSON.stringify(values, null, 2));
});

const [email, emailAttrs] = defineField('email');
const [password, passwordAttrs] = defineField('password');


  // external_id: "4601",
  // academic_title_before: "Ing.",
  // name: "Jozef",
  // surname: "Mrkvicka",
  // academic_title_after: "PhD.",
  // email: "test@tets.sk",
  // phone: "+421 911 111 111",
  // city: "Bratislava",
  // street: "Hlavna 1",
  // zip: "811 011",
  // city_correspondence: "Bratislava",
  // street_correspondence: "Hlavna 1",
  // zip_correspondence: "811 01",
  // country_id_correspondence: 3,
  // tax_residence_id: 1,
  // nationality: "Slovenska",
  // birthnumber: "115599",
  // birthday: "01.03.2000",
  // iban: "SK1234567890",
  // bank_account_currency_id: 1,
  // bank_account_owner_name: "Jozef Mrkvicka",
  // idcard_type_id: 1,
  // idcard_no: "1234567890",
  // idcard_validity: "01.01.3100",
  // risk_profile_id: 1,
  // intermediary_person_uuid: "1234567890"
// });


// const formSchema = Yup.object().shape({
//   external_id: Yup.string().required('Zadajte Vaše ID klienta!'),
//   academical_title_before: Yup.string(),
//   name: Yup.string().required('Zadajte meno!'),
//   surname: Yup.string().required('Zadajte priezvisko!'),
//   academical_title_after: Yup.string(),
//   email: Yup.string().email('Nesprávny formát e-mailu!').required('Zadajte e-mail!'),
//   phone: Yup.string().required('Zadajte telefón!'),
//   city: Yup.string().required('Zadajte mesto!'),
//   street: Yup.string().required('Zadajte ulicu!'),
//   zip: Yup.string().required('Zadajte PSČ!'),
//   country_id: Yup.string().required('Vyberte krajinu!'),
//   city_correspondence: Yup.string(),
//   street_correspondence: Yup.string(),
//   zip_correspondence: Yup.string(),
//   country_id_correspondence: Yup.string(),
//   tax_residence_id: Yup.string().required('Vyberte daňovú rezidenciu!'),
//   nationality: Yup.string().required('Zadajte štátnu príslušnosť!'),
//   birthnumber: Yup.string().required('Zadajte rodné číslo!'),
//   birthday: Yup.string().required('Vyberte dátum narodenia!'),
//   birthname: Yup.string().required('Zadajte rodné číslo!'),
//   iban: Yup.string().required('Zadajte IBAN účtu!'),
//   bank_account_currency_id: Yup.number().required('Zadajte menu účtu!'),
//   bank_account_owner_name: Yup.string().required('Zadajte meno majiteľa účtu!'),
//   idcard_type_id: Yup.string().required('Vyberte typ dokladu totožnosti!'),
//   idcard_no: Yup.string().required('Zadajte číslo dokladu totožnosti!'),
//   idcard_validity: Yup.string().required('Vyberte platnosť dokladu!'),
//   risk_profile_id: Yup.string().required('Vyberte rizikový profil klienta!'),
//   intermediary_person_uuid: Yup.string().required('Intermediary Person ID is required'),
// });




watch(client, (newValue) => {
  emits('client-information', client.value);
  formSchema.validate(newValue).then(() => {
    if (postAddress.value === false) {
      client.value.city_correspondence = client.value.city;
      client.value.street_correspondence = client.value.street;
      client.value.zip_correspondence = client.value.zip;
      client.value.country_id_correspondence = client.value.country_id;
    }
  }).catch((err) => {
    if (postAddress.value === false) {
      client.value.city_correspondence = client.value.city;
      client.value.street_correspondence = client.value.street;
      client.value.zip_correspondence = client.value.zip;
      client.value.country_id_correspondence = client.value.country_id;
    }
  });
}, {deep: true});

onMounted(() => {
  isAdmin.value = localStorage.getItem('isAdmin');
});


onBeforeMount(() => {
  agentsStore.fetchAgentsList(0, 1000);
});

const formattedRiskProfiles = computed(() => {
  return riskProfiles.value.map(type => ({
    name: type.name,
    value: type.id
  }));
});

const sendDataToParent = () => {
  emits('client-information', client.value);
};

const clientBankAccounts = ref([]);

const bankAccounts = [
  {field: "title", header: "Názov účtu"},
  {field: "iban", header: "IBAN"},
  {field: "currency.name", header: "Mena"}
];


</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 12px;
}

.root-ctab-panel-items-mobile {
  display: none;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items-desktop {
    display: none;
  }
  .root-ctab-panel-items-mobile {
    display: block;

    h3 {
      background-color: #F2F1F1;
      color: #949494;
    }

  }
}

</style>
