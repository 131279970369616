<template>
  <div class="h-full flex items-center justify-center" v-if="isLoading">
    <Loader/>
  </div>
  <div class="h-full" v-else>
    <div class="p-8 mobile-view" v-if="!performanceChartVisible">

      <div class="rounded-lg shadow-md bg-white p-4 desktop-table mb-4 flex justify-between custom-css">
        <span class="p-input-icon-left mr-2 ml-4">
          <i class="pi pi-search"/>
          <CInputText v-model="searchValue" placeholder="Vyhľadať"/>
        </span>

        <div class="space-x-4 custom-mobile-view mr-3">
          <CButton label="Predaj" class="gray-button" @click="openModal('sell')"/>
          <CButton label="Nákup" class="black-button" @click="openModal('buy')"/>
          <CButton label="Pridať CP" class="orange-button" @click="addCpOpen = true"/>
        </div>
      </div>

      <div class="rounded-lg shadow-md bg-white p-4">
        <div class="flex items-center justify-between py-2 custom-css">
          <h2 class="ml-4 text-xl">Zoznam cenných papierov</h2>
          <div class="mr-3 text-gray-400 text-sm" v-if="filteredSecuritiesList && filteredSecuritiesList.length">
            Počet záznamov: {{ filteredSecuritiesList.length }}
          </div>
        </div>
        <Table :columns="securitiesColumns"
               :data="filteredSecuritiesList"
               :pagination="true"
               class="m-3 table-row-desktop"
        >
          <CColumn field="profil" header="Zmena v %">
            <template #body="{ data }">
              <CChip v-if="data.percentage_diff <= 0" icon="pi pi-angle-down" :label="data.percentage_diff_formatted"
                     :pt="{root: {class: ['change-pill-down']}}"/>
              <CChip v-if="data.percentage_diff > 0" icon="pi pi-angle-up" :label="data.percentage_diff_formatted"
                     :pt="{root: {class: ['change-pill-up']}}"/>
            </template>
          </CColumn>
          <CColumn field="icon">
            <template #body="{ data }">
              <p class="menu-icon-clickable" @click="toggle(data, $event)">
                <font-awesome-icon icon="fa-solid fa-ellipsis-h" size="lg" class="nested-icon" aria-haspopup="true"
                                   aria-controls="overlay_menu"/>
                <CMenu ref="menu" id="overlay_menu" :model="items"
                       :popup="true"
                       :pt="{
                       submenuHeader: {style: {'display': 'none'}},
                       menuitem: {style: {'padding': '0px'}},
                       label: {style: {'color': 'black'}},
                       icon: {style: {'color': 'black'}}
                       }"/>
              </p>
            </template>
          </CColumn>
        </Table>
        <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
              <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                             v-for="(item, index) in tradesListPaginator" :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>Názov cenného papiera</span>
                    <p>{{ item?.name }}</p>
                  </div>
                  <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>ISIN</h3>
                    <p>{{ item?.isin }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Ticker</h3>
                    <p>{{ item?.ticker }}</p>
                  </div>
                </div>
                <div class="content-row flex" v-if="item?.type">
                  <div class="content-row-item">
                    <h3>Typ</h3>
                    <p>{{ item?.type }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Trhová cena</h3>
                    <p>{{ item?.last_price }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Mena</h3>
                    <p>{{ item?.currency }}</p>
                  </div>
                </div>
                <div class="content-row flex" v-if="item?.percentage_diff_formatted">
                  <div class="content-row-item">
                    <h3>Zmena v %</h3>
                    <p>{{ item?.percentage_diff_formatted }}</p>
                  </div>
                </div>
                <!--                <div class="content-row-buttons">-->
                <!--                  <div class="content-row-item">-->
                <!--                    <button>Stiahnut</button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator v-model:first="valueInPaginatorTrades" :rows="10" :totalRecords="filteredSecuritiesList.length"
                     template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
        </div>
      </div>
    </div>

    <!-- TENTO JE NASTYLOVANY - POUZIME TENTO LAYOUT-->
    <CSidebar
        header="Pokyn"
        v-model:visible="modalOpened"
        position="right"
        :pt="{closeButton: {class: ['close-button']}}"
        style="width: 500px;"
    >
      <template #header>
        <h1 style="font-size: 1.5rem;">{{ selectedAction === 'buy' ? 'Pokyn na nákup' : 'Pokyn na predaj' }}</h1>
      </template>
      <div class="root-ctab-panel-items mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="selectedClient" class="input-label">Klient</label>
          <CDropdown v-model="selectedClient" :options="clientList" filter
                     optionLabel="fullName"
                     placeholder="Vyhľadajte klienta..." class="w-full md:w-14rem mb-2"/>
        </div>
        <div class="flex flex-column gap-2 column-item" v-if="selectedClient">
          <label for="selectedStrategy" class="input-label">Stratégia</label>
          <!--          <CDropdown v-model="selectedStrategy" :options="fs" filter optionLabel="name"-->
          <!--                     placeholder="Vyberte stratégiu" class="w-full md:w-14rem mb-2">-->
          <!--          </CDropdown>-->

          <CDropdown v-model="selectedStrategy" :options="fs" filter optionLabel="name" placeholder="Vyberte stratégiu"
                     class="w-full md:w-14rem" emptyMessage="Klient nemá k dispozícii žiadnu komisionársku zmluvu.">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
            {{ slotProps.placeholder }}
        </span>
            </template>
            <template #option="slotProps">
              <div class=" align-items-center">
                <div>{{
                    slotProps.option.name + `  (${slotProps.option.agent.name} ${slotProps.option.agent.surname}, ${formatDate(slotProps.option.contract_closure_date)})`
                  }}
                </div>
              </div>
            </template>
          </CDropdown>

          <!--          {{ selectedStrategy }}-->

        </div>
        <div class="flex flex-column gap-2 column-item" v-if="selectedStrategy">
          <label for="selectedPaper" class="input-label">Cenný papier</label>
          <CDropdown v-if="selectedAction === 'buy'" v-model="selectedPaper" :options="formattedSecuritiesList" filter
                     optionLabel="name"
                     placeholder="Vyhľadajte cenný papier..." class="w-full md:w-14rem mb-2"/>
          <CDropdown v-if="selectedAction === 'sell'" v-model="selectedPaper" :options="securitiesForSale" filter
                     optionLabel="security.name"
                     placeholder="Vyhľadajte cenný papier..." class="w-full md:w-14rem mb-2"/>
        </div>
        <div class="flex flex-column gap-2 column-item" v-if="selectedPaper && selectedStrategy">
          <label for="amount" class="input-label">Počet</label>
          <CInputNumber :maxFractionDigits="0" v-model="amount"/>
        </div>
        <div class="flex flex-column gap-2 column-item" v-if="selectedPaper && selectedStrategy">
          <label for="note" class="input-label">Poznámka</label>
          <CInputText v-model="note"/>
        </div>
      </div>
      <div class="mt-5 flex justify-between">
        <CButton class="gray-button grow !mr-4" label="Zavrieť" @click="modalOpened = false"/>
        <CButton label="Predať" class="black-button grow !ml-4" :disabled="buttonDisabled" @click="sellButton"
                 v-if="selectedStrategy && selectedAction === 'sell'"/>
        <CButton label="Kúpiť" class="black-button grow !ml-4" :disabled="buttonDisabled" @click="buyButton"
                 v-if="selectedStrategy && selectedAction === 'buy'"/>
      </div>

    </CSidebar>

    <CSidebar
        header="Pokyn"
        v-model:visible="addCpOpen"
        position="right"
        :pt="{closeButton: {class: ['close-button']}}"
        style="width: 500px;"
    >
      <template #header>
        <h1 style="font-size: 1.5rem;">Pridanie CP</h1>
      </template>
      <div class="flex flex-column gap-2 column-item">
        <label for="note" class="input-label">ISIN</label>
        <CInputText v-model="addCpData.isin"/>
      </div>
      <div class="flex flex-column gap-2 column-item">
        <label for="note" class="input-label">Ticker</label>
        <CInputText v-model="addCpData.ticker"/>
      </div>
      <div class="flex flex-column gap-2 column-item">
        <label for="note" class="input-label">Názov</label>
        <CInputText v-model="addCpData.title"/>
      </div>
      <div class="flex flex-column gap-2 column-item">
        <label for="selectedPaper" class="input-label">Mena</label>
        <CDropdown show-clear v-model="addCpData.currency" :options="currencyList" optionLabel="name"
                   placeholder="Vyberte menu" class="w-full mb-4"/>
      </div>
      <div class="flex flex-column gap-2 column-item">
        <label for="note" class="input-label">Dôvod</label>
        <CInputText v-model="addCpData.reason"/>
      </div>
      <div class="mt-5 flex justify-between">
        <CButton class="gray-button grow !mr-4" label="Zavrieť" @click="addCpOpen = false"/>
        <CButton label="Pridať" class="black-button grow !ml-4" :disabled="addCpButtonDisabled" @click="submitCp"/>
      </div>
    </CSidebar>


    <div v-if="performanceChartVisible" class="w-auto">
      <div class="transfer-header flex items-center justify-between pl-8 pr-8">
        <div class="flex flex-row">
          <h1>
            {{ selectedSecurity?.ticker + " - " + selectedSecurity?.name + " (" + selectedSecurity?.isin + ") " }} </h1>
          <h1 class="ml-5" style="font-weight: bold">
            {{ selectedSecurity?.last_price + " " + selectedSecurity.currency }}</h1>
        </div>
        <!--        <h1>{{ selectedSecurity?.last_price }}</h1>-->
        <div class="flex items-center space-x-4 pl-4">
          <p class="back-button cursor-pointer" @click="tooglePerformanceChart">
            Zavrieť
          </p>
        </div>
      </div>

      <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8 w-1/2 mx-8 my-9 w-auto">
        <CButton
            v-for="range in chartDateRanges"
            :key="range.value"
            :class="{ 'time-range-button-selected': selectedDateRange === range.value, 'time-range-button': selectedDateRange !== range.value }"
            @click="changeChartRange(range.value)"
        >
          {{ range.label }}
        </CButton>
        <hr>
        <SecurityPerformanceChart v-if="pricesArray" :data="pricesArray" :options="validFromDateArray"
                                  type=""/>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import {useSecuritiesStore} from "@/stores/securitiesStore";
import {useMainStore} from "@/stores/store";
import {useClientsStore} from "@/stores/clientsStore";
import {useEnumsStore} from "@/stores/enumsStore";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import {arrowDown} from "@formkit/icons";
import {useRequestStore} from "@/stores/requestsStore";
import SecurityPerformanceChart from "@/components/Securities/SecurityPerformanceChart.vue";
import Paginator from 'primevue/paginator';

export default {
  name: 'All securities view',
  components: {SecurityPerformanceChart, Loader, Table, Paginator},
  methods: {
    arrowDown() {
      return arrowDown
    },
  },
  setup() {

    const menu = ref();
    const items = ref([
      {
        label: 'Možnosti',
        items: [
          {
            label: 'Nakúpiť',
            icon: 'pi pi-arrow-circle-up',
            command: () => {
              openModal('buy')
            }
          },
          // {
          //   label: 'Predať',
          //   icon: 'pi pi-arrow-circle-down',
          //   command: () => {
          //     openModal('sell')
          //   }
          // },
          {
            label: 'Vývoj',
            icon: 'pi pi-chart-line',
            command: () => {
              openDialog()
            }
          }
        ]
      }
    ]);


    const securitiesStore = useSecuritiesStore();
    const requestStore = useRequestStore();
    const mainStore = useMainStore();
    const clientsStore = useClientsStore();
    const enumsStore = useEnumsStore();

    const modalOpened = ref(false);
    const selectedAction = ref(undefined);

    const dialogVisible = ref(false);
    const addCpOpen = ref(false);

    const cummulativePerformance = computed(() => securitiesStore.getCumulativePerformancesArray);
    const cummulativePerformanceEur = computed(() => securitiesStore.getCumulativePerformancesEurArray);
    const prices = computed(() => securitiesStore.getPricesArray);
    const pricesEur = computed(() => securitiesStore.getPricesEurArray);
    const getValidFromDateArray = computed(() => securitiesStore.getValidFromDateArray);
    const currencyList = computed(() => enumsStore.getCurrencies);

    const selectedSecurity = ref(undefined);
    const selectedPaper = ref(undefined);
    const selectedClient = ref(undefined);
    const selectedStrategy = ref(undefined);
    const amount = ref(undefined);
    const note = ref(undefined);
    const searchValue = ref(undefined);
    const performanceChartVisible = ref(false);
    const tooglePerformanceChart = () => {
      performanceChartVisible.value = !performanceChartVisible.value;
    };

    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (filteredSecuritiesList?.value.length > 10) {
        return filteredSecuritiesList.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return filteredSecuritiesList.value
      }
    });

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    const chartData = ref();
    const chartOptions = ref();


    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Function to get the date exactly one year ago from the given date
    const getOneYearAgoDate = (date) => {
      const today = new Date(date);
      today.setFullYear(today.getFullYear() - 1);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const to = ref(getTodayDate());
    const from = ref(getOneYearAgoDate(to.value));


    const isLoading = computed(() => mainStore.getIsLoading);
    const clientList = computed(() => clientsStore.getFormattedClientList);
    const contractList = computed(() => {
      const contracts = clientsStore.getContracts;

      if (!contracts.content || contracts?.content?.length === 0) {
        return [];
      }

      // Map only the 'name' and 'uuid' properties for each contract
      return contracts.content.map(contract => ({name: contract.contract_type.name, uuid: contract.uuid}));
    });

    const validFromDateArray = computed(() => securitiesStore.validFromDateArray);
    const formattedDateArray = computed(() => validFromDateArray.value.map(date => formatDate2(date)));
    const pricesArray = computed(() => securitiesStore.pricesArray);
    const pricesEurArray = computed(() => securitiesStore.pricesEurArray);
    const cumulativePerformancesArray = computed(() => securitiesStore.cumulativePerformancesArray);
    const cumulativePerformancesEurArray = computed(() => securitiesStore.cumulativePerformancesEurArray);

    const chartRanges = [
      {label: 'Cena', value: pricesArray.value},
      {label: 'Kumulatívna výkonnosť', value: cumulativePerformancesArray.value},
    ];

    const chartDateRanges = [
      {label: '1M', value: '1M'},
      {label: '3M', value: '3M'},
      {label: '6M', value: '6M'},
      {label: '1Y', value: '1Y'},
      {label: '3Y', value: '3Y'},
      {label: 'YTD', value: 'YTD'},
      {label: 'MAX', value: 'MAX'},
    ];

    const selectedFilter = ref("kumulative");
    const selectedDateRange = ref('1Y');
    const fs = ref();



    watch(selectedClient, async (newValue, oldValue) => {
      console.log("Watch selectedClient");
      if (newValue) {
        console.log("Watch selectedClient - newValue", newValue);
        await clientsStore.fetchClientStrategies(newValue.uuid, false);
        const allStrategies = clientsStore.getClientStrategies;
        const filteredStrategies = allStrategies.filter(strategy => strategy?.id === 50);
        fs.value = filteredStrategies;
        console.log("Filtered strategies: ", filteredStrategies);
      }
    });

    watch(modalOpened, (newValue, oldValue) => {
      if (newValue === false) {
        selectedPaper.value = undefined;
        selectedSecurity.value = undefined;
        selectedClient.value = undefined;
        selectedStrategy.value = undefined;
        amount.value = undefined;
        note.value = undefined;
      }
    });

    watch([selectedSecurity], (newValues, oldValues) => {
      const [newSelectedSecurity] = newValues;
      securitiesStore.fetchHistoricalPrices(newSelectedSecurity?.id, from.value, to.value, 3000, false);
    });

    watch([selectedPaper], (newValues, oldValues) => {
      if (newValues[0]?.amount) {
        amount.value = newValues[0].amount
      }
    });

    watch(selectedFilter, (newValues, oldValues) => {
      const newSelectedFilter = newValues;
      setChartFilter(newSelectedFilter);
    });

    const setChartFilter = async (range) => {
      selectedFilter.value = range;
      chartData.value = setChartData(selectedFilter.value);
    };

    const changeChartRange = async (range) => {
      const previous_date = new Date();
      switch (range) {
        case '1M':
          previous_date.setMonth(previous_date.getMonth() - 1);
          break;
        case '3M':
          previous_date.setMonth(previous_date.getMonth() - 3);
          break;
        case '6M':
          previous_date.setMonth(previous_date.getMonth() - 6);
          break;
        case '1Y':
          previous_date.setFullYear(previous_date.getFullYear() - 1);
          break;
        case '3Y':
          previous_date.setFullYear(previous_date.getFullYear() - 3);
          break;
        case 'YTD':
          previous_date.setMonth(0);
          break;
        case 'MAX':
          previous_date.setFullYear(2000);
          break;
      }
      selectedDateRange.value = range;
      securitiesStore.fetchHistoricalPrices(selectedSecurity.value.id, previous_date.toISOString().split('T')[0], to.value, 3000, false);
      // await clientsStore.fetchClientPerformance(clientsStore?.client?.uuid, previous_date.toISOString().split('T')[0]);
      // chartData.value = setChartData();
    };

    const buttonDisabled = computed(() => {
      if (!selectedClient.value || !selectedPaper.value || !selectedStrategy.value || !amount.value) {
        return true;
      } else {
        return false;
      }
    });

    const addCpButtonDisabled = computed(() => {
      if (!addCpData.value.isin || !addCpData.value.ticker || !addCpData.value.title || !addCpData.value.currency || !addCpData.value.reason) {
        return true;
      } else {
        return false;
      }
    });

    const securitiesList = computed(() => securitiesStore.getSecuritiesList);
    const formattedSecuritiesList = computed(() => securitiesStore.getFormattedSecuritiesList);
    const securitiesForSale = ref();

    watch([selectedStrategy], async (newValues, oldValues) => {
      const [newSelectedStrategy] = newValues;

      // if (newSelectedStrategy?.is_equities_for_sell) {
      try {
        const response = await clientsStore.fetchClientSecurities(selectedClient.value.uuid, "50", false);
        securitiesForSale.value = response.content;
      } catch (error) {
        // Handle the error appropriately
        console.error("Error fetching client securities:", error);
      }
      // }
    });

    const filteredSecuritiesList = computed(() => {

      if (!searchValue.value) {
        return formattedSecuritiesList.value;
      }
      return formattedSecuritiesList.value.filter(security => {
        const search = searchValue.value.toLowerCase();

        return security.name.toLowerCase().includes(search) || security.isin.toLowerCase().includes(search) || security.ticker.toLowerCase().includes(search);
      });
    });

    const toggle = (data, event) => {
      menu.value.toggle(event);
      selectedSecurity.value = data;
    };


    const openModal = (action) => {
      if (selectedSecurity.value) {
        selectedPaper.value = selectedSecurity.value
      }

      selectedAction.value = action;
      modalOpened.value = true;
    };

    const addCpData = ref({
      isin: '',
      ticker: '',
      title: '',
      currency: '',
      reason: '',
    });
    const buyButton = () => {
      const requestData = {
        client_uuid: selectedClient.value.uuid,
        strategy_id: selectedStrategy.value.id,
        request_type_id: 3,
        equity_id: selectedPaper.value.id,
        quantity: parseFloat(amount.value),
        note: note.value,
      };

      // const requestDataJson = JSON.stringify(requestData);
      requestStore.buySellRequest(selectedClient.value.uuid, selectedStrategy.value.id, 3, selectedPaper.value.id, parseFloat(amount.value), note.value);


      selectedPaper.value = undefined;
      selectedClient.value = undefined;
      selectedStrategy.value = undefined;
      amount.value = undefined;
      note.value = undefined;

      modalOpened.value = false;
    };


    const sellButton = () => {
      const requestData = {
        client_uuid: selectedClient.value.uuid,
        strategy_id: selectedStrategy.value.id,
        request_type_id: 4,
        equity_id: selectedPaper.value.id,
        quantity: parseFloat(amount.value),
        note: note.value,
      };

      requestStore.buySellRequest(selectedClient.value.uuid, selectedStrategy.value.id, 4, selectedPaper.value.id, parseFloat(amount.value), note.value);


      selectedPaper.value = undefined;
      selectedClient.value = undefined;
      selectedStrategy.value = undefined;
      amount.value = undefined;
      note.value = undefined;

      modalOpened.value = false;
    };

    const submitCp = () => {
      securitiesStore.newSecurity(addCpData.value.isin, addCpData.value.ticker, addCpData.value.title, addCpData.value.currency?.name, addCpData.value.reason);
      // requestStore.addCpRequest(requestData);
      addCpOpen.value = false;

      addCpData.value = {
        isin: '',
        ticker: '',
        title: '',
        currency: '',
        reason: '',
      };
    }

    const openDialog = () => {
      // setTimeout(() => {
      // dialogVisible.value = true;
      performanceChartVisible.value = true;
      // }, 200);
    };


    const formatNumber = (num) => {
      const parts = num.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    };

    const formatDate = (transactionTime) => {
      const date = new Date(transactionTime);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return date.toLocaleString("sk-SK", options);
    };

    const formatDate2 = (date) => {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
      return new Date(date).toLocaleDateString('sk-SK', options);
    };


    onBeforeMount(() => {
      securitiesStore.fetchSecuritiesList(0, 50000, 4);
      clientsStore.fetchClientList(0, 50000, "client");
    });

    return {
      securitiesColumns: [
        {field: "name", header: "Názov cenného papiera", sortable: true},
        {field: "isin", header: "ISIN", sortable: true},
        {field: "ticker", header: "Ticker", sortable: true},
        {field: "type", header: "Typ", sortable: true},
        {field: "last_price", header: "Trhová cena", class: "font-bold !text-right !justify-end", sortable: true},
        {field: "currency", header: "Mena"},
      ],
      securitiesList,
      tradesListPaginator,
      valueInPaginatorTrades,
      tradesListPaginator,
      updateIndexTrades,
      isLoading,
      formattedSecuritiesList,
      securitiesForSale,
      formatNumber,
      formatDate,
      menu,
      items,
      toggle,
      selectedSecurity,
      selectedPaper,
      selectedClient,
      amount,
      note,
      buttonDisabled,
      addCpButtonDisabled,
      buyButton,
      sellButton,
      submitCp,
      clientList,
      contractList,
      openModal,
      modalOpened,
      selectedAction,
      searchValue,
      filteredSecuritiesList,
      dialogVisible,
      getTodayDate,
      getOneYearAgoDate,
      chartData,
      chartOptions,
      openDialog,
      chartRanges,
      setChartFilter,
      selectedFilter,
      to,
      from,
      chartDateRanges,
      selectedDateRange,
      changeChartRange,
      cummulativePerformanceEur,
      getValidFromDateArray,
      prices,
      pricesEur,
      validFromDateArray,
      formattedDateArray,
      pricesArray,
      pricesEurArray,
      cumulativePerformancesArray,
      cumulativePerformancesEurArray,
      performanceChartVisible,
      tooglePerformanceChart,
      selectedStrategy,
      clientsStore,
      fs,
      addCpOpen,
      currencyList,
      addCpData,
    };
  },
}
;
</script>

<style scoped lang="scss">

.transfer-header {
  background-color: white;
  width: 100%;
  height: 86px;

  h1 {
    font-size: 20px;
    font-weight: 400;
  }
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.menu-icon-clickable {
  cursor: pointer;
  padding: 12px 14px 12px 14px;
  border-radius: 5px;
  width: 50px;
  text-align: center;
}

.menu-icon-clickable:hover {
  background-color: #000000;
  color: #fff;
  padding: 12px 14px 12px 14px;
  border-radius: 5px;
  width: 50px;
  text-align: center;
}

.change-pill-down {
  padding: 0px 10px 0px 10px;
  border-radius: 100px;
  text-align: center;
  background-color: rgb(237, 94, 53);
  color: white;
  font-size: 0.8rem;
}

.change-pill-up {
  padding: 0px 10px 0px 10px;
  border-radius: 100px;
  text-align: center;
  background-color: #122f29;
  color: white;
  font-size: 0.8rem;
}

.delete-all-pill {
  display: flex;
  background-color: #F2F1F1;
  padding: 0.3rem 1rem;
  align-items: center !important;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: bold;
}

.time-range-button {
  background-color: white !important;
  color: gray;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  font-size: 0.9rem;

  &:hover {
    color: gray !important;
  }
}

.time-range-button-selected {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 4px !important;
  font-size: 0.9rem;
}

hr {
  margin: 10px 0;
  color: black !important;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .custom-mobile-view {
    display: none;
  }
  .custom-css {
    flex-direction: column;
    gap: 10px;

    input {
      width: 100%;
    }
  }
  .custom-css span {
    margin-left: 0;
  }
  .mobile-view {
    padding: 1rem;
  }
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
