<template>
  <apexchart
      type="line"
      v-if="chartData.length > 0"
      :options="chartOptions"
      :series="chartData"
      ref="apexChart"
      height="350"
  />
  <p v-else>No data available for the chart</p>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, defineProps } from "vue";

// Define props to accept multiple datasets and annotations, and showAsset to toggle secondary y-axis
const props = defineProps<{
  data: { name: string; data: number[] }[]; // Multiple datasets
  options: string[]; // X-axis labels
  annotations: any[]; // Annotations prop
  showAsset: boolean; // New prop to toggle asset on the right
}>();

// Refs for chart data and options
const chartData = ref([]);
const chartOptions = ref({});
const chartDataRef = toRef(props, 'data');

// Function to format currency and percentages
const formatterPercent = (value: number) => {
  return new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value) + '%';
};

const formatterCurrency = (value: number) => {
  return new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(value);
};

const getChartOptions = () => {
  chartOptions.value = {
    chart: {
      id: "vuechart-example",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "solid",
      opacity: [1, 1],
    },
    colors: ['#FF5733', '#33B5FF'], // Orange for Výkonnosť, Blue for Majetok
    xaxis: {
      categories: props.options, // Labels for x-axis
      type: 'datetime',
    },
    yaxis: [
      {
        // Left y-axis for performance
        title: {
          text: 'Výkonnosť (%)',
        },
        labels: {
          formatter: formatterPercent,
        },
      },
      ...(props.showAsset
          ? [
            {
              // Right y-axis for assets (Majetok)
              opposite: true,
              title: {
                text: 'Majetok (EUR)',
              },
              labels: {
                formatter: formatterCurrency,
              },
            },
          ]
          : []),
    ],
    annotations: {
      points: props.annotations || [],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y, { seriesIndex }) {
          // Use percentage formatter for performance, currency formatter for assets
          return seriesIndex === 0 ? formatterPercent(y) : formatterCurrency(y);
        },
      },
    },
  };
};


// Function to update chart data
const updateChartData = () => {
  chartData.value = props.data.map((dataset) => ({
    name: dataset.name,
    data: dataset.data,
  }));
};

// Call chart setup functions on component mount
onMounted(() => {
  getChartOptions();
  updateChartData();
});

// Watch for changes in data and annotations
watch([chartDataRef, () => props.annotations, () => props.showAsset], () => {
  getChartOptions(); // Recalculate chart options when showAsset changes
  updateChartData();
});
</script>
