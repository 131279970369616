<template>
    <div class="flex h-screen bg-white">
        <!-- Left Side: Login Form -->
        <div class="w-1/2 p-8 relative flex flex-col justify-center items-center custom-css-mobile-width">
            <!-- Logo at the very top -->
          <img src="../assets/Logo_Clear.svg" alt="Logo" class="w-[100px] h-[100px] absolute top-0 left-0 mt-12 ml-9"/>

            <!-- Body Section -->
            <form @submit.prevent="sendResetLink" class="text-left" v-if="!emailSent">
              <!-- Header Section -->
              <div class="mb-8 ">
                <!-- Header Text -->
                <h1 class="text-4xl">Zabudnuté heslo</h1>
              </div>

                <!-- Username Input -->
                <p class="text-sm text-gray-400 py-1">E-mail</p>
                <div class="mb-4">
                    <input
                            v-model="username"
                            type="text"
                            class="w-420 px-3 py-2 border rounded-md"
                    />
                </div>

              <div class="flex items-center mb-4 pt-4">
                <p class="text-sm">Už mám vytvorené Flip konto.&nbsp</p><p class="text-sm forgot-password"><router-link to="/login">Prihláste sa</router-link></p>
              </div>

              <!-- horizontal line -->
              <div class="py-4">
                <hr class="mb-4">
              </div>

                <!-- Login Button -->
                <div class="mb-4">
                    <button
                            type="submit"
                            class="w-420 bg-black text-white py-2 rounded-md"
                    >
                        Zabudnuté heslo
                    </button>
                </div>
            </form>
            <div v-if="emailSent">
              <div class="mb-8 ">
                <!-- Header Text -->
                <h1 class="text-4xl">Zabudnuté heslo</h1>
              </div>
              <h2>Na Váš e-mail sme Vám zaslali link, prostredníctvom ktorého si môžete nastaviť nové heslo.</h2>
              <!-- horizontal line -->
              <div class="py-4">
                <hr class="mb-4">
              </div>
              <div class="flex items-center mb-4 pt-4">
                <p class="text-sm">Spomenuli ste si na heslo?&nbsp</p><p class="text-sm forgot-password"><router-link to="/login">Prihláste sa</router-link></p>
              </div>
            </div>
        </div>


        <!-- Right Side: Video -->
        <div class="w-1/2 bg-black relative custom-css-mobile" v-if="showVideo()">
            <video autoplay loop muted class="w-full h-full absolute inset-0 object-cover">
                <source src="../assets/BallVideo.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</template>

<script>
import {useAuthStore} from "@/stores/authStore";

export default {
    name: "ForgotPasswordView",
    data() {
        return {
            username: "",
            emailSent: false,
        };
    },
    methods: {
        async sendResetLink() {
            try {
                // Access the authStore instance from main.ts
                const authStore = useAuthStore();

                // Call the forgotPassword action with lang and username
                await authStore.forgotPassword({ lang: "sk", username: this.username });

                // Set emailSent to true after successful request
                this.emailSent = true;
            } catch (error) {
                // Handle error (e.g., display an error message)
                console.error("Forgot password error:", error);
            }
        },
      showVideo() {
        return window.innerWidth > 950;
      }
    },
    created() {
    }
}
</script>

<style scoped>
.w-420 {
    width: 420px;
}
.forgot-password {
  color: #FF5C33;
  text-decoration: underline;
}
@media only screen and (max-width: 950px) {
    .custom-css-mobile{
        display: none;
    }
    .custom-css-mobile-width{
        width: 100%;
        form{
            width: 330px;
            input{
                width: 330px;
            }
            button{
                width: 330Px;
            }
        }
    }
}
</style>
