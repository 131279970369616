<template>
  <div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <CButton @click="toogleSidebar = true" class="black-button" style="background-color:#FF5C33"
               label="Nahrať dokument"></CButton>

      <Table v-if="uploadedFiles.length > 0"
             :columns="documentsColumns"
             :data="uploadedFiles"
             :pagination="true"
             class="m-3">

        <CColumn field="icon">
          <template #body="{ data }">
            <p class="menu-icon-clickable" @click="deleteDocument(data)">
              <font-awesome-icon :icon="['fas', 'xmark']"/>
            </p>
          </template>
        </CColumn>
      </Table>
    </div>

    <CSidebar v-model:visible="toogleSidebar"
              position="right"
              header=""
              style="width: 500px; padding: 1rem;"
              :pt="{closeButton: {class: ['close-button']}}"
              @hide="close">
      <div class="root-ctab-panel-items mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="selectedPaper" class="input-label">Typ dokumentu</label>
          <CDropdown v-model="selectedFileType" :options="fileTypes" filter optionLabel="name"
                     placeholder="Vyberte typ dokumentu" class="w-full md:w-14rem mb-2"/>
        </div>
        <div class="file-input-container" v-if="!file">
          <input type="file" @change="handleFileChange"/>
          <div class="file-input-label">Presuňte alebo vyberte dokument</div>
        </div>
        <div class="file-input-container" v-if="file">
          <p>Selected file: {{ file?.name }}</p>
        </div>
        <div class="mt-4">
          <CButton @click="uploadDocument" label="Nahrať dokument" class="black-button mt-2"></CButton>
        </div>
      </div>
    </CSidebar>
  </div>
</template>

<script setup lang="ts">
import {computed, defineEmits, ref, watch} from "vue";
import {useEnumsStore} from '@/stores/enumsStore';
import {useClientsStore} from "@/stores/clientsStore";
import Table from "@/components/CustomUIComponents/Table.vue";

const enumsStore = useEnumsStore();
const clientsStore = useClientsStore();
const emit = defineEmits([])

const toogleSidebar = ref(false);
const selectedFileType = ref(undefined);
const file = ref(undefined);
const uploadedFiles = ref([]);

watch(() => uploadedFiles.value, (newValue, oldValue) => {
  emit('uploadedDocumentsUpdate', newValue);
});

watch(() => selectedFileType.value, (newValue, oldValue) => {
});

const fileTypes = computed(() => {
  if (enumsStore.getFileTypes.length === 0) {
    enumsStore.fetchFileTypes;
  }

  const allFileTypes = enumsStore.fileTypes;
  return [16, 9, 14, 10, 11, 12].map(id =>
      allFileTypes.find(fileType => fileType.id === id)
  ).filter(Boolean);
});

const close = () => {
  toogleSidebar.value = false;
  selectedFileType.value = undefined;
  file.value = undefined;
};

const handleFileChange = (event) => {
  file.value = event.target.files[0];
};

const deleteDocument = (document) => {
  const index = uploadedFiles.value.indexOf(document);
  if (index !== -1) {
    uploadedFiles.value.splice(index, 1);
  }
};

const uploadDocument = () => {
  if (file.value) {
    uploadedFiles.value.push({
      name: file.value.name,
      fileType: selectedFileType?.value.name,
      size: file.value.size,
      type: selectedFileType?.value.id,
      data: file.value,
    });
    emit('uploadedDocumentsUpdate', uploadedFiles.value);
    toogleSidebar.value = false;
    selectedFileType.value = undefined;
    file.value = undefined;
  }
};

const documentsColumns = [
  {field: "fileType", header: "Typ dokumentu"},
  {field: "name", header: "Nazov prilohy"}
];
</script>

<style scoped>
hr {
  margin: 10px 0;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.file-input-container {
  @apply relative w-full mt-5 h-20 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer overflow-hidden;

  &:hover {
    @apply border-[#FF5C33];
  }
}

input[type="file"] {
  @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
}

.file-input-label {
  @apply text-center text-gray-500;
}
</style>
