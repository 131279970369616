<template>
  <div class="h-full">
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Reporty na stiahnutie</h1>
      <hr/>
      <div class="flex-column mt-8">
        <div class="hlasenie-class flex-column" v-for="(hlasenie, index) in hlasenia" :key="hlasenie.id">
          <div class="flex-column justify-content-around mb-2">
            <h3 class="hlasenie-nadpis">{{ hlasenie.name }}</h3>
            <p class="text-xs text-gray-400 mt-1">{{ hlasenie.popis }}</p>
          </div>
          <div class="flex gap-8 mr-0 justify-between width-mobile">
            <div class="flex flex-column gap-2 column-item" v-if="hlasenie.dateFrom">
              <label for="dateFrom" class="input-label">Dátum od</label>
              <!--              <CCalendar v-model="dateFrom[index]" dateFormat="dd.mm.yy" show-icon/>-->
              <CCalendar v-model="dateFrom[index]" @update:modelValue="dateFrom[index] = formatDate($event)"
                         dateFormat="dd.mm.yy" show-icon/>
            </div>
            <div class="flex flex-column gap-2 column-item" v-if="hlasenie.dateTo">
              <label for="dateTo" class="input-label">Dátum do</label>
              <!--              <CCalendar v-model="dateTo[index]" dateFormat="dd.mm.yy" show-icon />-->
              <CCalendar v-model="dateTo[index]" @update:modelValue="dateTo[index] = formatDate($event)"
                         dateFormat="dd.mm.yy" show-icon/>
            </div>
            <div class="flex flex-column gap-2 column-item">
              <label for="format" class="input-label">Formát</label>
              <CDropdown v-model="format[index]" :options="hlasenie.formaty" placeholder="Vyberte formát"
                         class="md:w-14rem"/>
            </div>
            <div class="flex flex-column column-item justify-end">
              <CButton
                  label="Exportovať"
                  icon="pi pi-cloud-download"
                  class="black-button w-full mt-3"
                  :disabled="hlasenie.dateFrom && hlasenie.dateTo ? !dateFrom[index] || !dateTo[index] || !format[index] : !format[index]"
                  @click="stiahniReport(hlasenie, index)"
              />
            </div>
          </div>
          <!--ciara vypnuta pre posledny element-->
          <hr v-if="hlasenie.id < hlasenia.length" class="large-hr">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAccountStore} from "@/stores/accountStore";
import {computed, ref} from "vue";
import saveAs from 'file-saver';

export default {
  name: 'AccountReporting',
  setup() {
    const accountStore = useAccountStore();

    const dateFrom = ref([]);
    const dateTo = ref([]);
    const format = ref([]);

    const stiahniReport = async (hlasenie, index) => {
      console.log(dateFrom.value, dateTo.value, format.value)
      console.log(dateFrom.value[index], dateTo.value[index], format.value[index])
      if (hlasenie.id === 5) {
        await accountStore.getAnnualReport(dateFrom.value[index], dateTo.value[index], format.value[index]);
      } else if (hlasenie.id === 4) {
        await accountStore.getQuartalReport(dateFrom.value[index], dateTo.value[index], format.value[index]);
      } else if (hlasenie.id === 3) {
        await accountStore.getCommissionsReport(dateFrom.value[index], dateTo.value[index], format.value[index]);
      } else if (hlasenie.id === 2) {
        await accountStore.getSummaryAssets(format.value[index]);
      } else if (hlasenie.id === 1) {
        await accountStore.getSummaryClients(format.value[index]);
      }
    };

    const hlasenia = [
      {
        id: 1,
        name: 'Sumárny report klientov',
        popis: 'Zoznam všetkých klientov s prehľadom ich majetku v delení na cenné papiere a hotovosť.',
        dateFrom: false,
        dateTo: false,
        formaty: ['.xlsx', '.xml'],
      },
      {
        id: 2,
        name: 'Sumárny report majetku',
        popis: 'Zoznam celkového majetku v správe v delení na cenné papiere a stratégie, v ktorých sú cenné papiere nakúpené.',
        dateFrom: false,
        dateTo: false,
        formaty: ['.xlsx', '.xml'],
      },
      {
        id: 3,
        name: 'Provízny report',
        popis: 'Prehľad všetkých provízií za vybrané obdobie rozdelené podľa klientov, typu poplatku a stratégie, na ktorej bol poplatok zaúčtovaný.',
        dateFrom: true,
        dateTo: true,
        formaty: ['.xlsx', '.xml'],
      },
      {
        id: 4,
        name: 'Štvrťročný výkaz o vykonávaní finančného sprostredkovania',
        popis: 'Podľa §36 ods. 8 Zákona č. 186/2009 Z.z.',
        dateFrom: true,
        dateTo: true,
        formaty: ['.xls', '.xml'],
      },
      {
        id: 5,
        name: 'Ročný výkaz o vykonávaní finančného sprostredkovania',
        popis: 'Podľa §36 ods. 8 Zákona č. 186/2009 Z.z.',
        dateFrom: true,
        dateTo: true,
        formaty: ['.xls', '.xml'],
      },
    ];


    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      hlasenia,
      formatDate,
      stiahniReport,
      dateFrom,
      dateTo,
      format
    };
  },
};
</script>


<style>

@media only screen and (max-width: 1372px) {
}

</style>
<style lang="scss" scoped>
.hlasenie-nadpis {
  font-size: 1.1rem;
  font-weight: 400;
}

.large-hr {
  margin: 25px 0 !important;
}

.root-ctab-panel-items-mobile {
  display: none;
}

.column-item {
  flex-direction: column;
  width: 20%;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items-desktop {
    display: none;
  }
  .root-ctab-panel-items-mobile {
    display: block;

    h3 {
      background-color: #F2F1F1;
      color: #949494;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .width-mobile {
    flex-direction: column
  }
  .column-item {
    width: 100%;
  }
}

</style>
