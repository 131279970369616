<template>
  <div>
    <div class="pr-7 pl-7 bg-white rounded-lg shadow-md m-3 mb-8 pb-8">
      <CDataView :value="products" paginator paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink" :rows="5">
        <template #list="spravy">
          <div class="flip-news-container gap-5">
            <div class="col-8 flip-inside-container">
              <div>
                <div class="flex">
                  <i class="pi pi-circle-fill not-read" v-if="!spravy.data.read"></i>
                  <h1 class="text-xl mb-3">{{ spravy.data.title }}</h1>
                </div>
                <p class="text-sm text-gray-400">{{ spravy.data.text }}</p>
              </div>
              <p class="text-sm underline read_more">Prečítať viac</p>
            </div>
            <div class="col-4 mobile-view">
                <img class="w-3rem rounded-lg" width="250"
                     :src="`https://primefaces.org/cdn/primevue/images/product/${spravy.data.image}`" :alt="spravy.data.name" />
            </div>
          </div>
        </template>
      </CDataView>
    </div>
  </div>
  <!--  </div>-->
</template>
<script>

export default {
  name: 'AccountMessages',
  setup() {

    const products = [{
      id: '1000',
      image: 'bamboo-watch.jpg',
      title: 'WEM vytvoril nové portfólio pre odvážnych.',
      read: false,
      text: 'Maecenas volutpat ultrices mattis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean eu tempor elit. Morbi ut nisl leo. Phasellus eget libero et tellus maximus luctus sit amet id lacus. '
    },
      {
        id: '1001',
        image: 'black-watch.jpg',
        title: 'Zmena poplatkov',
        read: false,
        text: 'Maecenas volutpat ultrices mattis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean eu tempor elit. Morbi ut nisl leo. Phasellus eget libero et tellus maximus luctus sit amet id lacus. '
      },
      {
        id: '1002',
        image: 'black-watch.jpg',
        title: 'Legislatívna zmena pre cenné papiere',
        read: true,
        text: 'Maecenas volutpat ultrices mattis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean eu tempor elit. Morbi ut nisl leo. Phasellus eget libero et tellus maximus luctus sit amet id lacus. '
      }];

    return {
      products,
    };
  },
}
</script>

<style>

@media only screen and (max-width: 1372px) {
}

</style>
<style lang="scss" scoped>
.flip-news-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
  border-bottom: 1px solid #E5E5E5;
}
.flip-inside-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
 height: 100%;

}
.read_more{
  color: #FF5C33 !important;
}
.not-read{
  color: #FF5C33 !important;
  font-size: 0.5rem;
  padding-top: 0.7rem;
  padding-right: 0.3rem;
}
@media only screen and (max-width: 800px) {
  .mobile-view{
    display: none;
  }

}
</style>
