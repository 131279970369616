import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';


interface AumItem {
    closure_date: string;
    total_aum: number;
    // Other properties...
}

export const useDashboardStore = defineStore({
    id: 'dashboard',

    state: () => ({
        headlines: [],
        lastTransactions: [],
        totalAumData: [],
        yearsAum: [],
        yearsAumData: [],
        monthsAum: [],
        monthsAumData: []

    }),

    getters: {
        getHeadliness: (state) => state.headlines,
        getLastTransactions: (state) => state.lastTransactions,
        getTotalAum: (state) => state.totalAumData,
        getYearsAum: (state) => state.yearsAum,
        getYearsAumData: (state) => state.yearsAumData,
        getMonthsAum: (state) => state.monthsAum,
        getMonthsAumData: (state) => state.monthsAumData,
    },

    actions: {
        async fetchHeadliness(days: number) {
            try {
                const apiStore = useApiStore();
                // Include the 'history_days' parameter in the request data
                const response = await apiStore.makeApiRequest({
                    method: 'GET',
                    url: '/v1/flip/dashboard/headlines',
                    data: {
                        history_days: days,
                    },
                    contentType: 'application/x-www-form-urlencoded', // Set the content type
                });

                console.log("Headlines", response)
                // Store the list of headlines in the state
                this.headlines = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchLastTransactions() {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/transactions/last_transactions`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });

                // Store the list of clients in the state
                this.lastTransactions = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchTotalAum(groupBy: string) {
            try {
                const apiStore = useApiStore();
                const response = await apiStore.makeApiRequest({
                    method: 'GET',
                    url: '/v1/flip/dashboard/total_aum',
                    data: {
                        group_by: groupBy,
                    },
                    contentType: 'application/x-www-form-urlencoded',
                });


                this.totalAumData = response;

                if (groupBy === 'year') {
                    this.yearsAum = response.map((item: AumItem) => new Date(item.date).getFullYear().toString());
                    this.yearsAumData = response.map((item: AumItem) => item.value);
                } else if (groupBy === 'month') {
                    this.monthsAum = response.map((item: AumItem) => {
                        const date = new Date(item.date);
                        return `${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`;
                    });
                    this.monthsAumData = response.map((item: AumItem) => item.value);
                }

                return response;
            } catch (error) {
                throw error;
            }
        }
    },
});
