import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';
import router from "@/router";

class Client {
}

export const useClientsStore = defineStore({
    id: 'clients',

    state: () => ({
        clientList: Object,
        client: undefined as Client | undefined,
        bankAccounts: Object,
        securities: Object,
        cashAccounts: Object,
        performance: Object,
        messages: Object,
        transactions: Object,
        trades: Object,
        documents: Object,
        contracts: Object,
        clientRibbonData: Object,
        file: undefined as any | undefined,
        messageFile: undefined as any | undefined,
        birthNumberStatus: "",
        clientStrategies: undefined,
        totalCash: 0,
        totalAssets: 0,
        performanceData: [],
        performanceLabels: [],
    }),

    getters: {
        getClientList: (state) => state.clientList,
        getClient: (state) => state.client,
        getBankAccounts: (state) => state.bankAccounts,
        getSecurities: (state) => state.securities,
        getCashAccounts: (state) => state.cashAccounts,
        getPerformance: (state) => state.performance,
        getMessages: (state) => state.messages,
        getTransactions: (state) => state.transactions,
        getTrades: (state) => state.trades,
        getDocuments: (state) => state.documents,
        getContracts: (state) => state.contracts,
        getFile: (state) => state.file,
        getMessageFile: (state) => state.messageFile,
        getClientStrategies: (state) => state.clientStrategies,
        getBirthNumberStatus: (state) => state.birthNumberStatus,
        getPerformanceData: (state) => state.performanceData,
        getPerformanceLabels: (state) => state.performanceLabels,
        getFormattedClientList: (state) => {
            if (!state.clientList.content || state.clientList.content.length === 0) {
                console.warn("clientList is empty or not an array:", state.clientList.content);
                // return [];
            }

            const formatDate = (dateString: string) => {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            return state.clientList?.content?.map((item) => {
                const name = item?.name;
                const surname = item?.surname;
                const email = item?.contact?.email;
                const birthday = formatDate(item?.birthday);
                const birthnumber = item?.birthnumber;
                const idcard_validity = formatDate(item?.idcard_validity);
                const risk_profile = item?.risk_profile;
                const uuid = item?.uuid;
                const fullName = `${name} ${surname}`;

                return {
                    name,
                    surname,
                    email,
                    birthday,
                    birthnumber,
                    idcard_validity,
                    risk_profile,
                    uuid,
                    fullName,
                };
            });
        },
        getFormattedTrades: (state) => {
            if (!state.trades.content || state.trades.content.length === 0) {
                console.warn("tradesList is empty or not an array:", state.trades.content);
                // return [];
            }

            const formatDate = (dateString: string) => {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            const formatToSkFormat = (number: number) => {
                return number?.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            return state.trades?.content?.map((item) => {
                const datum = formatDate(item?.trade_date);
                const smer = item?.trade_type?.name;
                const strategia = item?.investment_strategy?.name;
                const cennyPapier = item?.security?.name;
                const isin = item?.security?.isin;
                const pocet = item?.quantity;
                const cena = formatToSkFormat(item?.price);
                const objem = formatToSkFormat(item?.trade_value);
                const mena = item?.security?.currency?.name;

                return {
                    datum,
                    smer,
                    strategia,
                    cennyPapier,
                    isin,
                    pocet,
                    cena,
                    objem,
                    mena,
                };
            });
        },
        getFormattedDocumentsList: (state) => {
            if (!state.documents.content || state.documents.content.length === 0) {
                console.warn("documents is empty or not an array:", state.documents.content);
                // return [];
            }

            const formatDate = (dateString: string) => {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            return state.documents?.content?.map((item) => {
                const documentType = item.file_type.name;
                const name = item.name;
                const date = formatDate(item.created);
                const documentId = item.id
                const documentName = item.name


                return {
                    documentType,
                    name,
                    date,
                    documentId,
                    documentName
                };
            });
        },
        getFormattedMessagesList: (state) => {
            const messagesContent = state.messages && state.messages.content;
            if (!messagesContent || messagesContent.length === 0) {
                console.warn("messages is empty or not an array:", messagesContent);
                return [];
            }

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                };
                return date.toLocaleDateString('sk-SK', options);
            };

            const stripHtmlTags = (html) => {
                const tempElement = document.createElement("div");
                tempElement.innerHTML = html;
                return tempElement.textContent || tempElement.innerText || "";
            };

            return messagesContent.map((item) => {
                const messageWithHtml = item.text; // Original message with HTML tags
                const messageWithoutHtml = item.text ? stripHtmlTags(item.text) : ""; // Check if item.text exists before stripping HTML tags

                let truncatedMessageWithoutHtml = messageWithoutHtml;

                if (truncatedMessageWithoutHtml.length > 40) {
                    // Truncate the message to the last full word before 100 characters
                    truncatedMessageWithoutHtml = truncatedMessageWithoutHtml.substring(0, 40);
                    const lastSpaceIndex = truncatedMessageWithoutHtml.lastIndexOf(' ');
                    if (lastSpaceIndex !== -1) {
                        truncatedMessageWithoutHtml = truncatedMessageWithoutHtml.substring(0, lastSpaceIndex);
                    }
                    truncatedMessageWithoutHtml += '...';
                }

                const date = formatDate(item.created);
                const subject = item.subject;
                const type = item.message_type.name;
                const seen = item.seen;

                // Map each file in the files array to an object
                const files = item.files.map((file) => ({
                    file_id: file.id,
                    file_name: file.name,
                    file: file.file,
                }));

                return {
                    messageWithHtml,
                    messageWithoutHtml: truncatedMessageWithoutHtml,
                    date,
                    subject,
                    type,
                    seen,
                    files, // Include an array of files
                };
            });
        },
        getFormattedCashAccounts: (state) => {
            if (!state.cashAccounts.content || state.cashAccounts.content.length === 0) {
                console.warn("cashAccounts is empty or not an array:", state.cashAccounts.content);
                // return [];
            }

            const formatToSkFormat = (number: number | undefined) => {
                if (number === undefined) {
                    return ''; // Return an empty string or handle the undefined case appropriately
                }
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };

            return state.cashAccounts?.content?.map((item) => {
                const strategy = item?.strategy;
                const bank = item?.bank;
                const value = formatToSkFormat(item?.value);
                const currency = item?.currency;
                const value_eur = formatToSkFormat(item?.value_eur);

                return {
                    strategy,
                    bank,
                    value,
                    currency,
                    value_eur,
                };
            });
        },

        getFormattedSecurities: (state) => {
            if (!state.securities?.content || state.securities?.content?.length === 0) {
                console.warn("securities is empty or not an array:", state.securities?.content);
                // return [];
            }

            const formatToSkFormat = (number: number | undefined) => {
                if (number === undefined) {
                    return ''; // Return an empty string or handle the undefined case appropriately
                }
                return number.toLocaleString('sk-SK', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            };


            return state.securities?.content?.map((item) => {
                const name = item?.security?.name;
                const strategy = item?.strategy?.name;
                const security_name = item?.security?.name;
                const security_isin = item?.security?.isin;
                const amount = formatToSkFormat(item?.amount);
                const sum_value = formatToSkFormat(item?.sum_value);
                const currency = formatToSkFormat(item?.security?.currency?.name);
                const sum_value_eur = formatToSkFormat(item?.sum_value_eur);
                const isin = item?.security?.isin;

                return {
                    name,
                    strategy,
                    security_name,
                    security_isin,
                    amount,
                    sum_value,
                    currency,
                    sum_value_eur,
                    isin,
                };
            });
        },
        getClientTotalCash: (state) => {
            if (state.cashAccounts.content) {
                let total = 0;
                state.cashAccounts.content.forEach((item: any) => {
                    total += item.value_eur;
                });
                return total;
            }
            return 0;
        },
        getClientTotalAssets: (state) => {
            if (state.securities.content && state.securities.sum_value_eur) {
                let total = 0;
                state.securities?.content?.forEach((item: any) => {
                    total += item?.sum_value_eur;
                });
                return total;
            }
            return 0;
        },

        getFormattedContracts: (state) => {
            if (!state.contracts || state.contracts?.content?.length === 0) {
                return [];
            }

            const formatDate = (dateString: string) => {
                const date = new Date(dateString);

                // Check if the date is valid by using isNaN() on the time value
                if (isNaN(date.getTime())) {
                    return ''; // Return empty string for invalid dates
                }

                const options: Intl.DateTimeFormatOptions = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                };
                return date.toLocaleString("sk-SK", options);
            };

            return state.contracts?.content?.map((item) => {
                const full_name = item.intermediary?.name + " " + item.intermediary?.surname;
                const client_name = item?.client?.name + " " + item?.client?.surname;

                // Extract additional values from the template
                const intermediaryInfo = {
                    name: item?.intermediary?.name,
                    surname: item?.intermediary?.surname,
                    email: item?.intermediary?.email,
                    phone: item?.intermediary?.phone,
                    reg_number: item?.intermediary?.reg_number,
                    position: item?.intermediary?.position,
                };

                const bankAccountInfo = {
                    bank_account_name: item?.bank_account?.bank_account_name,
                    iban: item?.bank_account?.iban,
                };

                const clientInfo = {
                    name: item?.client?.name,
                    surname: item?.client?.surname,
                    birthnumber: item?.client?.birthnumber,
                };

                const strategiesInfo = item.strategies.map(strategy => {
                    return {
                        id: strategy?.id,
                        name: strategy?.name,
                        date_from: formatDate(strategy?.date_from),
                        risk_profile: {
                            id: strategy?.risk_profile?.id,
                            name: strategy?.risk_profile?.name,
                            name_en: strategy?.risk_profile?.name_en,
                            level: strategy?.risk_profile?.level,
                        }
                    };
                });

                const contractInfo = {
                    uuid: item.uuid,
                    contract_type: {
                        id: item.contract_type?.id,
                        name: item.contract_type?.name,
                        name_en: item.contract_type?.name_en,
                    },
                    contract_closure_date: formatDate(item.contract_closure_date),
                    questionnaire_date: formatDate(item.questionnaire_date),
                };

                return {
                    full_name,
                    client_name,
                    intermediaryInfo,
                    bankAccountInfo,
                    clientInfo,
                    strategiesInfo,
                    contractInfo,
                };
            });
        },
        getTotalCash: (state) => state.totalCash,
        getTotalAssets: (state) => state.totalAssets,

    },

    actions: {
        setClient(clientData: Client) {
            this.client = clientData;
        },

        async fetchClientList(
            page: number,
            size: number,
            status?: string,
            search?: string,
            risk_profile_id?: number,
        ) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                // Define the request parameters
                const method = 'GET';
                const url = '/v1/flip/clients/list';
                const data = {
                    page,
                    size,
                    status,
                    search,
                    risk_profile_id,
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.clientList = response;

                return response;
            } catch (error) {
                throw error;
            }
        },


        async fetchClient(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/clients/get/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.client = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchClientBankAccounts(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/clients/client_bank_accounts/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.bankAccounts = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchClientSecurities(uuid: string, strategy_id?: number, loading?: boolean) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                let url = `/v1/flip/clients/securities/list/${uuid}`;

                // Check if strategy_id is defined, if so, append it to the URL
                if (strategy_id !== undefined) {
                    url += `?strategy_id=${encodeURIComponent(strategy_id.toString())}`;
                }

                const contentType = 'application/x-www-form-urlencoded';

                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType,
                });

                this.securities = response;

                let total = 0;
                // const lastThreeItems = response.content.slice(-3);
                for (const item of response.content) {
                    total += item.sum_value_eur || 0;
                }
                this.totalAssets = total;

                return response;
            } catch (error) {
                throw error;
            }
        },


        async fetchCashAccounts(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/clients/cash_accounts/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.cashAccounts = response;
                let total = 0;
                // const lastThreeItems = response.content.slice(-3);
                for (const item of response.content) {
                    total += item.value_eur || 0;
                }
                // console.log("Response", response);
                this.totalCash = total;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchClientPerformance(uuid: string, date_from: string, strategy_id?: number, loading?: boolean) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = `/v1/flip/clients/performance/${uuid}`;
                const data = {
                    strategy_id:   strategy_id || 0,
                    date_from: date_from,
                    size: 5000,
                    page: 0,
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.performance = response;
                // console.log("Performance", response.content);
                this.performanceData = response.content.map((item: any) => item.value);
                this.performanceLabels = response.content.map((item: any) => item.closure_date);

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchClientDocuments(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/documents/client_files/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.documents = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchClientMessages(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/messages/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.messages = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchClientTransactions(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/transactions/list/${uuid}?size=10000000`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.transactions = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchClientTrades(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/trades/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.trades = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchClientContracts(uuid: string, loading?: boolean) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = `/v1/flip/contracts/get/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.contracts = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchFile(file_id: any, uuid: any) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/documents/file/${file_id}`;
                const data = {
                    client_uuid: uuid,
                };

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data
                    // contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.file = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },
        async fetchMessageFile(uuid: any, file_id: any) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/messages/file/?client_uuid=${uuid}&file_id=${file_id}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url
                    // contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.messageFile = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async birthNumberCheck(
            birthnumber: string,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/clients/birthnumber_partner_check',
                        data: {
                            birthnumber: birthnumber,
                        },
                    });
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                return error
                // return Promise.resolve(error, showToast('This is a success message', 'error', 'Chyba pri vytvarani agenta', 3000));
            }
        },
        async createClient(
            external_id: number,
            academical_title_before?: string,
            name: string,
            surname: string,
            academical_title_after?: string,
            email: string,
            phone: string,
            city: string,
            street: string,
            zip: string,
            country_id: number,
            city_correspondence: string,
            street_correspondence: string,
            zip_correspondence: string,
            country_id_correspondence: string,
            tax_residence_id: number,
            nationality: string,
            birthnumber: string,
            birthdate: string,
            iban: string,
            bank_account_currency_id: number,
            bank_account_owner_name: string,
            idcard_type_id: number,
            idcard_no: string,
            idcard_validity: string,
            risk_profile_id: number,
            intermediary_person_uuid?: string,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/clients/create',
                        data: {
                            external_id: external_id,
                            academical_title_before: academical_title_before,
                            name: name,
                            surname: surname,
                            academical_title_after: academical_title_after,
                            email: email,
                            phone: phone,
                            city: city,
                            street: street,
                            zip: zip,
                            country_id: country_id,
                            city_correspondence: city_correspondence,
                            street_correspondence: street_correspondence,
                            zip_correspondence: zip_correspondence,
                            country_id_correspondence: country_id_correspondence,
                            tax_residence_id: tax_residence_id,
                            nationality: nationality,
                            birthnumber: birthnumber,
                            birthdate: birthdate,
                            iban: iban,
                            bank_account_currency_id: bank_account_currency_id,
                            bank_account_owner_name: bank_account_owner_name,
                            idcard_type_id: idcard_type_id,
                            idcard_no: idcard_no,
                            idcard_validity: idcard_validity,
                            risk_profile_id: risk_profile_id,
                            intermediary_person_uuid: intermediary_person_uuid,
                        },
                        showToast: true,
                    });

                // Make the API request
                router.push({name: 'clients'});
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },

        async createLeadContract(
            ws_client_uuid: any,
            investment_strategies: any,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }
                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/contracts/create_lead_contracts',
                        data: {
                            ws_client_uuid: ws_client_uuid,
                            investment_strategies: investment_strategies,
                        },
                    });
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },

        async createClientContract(
            ws_client_uuid: any,
            investment_strategies: any,
            files: any,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                // Parse the investment_strategies string into a JSON object
                const parsedInvestmentStrategies = JSON.parse(investment_strategies);

                let filesArray = []

                // formData.append('files', files);
                files.forEach((file, index) => {
                    filesArray.push(file);
                });

                console.log("Files array", filesArray)
                const formData = new FormData();
                formData.append('client_uuid', ws_client_uuid);
                formData.append('investment_strategies', JSON.stringify(parsedInvestmentStrategies));
                // formData.append('files', filesArray);

                files.forEach((file, index) => {
                    console.log(`File_${index}`, file)
                    formData.append('files', file);
                });

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/contracts/create_client_contracts',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                    },
                });
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },


        async addStrategyToClientContract(
            client_uuid: any,
            contract_type_id: any,
            investment_strategy_id: any,
            files: any,
            method: string,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const formData = new FormData();
                formData.append('client_uuid', client_uuid);
                formData.append('contract_type_id', contract_type_id);
                formData.append('investment_strategy_id', investment_strategy_id);
                // formData.append('files', filesArray);

                files.forEach((file, index) => {
                    console.log(`File_${index}`, file)
                    formData.append('files', file);
                });

                formData.append('method', method);
                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/contracts/add_client_contract_strategy',
                        data: formData,
                        showToast: true,
                    });
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },

        async addStrategyToLeadContract(
            client_uuid: any,
            contract_type_id: any,
            investment_strategy_id: any,
            loading?: boolean,
        ) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }
                const response: {} =
                    await apiStore.makeApiRequest({
                        method: 'POST',
                        url: '/v1/flip/contracts/add_lead_contract_strategy',
                        data: {
                            ws_client_uuid: client_uuid,
                            contract_type_id: contract_type_id,
                            investment_strategy_id: investment_strategy_id,
                        },
                        showToast: true,
                    });
                return response;
            } catch (error) {
                // toast.add({ severity: 'error', summary: 'Chyba', detail: 'Chyba pri vytvarani agenta', life: 3000 });
                throw error;
            }
        },


        async addNewBankAccount(client_uuid: string, account_owner_name: string, iban: string, currency_id: string, file: any) {
            try {
                const apiStore = useApiStore();
                let formData = new FormData();
                formData.append('client_uuid', client_uuid);
                formData.append('account_owner_name', account_owner_name);
                formData.append('iban', iban);
                formData.append('currency_id', currency_id);
                formData.append('file', file);

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/clients/new_client_bank_account',
                    data: formData, // Use formData instead of a plain object
                    showToast: true,
                });

                return response;
            } catch (error) {
                throw error;
            }
        },


        async fetchLeadBankAccounts(uuid: string) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();


                const method = 'GET';
                const url = `/v1/flip/clients/lead_bank_accounts/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.bankAccounts = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchLeadContracts(uuid: string, loading?: boolean) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = `/v1/flip/lead_contracts/get/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    contentType: 'form-data',
                });

                // Store the list of clients in the state
                this.contracts = response;

                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async sendPaymentDetailsEmail(variable_symbol: string, specific_symbol: string, iban: string, bic: string, client_uuid: string, lang: string, loading?: boolean) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }
                const response = await apiStore.makeApiRequest({
                    method: 'GET',
                    url: '/v1/flip/clients/send_client_deposit_mail',
                    data: {
                        variable_symbol: variable_symbol,
                        specific_symbol: specific_symbol,
                        iban: iban,
                        bic: bic,
                        client_uuid: client_uuid,
                        lang: lang
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                return response;
            } catch (error) {
                throw error;
            }
        },

        async leadUploadFile(ws_client_uuid, file_type_id, file, loading = false) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const formData = new FormData();
                formData.append('ws_client_uuid', ws_client_uuid);
                formData.append('file_type_id', file_type_id);
                formData.append('file', file);

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/clients/lead_upload_file',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    showToast: true,
                });

                return response;
            } catch (error) {
                toast.add({
                    severity: 'error',
                    summary: 'Chyba',
                    detail: 'Chyba pri uploade',
                    life: 3000,
                });
                throw error;
            }
        },

        async clientUploadFile(ws_client_uuid, file_type_id, file, loading = false) {
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const formData = new FormData();
                formData.append('client_uuid', ws_client_uuid);
                formData.append('file_type_id', file_type_id);
                formData.append('file', file);

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/flip/clients/client_upload_file',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    showToast: true,
                });

                return response;
            } catch (error) {
                toast.add({
                    severity: 'error',
                    summary: 'Chyba',
                    detail: 'Chyba pri uploade',
                    life: 3000,
                });
                throw error;
            }
        },

        async fetchClientStrategies(uuid: string, loading?: boolean) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = `/v1/flip/clients/strategies/list/${uuid}`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                });


                // Store the list of clients in the state
                this.clientStrategies = response;
                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async fetchClientRibbonData(uuid: string, strategy_id: any, loading?: boolean) {
            // eslint-disable-next-line no-useless-catch
            try {
                const apiStore = useApiStore();

                if (loading === false) {
                    apiStore.loading = loading;
                }

                const method = 'GET';
                const url = `/v1/flip/clients/client_ribbon_data`;

                // Make the API request
                const response = await apiStore.makeApiRequest({
                    method,
                    url,
                    data: {
                        client_uuid: uuid,
                        strategy_id: strategy_id,
                    }
                });


                // Store the list of clients in the state
                this.clientRibbonData = response;
                return response;
            } catch (error) {
                // Handle error as needed
                throw error;
            }
        },

        async generateStatement(client_uuid: string, strategy_id: number, dateFrom: string, dateTo: string, lang: string, format: string) {
            try {
                const apiUrl = `${import.meta.env.VITE_API_LINK}/v1/flip/clients/generate_statement`;
                const filename = 'FLIP_strategy_report' + format;
                const data = {
                    client_uuid: client_uuid,
                    strategy_id: strategy_id,
                    date_from: dateFrom,
                    date_to: dateTo,
                    lang: lang,
                };

                // Use the downloadFile action from useApiStore with the fixed URL
                await useApiStore().downloadFile({
                    url: apiUrl,
                    filename: filename,
                    requireAuth: true,
                    contentType: 'application/octet-stream',
                    data: data,
                });
            } catch (error) {
                // Handle any errors that occur during the request
                console.error('Error while generating the report:', error);
            }
        },
    }
});
