<template>
  <div>
    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Osobné údaje</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="title" class="input-label">Titul</label>
          <CInputText id="title" v-model="client.academic_title_before" aria-describedby="title-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="name" class="input-label">Meno</label>
          <CInputText id="name" v-model="client.name" aria-describedby="name-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="surname" class="input-label">Priezvisko</label>
          <CInputText id="surname" v-model="client.surname" aria-describedby="surname-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="birthname" class="input-label">Rodné priezvisko</label>
          <CInputText id="birthname" v-model="client.surname" aria-describedby="birthname-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
      </div>
      <div class="root-ctab-panel-items space-x-5 mt-3">
        <div class="flex flex-column gap-2 column-item">
          <label for="birthnumber" class="input-label">Rodné číslo</label>
          <CInputText id="birthnumber" v-model="client.birthnumber" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="birthday" class="input-label">Dátum narodenia</label>
          <Calendar id="birthday" v-model="client.birthday" aria-describedby="username-help" disabled
                    :pt="{root: {class:['background-color-input']}}" show-icon/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="id_number" class="input-label">Číslo OP</label>
          <CInputText id="id_number" v-model="client.idcard_no" aria-describedby="username-help" disabled
          />
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="id_validity" class="input-label">Platnosť OP</label>
          <Calendar id="id_validity" v-model="client.idcard_validity" aria-describedby="username-help" disabled
                    :pt="{root: {class:['background-color-input']}}" show-icon/>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Adresa</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="street" class="input-label">Ulica a číslo</label>
          <CInputText id="street" v-model="client.address.street" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="city" class="input-label">Mesto</label>
          <CInputText id="city" v-model="client.address.city" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="zip" class="input-label">PSČ</label>
          <CInputText id="zip" v-model="client.address.zip" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="country" class="input-label">Krajina</label>
          <CInputText id="country" v-model="client.address.country.name" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
      <h1 class="root-ctab-panel-h1">Doplňujúce informácie</h1>
      <hr>
      <div class="root-ctab-panel-items space-x-5 mt-7">
        <div class="flex flex-column gap-2 column-item">
          <label for="state" class="input-label">Štátna príslušnosť</label>
          <CInputText id="state" v-model="client.nationality" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="phone" class="input-label">Tel. číslo</label>
          <CInputText id="phone" v-model="client.contact.phone" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
        <div class="flex flex-column gap-2 column-item">
          <label for="email" class="input-label">E-mail</label>
          <CInputText id="email" v-model="client.contact.email" aria-describedby="username-help" disabled
                      :pt="{root: {class:['background-color-input']}}"/>
        </div>
      </div>
    </div>

    <div class="p-7 bg-white rounded-lg shadow-md m-3">
      <div class="flex flex-row justify-between align-items-center bank-custom-row items-center">
        <h1 class="root-ctab-panel-h1">Bankové účty</h1>
        <CButton type="button" class="black-button" @click="addBankAccountVisible = true">
          Pridať bankový účet
        </CButton>
      </div>
      <hr>
      <div class="root-ctab-panel-items root-ctab-panel-items-desktop table-row-desktop">
        <Table
            :columns="bankAccounts"
            :data="clientBankAccounts.content"
            class="w-full mt-4"
        />
      </div>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTransactions($event)">
            <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                           v-for="(item, index) in transactionsListPaginator" :key="index"
            >
              <template #header>
                <div class="customer-header-row">
                  <!--                    {{ item[0] }}-->
                  <span>Názov účtu</span>
                  <p>{{ item?.title }}</p>

                </div>
                <i :id="'pi-plus-bank-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>IBAN</h3>
                  <p>{{ item?.iban }}</p>
                </div>
              </div>
              <div class="content-row flex">
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Mena</h3>
                  <p>{{ item?.currency?.name }}</p>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator v-model:first="valueInPaginatorTransactions" :rows="10"
                   :totalRecords="clientBankAccounts?.content?.length"
                   template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
      </div>
    </div>
  </div>

  <CSidebar v-model:visible="addBankAccountVisible"
            position="right"
            header="Transakcia"
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
  >
    <template #header>
      <h1 style="font-size: 1.5rem;">Pridanie bankového účtu</h1>
    </template>

    <CInputText class="w-full mb-4" placeholder="Majiteľ účtu" v-model="newBankAccount.owner"
                :pt="{root: {class:['background-color-input']}}"/>

    <CInputText class="w-full mb-4" placeholder="IBAN" v-model="newBankAccount.iban"
                :pt="{root: {class:['background-color-input']}}"/>

    <CDropdown show-clear v-model="newBankAccount.currency" :options="currencyList" optionLabel="name"
               placeholder="Vyberte menu" class="w-full mb-4"/>

    <div class="file-input-container" v-if="!newBankAccount.file">
      <input type="file" @change="handleFileChange"/>
      <div class="file-input-label">Presuňte alebo vyberte dokument</div>
    </div>

    <div class="file-input-container" v-if="newBankAccount.file">
      <p>{{ newBankAccount.file.name }}</p>
    </div>

    <!-- Text pod sekciou pre nahrávanie súboru -->
    <div style="margin-top: 0.5rem; color: #6b7280; font-size: 0.875rem;">
      Nahrávať môžete sken bankového účtu alebo fotku z aplikácie, kde je viditeľné číslo účtu spolu s jeho názvom, resp. menom majiteľa.
    </div>

    <CButton type="button" class="mt-4 black-button" @click="addBankAccount" :disabled="!isFormComplete">
      Pridať bankový účet
    </CButton>
  </CSidebar>

</template>
<script>
import {useClientsStore} from "@/stores/clientsStore";
import {useEnumsStore} from "@/stores/enumsStore";
import {computed, getCurrentInstance, onBeforeUnmount, ref, watch} from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import Calendar from "@/components/CustomUIComponents/Calendar/Calendar.vue";

export default {
  components: {Table, Calendar},
  setup() {
    const clientStore = useClientsStore();
    const enumsStore = useEnumsStore();
    const client = computed(() => clientStore.client);
    const clientBankAccounts = computed(() => clientStore.bankAccounts);
    const fullName = computed(() => client.value.name + ' ' + client.value.surname);
    const currencyList = computed(() => enumsStore.getCurrencies);
    const currentInstance = getCurrentInstance();
    const valueInPaginatorTransactions = ref(0);
    const previousIndexTransactions = ref(null);
    const transactionsListPaginator = computed(() => {
      if (clientBankAccounts?.value.content?.length > 10) {
        return clientBankAccounts?.value.content?.slice(valueInPaginatorTransactions.value, valueInPaginatorTransactions.value + 10);
      } else {
        return clientBankAccounts?.value.content
      }
    });

    const addBankAccountVisible = ref(false);
    const newBankAccount = ref({
      owner: '',
      iban: '',
      currency: '',
      file: null, // Store selected file here
    });

    const isFormComplete = computed(() => {
      return newBankAccount.value.owner && newBankAccount.value.iban && newBankAccount.value.currency && newBankAccount.value.file;
    });

    const handleFileChange = (event) => {
      newBankAccount.value.file = event.target.files[0];
    };

    function updateIndexTransactions(event) {
      const element = document.getElementById("pi-plus-bank-" + event);
      if (element === null) {
        previousIndexTransactions.value.classList.add("pi-plus");
        previousIndexTransactions.value.classList.remove("pi-minus");
      } else if (previousIndexTransactions.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTransactions.value) {
        previousIndexTransactions.value.classList.add("pi-plus");
        previousIndexTransactions.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTransactions.value = element;
    }

    const addBankAccount = () => {
      const routeUuid = computed(() => {
        const route = currentInstance.appContext.config.globalProperties.$route;
        return route.params.uuid;
      });

      console.log("New bank account:", newBankAccount.value);
      clientStore.addNewBankAccount(routeUuid.value, newBankAccount.value.owner, newBankAccount.value.iban, newBankAccount.value.currency.id, newBankAccount.value.file);
      addBankAccountVisible.value = false;
      newBankAccount.value = {
        owner: '',
        iban: '',
        currency: '',
        file: null,
      };
      setTimeout(() => {
        clientStore.fetchClientBankAccounts(routeUuid.value);
      }, 300);
    }

    onBeforeUnmount(() => {
      clientStore.client = {};
      clientStore.bankAccounts = {};
      clientStore.transactions = {};
      clientStore.trades = {};
      clientStore.documents = {};
      clientStore.contracts = {};
      clientStore.file = {};
      clientStore.messageFile = {};
      clientStore.birthNumberStatus = "";
      clientStore.clientStrategies = {};
      clientStore.totalCash = 0;
      clientStore.totalAssets = 0;
      clientStore.performanceData = [];
      clientStore.performanceLabels = [];
    });

    return {
      client,
      fullName,
      clientBankAccounts,
      addBankAccountVisible,
      newBankAccount,
      currencyList,
      updateIndexTransactions,
      transactionsListPaginator,
      previousIndexTransactions,
      valueInPaginatorTransactions,
      addBankAccount,
      isFormComplete,
      handleFileChange,
      bankAccounts: [
        {field: "title", header: "Názov účtu"},
        {field: "iban", header: "IBAN"},
        {field: "currency.name", header: "Mena"}
      ],
    };
  },
}
</script>

<style lang="scss" scoped>
.root-ctab-panel-items-mobile {
  display: none;
}

.file-input-container {
  @apply relative w-full mt-1 h-20 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer overflow-hidden;

  &:hover {
    @apply border-[#FF5C33];
  }
}

input[type="file"] {
  @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
}

.file-input-label {
  @apply text-center text-gray-500;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media only screen and (max-width: 1372px) {
  .root-ctab-panel-items {
    display: block;

    .column-item {
      margin-left: 0;
    }
  }
  .root-ctab-panel-items-desktop {
    display: none;
  }
  .root-ctab-panel-items-mobile {
    display: block;

    h3 {
      background-color: #F2F1F1;
      color: #949494;
    }

  }
  .bank-custom-row {
    flex-direction: column;
  }
  .black-button {
    width: 250px;
  }
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
</style>
