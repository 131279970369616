<template>
  <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8">
    <div class="header-row">
      <h1 class="root-ctab-panel-h1">Obchody klienta</h1>
      <div class="filters flex pb-2">
        <CDropdown show-clear v-model="selectedStrategy" :options="agentStrategy" optionLabel="name"
                   placeholder="Vyberte stratégiu" class="md:w-14rem custom-item-mobile custom-item-mobile-custom"/>
        <div class="calendar-filters flex">
          <Calendar placeholder="Dátum od" v-model="dateFrom" dateFormat="dd.mm.yy"
                    class="mr-2 ml-2 no-margin width-mobile-inputs" ref="calendarFromRef" showIcon/>
          <Calendar placeholder="Dátum do" v-model="dateTo" dateFormat="dd.mm.yy" class="width-mobile-inputs"
                    ref="calendarToRef" showIcon/>
        </div>
      </div>
    </div>
    <hr>
    <div class="root-ctab-panel-items space-x-5 mt-7 table-row-desktop">
      <Table
          :columns="tradesColumn"
          :data="paginatedTrades"
          :pagination="true"
      >
      </Table>
    </div>
    <div class="table-row-tab-mobile">
      <div class="custom-accordion">
        <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
          <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                         v-for="(item, index) in paginatedTrades" :key="index">
            <template #header>
              <div class="customer-header-row">
                <span>Cenný papier</span>
                <p>{{ item?.cennyPapier }}</p>
              </div>
              <i :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
            </template>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Smer</h3>
                <p>{{ item?.smer }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Dátum</h3>
                <p>{{ item?.datum }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Objem</h3>
                <p>{{ item?.objem }}</p>
              </div>
            </div>
            <div class="content-row flex">
              <div class="content-row-item">
                <h3>Mena</h3>
                <p>{{ item?.mena }}</p>
              </div>
            </div>
          </CAccordionTab>
        </CAccordion>
      </div>
      <Paginator v-model:first="valueInPaginatorTrades" :rows="10" :totalRecords="filteredTrades.length"
                 template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
    </div>
  </div>
</template>

<script>
import { useClientsStore } from "@/stores/clientsStore";
import { computed, ref } from "vue";
import Table from "@/components/CustomUIComponents/Table.vue";
import Paginator from 'primevue/paginator';
import Calendar from "@/components/CustomUIComponents/Calendar/Calendar.vue";

export default {
  name: 'ClientTrades',
  components: { Table, Paginator, Calendar },
  setup() {
    const clientStore = useClientsStore();
    const trades = computed(() => clientStore.getFormattedTrades);
    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);

    const selectedStrategy = ref(null);
    const dateFrom = ref(null);
    const dateTo = ref(null);

    const agentStrategy = computed(() => {
      const strategies = new Set();
      (trades.value || []).forEach(item => strategies.add(item.strategia));
      return Array.from(strategies).map(strategy => ({ name: strategy }));
    });

    const filteredTrades = computed(() => {
      let filtered = trades.value || [];

      // Filtrovanie podľa stratégie, ak je nastavená
      if (selectedStrategy.value) {
        filtered = filtered.filter(trade => trade.strategia === selectedStrategy.value.name);
      }

      // Filtrovanie od dátumu "Od," ak je nastavený
      if (dateFrom.value) {
        const fromDate = new Date(dateFrom.value);
        filtered = filtered.filter(trade => {
          const tradeDate = new Date(trade.datum.split('.').reverse().join('-').trim());
          return tradeDate >= fromDate;
        });
      }

      // Filtrovanie do dátumu "Do," ak je nastavený
      if (dateTo.value) {
        const toDate = new Date(dateTo.value);
        filtered = filtered.filter(trade => {
          const tradeDate = new Date(trade.datum.split('.').reverse().join('-').trim());
          return tradeDate <= toDate;
        });
      }

      return filtered;
    });



    const paginatedTrades = computed(() => {
      const start = valueInPaginatorTrades.value;
      const end = start + 10;
      return filteredTrades.value.slice(start, end);
    });

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    return {
      tradesColumn: [
        { field: "datum", header: "Dátum"},
        { field: "smer", header: "Smer", sortable: true },
        { field: "strategia", header: "Stratégia", sortable: true },
        { field: "cennyPapier", header: "Cenný papier", sortable: true },
        { field: "isin", header: "ISIN", sortable: true },
        { field: "pocet", header: "Množstvo", sortable: true, class: "!text-right !justify-end" },
        { field: "cena", header: "Cena", sortable: true, class: "!text-right !justify-end" },
        { field: "objem", header: "Objem", sortable: true, class: "!text-right !justify-end font-bold" },
        { field: "mena", header: "Mena", sortable: true },
      ],
      trades,
      selectedStrategy,
      dateFrom,
      dateTo,
      agentStrategy,
      valueInPaginatorTrades,
      updateIndexTrades,
      paginatedTrades,
      filteredTrades,
    };
  },
};
</script>


<style lang="scss" scoped>
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filters {
  display: flex;
  gap: 10px;
}

.calendar-filters {
  display: flex;
  gap: 10px;
}

hr {
  margin: 10px 0;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

</style>
