<template>
  <apexchart
      type="line"
      v-if="props.data.length > 0"
      :options="chartOptions"
      :series="chartData"
      ref="apexChart"
      height="350"
  />
  <h3 v-else>Žiadne dáta k dispozícii</h3>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, toRef, defineProps } from "vue";

const props = defineProps<{
  data: number[];
  options: string[];
  type: any;
}>();

const chartData = ref([]);
const chartOptions = ref({});
const chartDataRef = toRef(props, 'data');

onMounted(() => {
  getChartOptions();
  getChartSeries();
});

watch(chartDataRef, () => {
  getChartOptions();
  getChartSeries();
});

const getChartOptions = () => {
  chartOptions.value = {
    chart: {
      id: "vuechart-example",
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "solid",
      opacity: [1, 1],
    },
    xaxis: {
      categories: props.options,
      type: 'datetime',
    },
    yaxis: {
      tickAmount: 6,
      labels: {
        formatter: function (value) {
          return Math.floor(value); // Round down to the nearest integer
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#F37021",
        opacity: 1
      },
    },
  };
};

const getChartSeries = () => {
  chartData.value = [
    {
      name: "Počet klientov",
      data: props.data,
    },
  ];
};

</script>
