import {defineStore} from 'pinia';
import {useApiStore} from './apiStore';
import router from "@/router";
import {useRoute} from "vue-router";

interface AuthState {
    authToken: string;
    authTokenExpiresAt: number;
    userName: string;
    isAdmin: boolean;
}

export const useAuthStore = defineStore({
    id: 'auth',

    state: (): AuthState => ({
        authToken: '',
        authTokenExpiresAt: 0,
        userName: '',
        isAdmin: false,
        multiFactorToken: ''
    }),

    getters: {
        isAuthenticated(state: AuthState): boolean {
            const currentTimestamp = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds
            return !!state.authToken && state.authTokenExpiresAt >= currentTimestamp;
        },
        getAuthToken(state: AuthState): string {
            return state.authToken;
        }
    },

    actions: {
        setAuthToken(this: any, tokenInfo: { token: string; expires_at: string, is_admin: boolean }): void {
            this.authToken = tokenInfo.token;

            // Parse the expires_at string to a JavaScript Date object
            const expiresAtDate = new Date(tokenInfo.expires_at);

            // Convert the Date object to a UNIX timestamp in seconds
            const expiresAtTimestamp = Math.floor(expiresAtDate.getTime() / 1000);

            this.authTokenExpiresAt = expiresAtTimestamp;

            // Store the token and its expiration date in local storage
            localStorage.setItem('authToken', tokenInfo.token);
            localStorage.setItem('authTokenExpiresAt', expiresAtTimestamp.toString());
            localStorage.setItem('isAdmin', tokenInfo.is_admin.toString());
        },

        async verifyAuthCode(this: any, code: string): Promise<any> {
            try {
                const apiStore = useApiStore();
                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/verify_2fa_code',
                    data: {
                        code: code,
                    },
                    requireAuth: true,
                    // showToast: true,
                    useCustomAuth: true,
                    customToken: this.multiFactorToken
                });


                // if (response.status === 200) {
                //     console.log('2FA response:', response);
                //     router.push('/');
                // }

                this.setAuthToken({
                    token: response.authorization_token,
                    expires_at: response.expires_at,
                    is_admin: response.admin
                });
                this.isAdmin = response.admin;
                router.push('/');

                return response;
            } catch (error) {
                console.error('2FA error:', error);
                throw error;
            }
        },

        async login(this: any, {username, password}: { username: string; password: string }): Promise<any> {
            try {
                const apiStore = useApiStore(); // Access the API store

                const response: {
                    authorization_token: string;
                    expires_at: string;
                    admin: any
                } = await apiStore.makeApiRequest({
                    method: 'POST', // Adjust the HTTP method as needed
                    url: '/v1/authentication', // Adjust the API endpoint URL
                    data: {
                        scope: 'web', // Add scope parameter
                        user: username, // Add user parameter
                        pass: password, // Add pass parameter
                    },
                    requireAuth: false,
                });
                if (response.multifactor_token) {
                    this.multiFactorToken = response.multifactor_token;
                    router.push('/fa');
                }

                if (response.authorization_token) {
                    this.setAuthToken({
                        token: response.authorization_token,
                        expires_at: response.expires_at,
                        is_admin: response.admin
                    });
                    this.isAdmin = response.admin;
                    router.push('/');
                }




                // this.isAdmin = response.admin;

                // await this.check2FA(username, response.authorization_token);
                return response;
            } catch (error) {
                // Handle login error (e.g., throw an error)
                console.error('Login error:', error);
                throw error;
            }
        },


        checkTokenExpiration(this: any): void {
            const route = useRoute(); // Get the current route
            const currentTimestamp = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds
            if (localStorage.getItem('authTokenExpiresAt')) {


                const getFromLocalStorage = localStorage.getItem('authTokenExpiresAt');
                if (getFromLocalStorage < currentTimestamp) {
                    // Check if the current route is 'login', 'forgot-password', or 'reset-password'
                    if (route.name !== 'login' && route.name !== 'forgot-password' && route.name !== 'reset-password') {
                        // Token has expired, clear token and redirect to login page
                        this.authToken = '';
                        this.authTokenExpiresAt = 0;
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('authTokenExpiresAt');
                        localStorage.removeItem('isAdmin');
                        router.push('/login');
                    }
                }
            }
        },

        async forgotPassword(this: any, {lang, username}: { lang: string; username: string }): Promise<any> {
            try {
                const apiStore = useApiStore(); // Access the API store

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: `/v1/forgot_password?lang=${lang}`,
                    data: {
                        username,
                    },
                    requireAuth: false,
                    showToast: true
                });

                return response;
            } catch (error) {
                console.error('Forgot password error:', error);
                throw error;
            }
        },

        async changePassword(this: any, {token, new_password}: { token: string; new_password: string }): Promise<any> {
            try {
                const apiStore = useApiStore(); // Access the API store

                const response = await apiStore.makeApiRequest({
                    method: 'POST',
                    url: '/v1/change_password', // Adjust the API endpoint URL
                    data: {
                        token,
                        new_password,
                    },
                    requireAuth: false,
                    showToast: true
                });

                return response;
            } catch (error) {
                // Handle change password error (e.g., throw an error)
                console.error('Change password error:', error);
                throw error;
            }
        },
    },
    persist: true,
});
