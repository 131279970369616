<template>
  <div class="p-7 bg-white rounded-lg shadow-md m-3">
    <div class="flex justify-between items-center mb-4">
      <h1 class="root-ctab-panel-h1">Vývoj majetku klienta</h1>
      <CButton label="Filter" icon="pi pi-filter" @click="toggleOverlayPanel" class="custom-button"/>
    </div>

    <COverlayPanel ref="filterPanel" dismissable>
      <h4>Stratégia</h4>
      <CDropdown
          v-model="selectedStrategy"
          :options="strategies"
          optionLabel="name"
          optionValue="id"
          show-clear
          placeholder="Vyberte stratégiu"
          class="md:w-14rem custom-item-mobile custom-item-mobile-custom"
      />


      <!-- Checkboxy -->
      <div class="mt-4">
        <div class="flex items-center mb-2">
          <CCheckbox v-model="showCashFlow" :binary="true"/>
          <label for="showCashFlow" class="ml-2">Zobraziť vklady/výbery</label>
        </div>
        <div class="flex items-center mb-2">
          <CCheckbox v-model="showAsset" :binary="true"/>
          <label for="showPerformance" class="ml-2">Majetok</label>
        </div>
        <div class="flex items-center">
          <CCheckbox v-model="showPerformanceWithoutFee" :binary="true"/>
          <label for="showPerformanceWithoutFee" class="ml-2">Bez vplyvu vstupných poplatkov</label>
        </div>
      </div>
    </COverlayPanel>

    <!-- Chart Ranges and other components remain the same -->
    <hr>
    <div class="flex my-6 time-range-holder">
      <CButton
          v-for="range in chartRanges"
          :key="range.value"
          :class="{ 'time-range-button-selected': chartRange === range.value, 'time-range-button': chartRange !== range.value }"
          @click="changeChartRange(range.value)"
      >
        {{ range.label }}
      </CButton>
    </div>

    <!-- Chart -->
    <CSkeleton width="100%" height="250px" v-if="!clientStore.getPerformanceData"/>
    <ClientPerformanceChart
        v-if="filteredPerformanceData.labels.length > 0"
        :data="filteredPerformanceData.datasets"
        :options="filteredPerformanceData.labels"
        :annotations="cashFlowAnnotations"
        :showAsset="showAsset"
        type="line"
        height="800"
    />

    <p v-else>No performance data available.</p>
  </div>
</template>


<script setup>
import {ref, computed, onMounted, onBeforeUnmount, watch} from 'vue';
import {useClientsStore} from '@/stores/clientsStore';
import ClientPerformanceChart from '@/components/Charts/ClientPerformanceChart.vue';

// Variables and computed properties
const clientStore = useClientsStore();
const performance = computed(() => clientStore.performance);
const performanceData = computed(() => clientStore.getPerformanceData);
const performanceLabels = computed(() => clientStore.getPerformanceLabels);
const chartData = ref();
const chartOptions = ref();
const chartRange = ref('1Y');
const selectedStrategy = ref(null);
const showCashFlow = ref(false);
const showPerformanceWithoutFee = ref(false);
const showPerformance = ref(true);
const showAsset = ref(false);
const strategies = ref([]);

// Dropdown options for chart ranges
const chartRanges = [
  {label: '1M', value: '1M'},
  {label: '3M', value: '3M'},
  {label: '6M', value: '6M'},
  {label: '1Y', value: '1Y'},
  {label: '3Y', value: '3Y'},
  {label: 'YTD', value: 'YTD'},
  {label: 'MAX', value: 'MAX'},
];

// Initialize strategies
setTimeout(() => {
  strategies.value = clientStore.getClientStrategies;
}, 500);

/*
  Function: formatCurrency
  Description: Format a given value as EUR currency.
*/
const formatCurrency = (value) => {
  return new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(value);
};

/*
  Function: formatPercentage
  Description: Format a given value as %.
*/
const formatPercentage = (value) => {
  return new Intl.NumberFormat('sk-SK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value) + '%';
};

/*
  Function: formatDate
  Description: Format a given date in 'sk-SK' locale.
*/
const formatDate = (date) => {
  const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
  return new Date(date).toLocaleDateString('sk-SK', options);
};

/*
  Function: setChartOptions
  Description: Configure chart options, including y-axes for performance and assets.
*/
const setChartOptions = () => {
  chartOptions.value = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true }
    },
    scales: {
      x: {
        title: { display: true, text: 'Closure Date' },
        ticks: { maxRotation: 90, autoSkip: true, maxTicksLimit: 1 }
      },
      y1: {
        position: 'left',
        title: { display: true, text: 'Výkonnosť (%)' },
        ticks: { callback: (value) => formatPercentage(value) }
      },
      ...(showAsset.value
          ? {
            y: {
              position: 'right',
              title: { display: true, text: 'Majetok (EUR)' },
              ticks: { callback: (value) => formatCurrency(value) },
              grid: { drawOnChartArea: false }
            }
          }
          : {})
    }
  };
};


/*
  Function: setChartData
  Description: Prepare chart datasets, showing performance by default and assets if selected.
*/
const setChartData = () => {
  chartData.value = {
    labels: performance.value.content.map(item => new Date(item.closure_date).toLocaleDateString('sk-SK')),
    datasets: [
      {
        label: 'Kumulatívna výkonnosť (ľavá os)',
        backgroundColor: '#FF5733',
        borderColor: '#FF5733',
        data: performance.value.content.map(item => item[showPerformanceWithoutFee.value ? 'cumulative_performance_without_entry_fee' : 'cumulative_performance']),
        yAxisID: 'y1'
      },
      ...(showAsset.value
          ? [
            {
              label: 'Majetok spolu (pravá os)',
              backgroundColor: '#000',
              borderColor: '#000',
              data: performance.value.content.map(item => item.value),
              yAxisID: 'y'
            }
          ]
          : [])
    ]
  };
};




/*
  Watcher: selectedStrategy
  Description: Update chart data when the selected strategy changes.
*/
watch([showPerformanceWithoutFee, showAsset], () => {
  setChartOptions();
  setChartData();
});

watch(selectedStrategy, async (newStrategy) => {
  await clientStore.fetchClientPerformance(clientStore.client.uuid, chartRange.value, newStrategy);
  setChartData();
}, { deep: true });

/*
  Hook: onMounted
  Description: Initialize the chart data and options when the component is mounted.
*/
onMounted(() => {
  const data = performance.value.content || [];
  if (data.length > 0) {
    chartData.value = setChartData();
    chartOptions.value = setChartOptions(showPerformance.value);
  }
});

/*
  Hook: onBeforeUnmount
  Description: Clean up data before the component is unmounted.
*/
onBeforeUnmount(() => {
  performanceData.value = null;
  performanceLabels.value = null;
});

/*
  Function: toggleOverlayPanel
  Description: Toggles the display of the overlay panel.
*/
const filterPanel = ref(null);
const toggleOverlayPanel = (event) => {
  filterPanel.value.toggle(event);
};

/*
  Function: changeChartRange
  Description: Changes the range of the chart based on selected range.
*/
const changeChartRange = async (range) => {
  const previous_date = new Date();
  switch (range) {
    case '1M':
      previous_date.setMonth(previous_date.getMonth() - 1);
      break;
    case '3M':
      previous_date.setMonth(previous_date.getMonth() - 3);
      break;
    case '6M':
      previous_date.setMonth(previous_date.getMonth() - 6);
      break;
    case '1Y':
      previous_date.setFullYear(previous_date.getFullYear() - 1);
      break;
    case '3Y':
      previous_date.setFullYear(previous_date.getFullYear() - 3);
      break;
    case 'YTD':
      previous_date.setMonth(0);
      break;
    case 'MAX':
      previous_date.setFullYear(2000);
      break;
  }
  chartRange.value = range;
  await clientStore.fetchClientPerformance(clientStore.client.uuid, previous_date.toISOString().split('T')[0], selectedStrategy.value || 0);
  chartData.value = setChartData();
};

/*


  Computed: cashFlowAnnotations
  Description: Generates annotations for the chart based on cash flow data.


*/
const cashFlowAnnotations = computed(() => {
  if (!showCashFlow.value || !performance.value) return [];

  const field = showPerformanceWithoutFee.value ? 'cumulative_performance_without_entry_fee' : 'cumulative_performance';
  return performance.value.content
      .filter(item => item.cashflow !== 0)
      .map(item => ({
        x: new Date(item.closure_date).getTime(),
        y: item[field],
        marker: {
          size: 6,
          fillColor: item.cashflow > 0 ? '#00E396' : '#FF4560',
          strokeColor: '#fff',
          radius: 2
        },
        label: {
          borderColor: item.cashflow > 0 ? '#00E396' : '#FF4560',
          text: `${item.cashflow > 0 ? 'Vklad' : 'Výber'}: ${formatCurrency(Math.abs(item.cashflow))}`
        }
      }));
});

/*
  Computed: filteredPerformanceData
  Description: Filters performance data based on the selected strategy.
*/
const filteredPerformanceData = computed(() => {
  if (!performance.value || !performance.value.content) return { labels: [], datasets: [] };

  const filteredData = performance.value.content;
  const labels = filteredData.map(item => new Date(item.closure_date).toISOString());
  const performanceField = showPerformanceWithoutFee.value ? 'cumulative_performance_without_entry_fee' : 'cumulative_performance';

  const performanceData = filteredData.map(item => item[performanceField]);
  const assetData = filteredData.map(item => item.value);

  const datasets = [
    {
      name: 'Kumulatívna výkonnosť (ľavá os)',
      data: performanceData,
      type: 'line',
      color: '#FF5733'
    }
  ];

  if (showAsset.value) {
    datasets.push({
      name: 'Majetok spolu (pravá os)',
      data: assetData,
      type: 'line',
      color: '#000000'
    });
  }

  return { labels, datasets };
});
</script>


<style lang="scss" scoped>
.p-overlaypanel::before {
  background-color: #fff !important; /* Match the panel's background */
}

.custom-button {
  background-color: #000000 !important; /* Oranžová farba */
  color: white !important; /* Biely text a ikonky */
  border: none !important;
}


.time-range-button {
  background-color: white !important;
  color: gray;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  font-size: 0.9rem;

  &:hover {
    color: gray !important;
  }
}

.time-range-button-selected {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 4px !important;
  font-size: 0.9rem;
}

hr {
  margin: 10px 0;
  color: black !important;
}

.p-overlaypanel::before {
  background-color: white !important; /* Prispôsob šípku farbe pozadia */
  border-color: transparent !important; /* Ak to nie je šípka */
}


.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .time-range-button {
    border: 0 !important;
    padding-left: 5px;
  }
  .time-range-button-selected {
    border: 0 !important;
    padding-left: 5px;
  }
}
</style>
