<template>


  <div class="h-full">
    <div
        class="rounded-lg shadow-md bg-white desktop-table mb-4 flex items-center justify-between custom-header-assets p-4 m-3">
      <div class="flex items-center">
        <div class="flex items-center">
          <CDropdown v-model="selectedClientStrategy" :options="getClientStrategies"
                     optionLabel="name"
                     optionValue="id"
                     show-clear
                     placeholder="Vyberte stratégiu" class="md:w-14rem custom-dropdown ml-4"/>
        </div>
        <div class="flex items-center">
          <CCalendar placeholder="Dátum od" v-model="dateFrom" dateFormat="dd.mm.yy"
                     class="mr-2 ml-2 no-margin width-mobile-inputs" ref="calendarFromRef" showIcon/>
        </div>
        <div class="flex items-center mr-4">
          <CCalendar placeholder="Dátum do" v-model="dateTo" dateFormat="dd.mm.yy" class="width-mobile-inputs"
                     ref="calendarToRef" showIcon/>
        </div>
      </div>
      <div class="flex items-end">
  <CButton @click="generateReport" :disabled="!isFormValid" :class="buttonClass" class="black-button grow !ml-4 mr-3">
    <font-awesome-icon icon="fa-solid fa-file-pdf" class="mr-2"/>
    Generovať výpis
  </CButton>
</div>
    </div>

    <div class="rounded-lg shadow-md bg-white p-4 m-3">
      <div class="flex items-center justify-between py-2">
        <h2 class="ml-4 root-ctab-panel-h1">Dokumenty</h2>
        <CButton @click="toogleSidebar = true" icon="pi pi-plus" class="black-button mr-3"
                 style="background-color:#FF5C33"
                 label="Nahrať dokument"></CButton>
      </div>
      <Table :columns="documentsColumns"
             :data="documents"
             :pagination="true"
             class="m-3 table-row-desktop">
        <CColumn field="icon">
          <template #body="icon">
            <!--            {{icon}}-->
            <font-awesome-icon icon="fa-solid fa-download" size="lg" class="menu-icon"
                               @click="downloadDocument(icon.data.documentId, icon.data.documentName)"/>
          </template>
        </CColumn>
      </Table>
      <div class="table-row-tab-mobile">
        <div class="custom-accordion">
          <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
            <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                           v-for="(item, index) in tradesListPaginator" :key="index"
            >
              <template #header>
                <div class="customer-header-row">
                  <span>Názov</span>
                  <p>{{ item?.documentType }}</p>
                </div>
                <i :id="'pi-plus-documents-' + index" class="pi pi-plus"></i>
              </template>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Typ</h3>
                  <p>{{ item?.name }}</p>
                </div>
              </div>
              <div class="content-row flex">
                <div class="content-row-item">
                  <h3>Dátum</h3>
                  <p>{{ item?.date }}</p>
                </div>
              </div>
              <div class="content-row-buttons">
                <div class="content-row-item">
                  <button @click="downloadDocument(item?.documentId, item?.documentName)">Stiahnuť</button>
                </div>
              </div>
            </CAccordionTab>
          </CAccordion>
        </div>
        <Paginator v-model:first="valueInPaginatorTrades" :rows="10" :totalRecords="documents?.length"
                   template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
      </div>
    </div>
  </div>

  <CSidebar v-model:visible="toogleSidebar"
            position="right"
            header=""
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="close">
    <div class="root-ctab-panel-items mt-7">
      <div class="flex flex-column gap-2 column-item">
        <label for="selectedPaper" class="input-label">Typ dokumentu</label>
        <CDropdown v-model="selectedFileType" :options="fileTypes" filter optionLabel="name"
                   placeholder="Vyberte typ dokumentu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="file-input-container" v-if="!file">
        <input type="file" @change="handleFileChange"/>
        <div class="file-input-label">Presuňte alebo vyberte dokument</div>
      </div>
      <div class="file-input-container" v-if="file">
        <p>Selected file: {{ file?.name }}</p>
      </div>
      <div class="mt-4">
        <CButton @click="uploadDocument" label="Nahrať dokument" class="black-button mt-2"></CButton>
      </div>
    </div>
  </CSidebar>
</template>

<script>
import {useClientsStore} from "@/stores/clientsStore";
import {useEnumsStore} from "@/stores/enumsStore";
import {computed, getCurrentInstance, onBeforeUnmount, onMounted, ref} from "vue";
import {downloadFile, downloadPDF} from "@/helpers";
import Table from "@/components/CustomUIComponents/Table.vue";
import Paginator from 'primevue/paginator';
import {upload} from "@formkit/icons";

export default {
  name: 'ClientContracts',
  components: {Table, Paginator},
  setup() {
    const toogleSidebar = ref(false);
    const selectedFileType = ref(undefined);
    const file = ref(undefined);
    // const selectedClientStrategy = ref(undefined);
    const dateFrom = ref('');
    const dateTo = ref('');
    const selectedClientStrategy = ref(null);
    const uploadedFiles = ref([]);
    const instance = getCurrentInstance();
    const clientStore = useClientsStore();
    const enumsStore = useEnumsStore();
    const documents = computed(() => clientStore.getFormattedDocumentsList);
    const getClientStrategies = computed(() => clientStore.getClientStrategies || []);
    const client = computed(() => clientStore.getClient);
    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const currentInstance = getCurrentInstance();
    const tradesListPaginator = computed(() => {
      if (documents?.value && documents?.value.length > 10) {
        return documents.value.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return documents.value
      }
    });

    const buttonClass = computed(() => {
      return !isFormValid.value ? 'opacity-50' : '';
    });

    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-documents-" + event);
      if (element === null) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if (previousIndexTrades.value === null) {
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if (element !== previousIndexTrades.value) {
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }

    const isFormValid = computed(() => {
      return selectedClientStrategy.value && dateFrom.value && dateTo.value;
    });

    const fileTypes = computed(() => {
      if (enumsStore.getFileTypes.length === 0) {
        enumsStore.fetchFileTypes;
      }

      const allFileTypes = enumsStore.fileTypes;
      return [16, 9, 14, 10, 11, 12].map(id =>
          allFileTypes.find(fileType => fileType.id === id)
      ).filter(Boolean);
    });

    const formatDate = (dateString) => {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };

      // Parse the input string as a Date object
      const date = new Date(dateString);

      // Format the date to Slovak locale
      return date.toLocaleDateString('sk-SK', options);
    };

const generateReport = () => {
  const uuid = instance.proxy.$route.params.uuid;
  const formattedDateFrom = dateFrom.value.toISOString().split('T')[0];
  const formattedDateTo = dateTo.value.toISOString().split('T')[0];
  console.log('Generating report from', formattedDateFrom, 'to', formattedDateTo, 'for strategy', selectedClientStrategy.value);
  clientStore.generateStatement(uuid, selectedClientStrategy.value, formattedDateFrom, formattedDateTo, 'sk', '.pdf');
};


    const downloadDocument = async (documentId, documentName) => {
      const uuid = instance.proxy.$route.params.uuid;
      const response = await clientStore.fetchFile(documentId, uuid);

      downloadFile(documentName, response);
    };

    // const uploadDocument = async (document) => {
    //   await clientStore.leadUploadFile(
    //       response,
    //       document.type,
    //       document.data,
    //   );
    // }

    const handleFileChange = (event) => {
      file.value = event.target.files[0];
    };

    const deleteDocument = (document) => {
      const index = uploadedFiles.value.indexOf(document);
      if (index !== -1) {
        uploadedFiles.value.splice(index, 1);
      }
    };

    const route = currentInstance.appContext.config.globalProperties.$route;
    console.log(route.params.uuid);
    const uploadDocument = () => {
      console.log('uploadDocument', file.value, selectedFileType?.value, uploadedFiles.value);
      if (file.value) {
        uploadedFiles.value.push({
          name: file.value.name,
          fileType: selectedFileType?.value.name,
          size: file.value.size,
          type: selectedFileType?.value.id,
          data: file.value,
        });
        if (client.value.status === 'lead') {
          clientStore.leadUploadFile(
              route.params.uuid,
              selectedFileType?.value.id,
              file.value,
          );
        } else {
          clientStore.clientUploadFile(
              route.params.uuid,
              selectedFileType?.value.id,
              file.value,
          );
        }

        toogleSidebar.value = false;
        selectedFileType.value = undefined;
        file.value = undefined;
      }
    };

    onMounted(() => {
      documents.value = [];
    });

    onBeforeUnmount(() => {
      toogleSidebar.value = false;
      documents.value = [];
      clientStore.documents = [];
    });


    return {
      documentsColumns: [
        {field: "documentType", header: "Názov"},
        {field: "name", header: "Typ"},
        {field: "date", header: "Dátum"},
      ],
      documents,
      formatDate,
      downloadDocument,
      updateIndexTrades,
      tradesListPaginator,
      valueInPaginatorTrades,
      uploadDocument,
      toogleSidebar,
      selectedFileType,
      file,
      uploadedFiles,
      handleFileChange,
      fileTypes,
      deleteDocument,
      getClientStrategies,
      selectedClientStrategy,
      dateFrom,
      dateTo,
      generateReport,
      isFormValid,
      buttonClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.opacity-50 {
  opacity: 0.3 !important;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.file-input-container {
  @apply relative w-full mt-5 h-20 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer overflow-hidden;

  &:hover {
    @apply border-[#FF5C33];
  }
}

input[type="file"] {
  @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
}

.file-input-label {
  @apply text-center text-gray-500;
}

.root-ctab-panel-h1 {
  font-size: 20px;
  color: black;
  padding-bottom: 8px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the gap between cards as needed */
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-actions {
  margin-top: 10px;
}

.card-actions span {
  margin-right: 10px;
}


hr {
  margin: 10px 0;
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.content-row-buttons {
  margin-top: 10px;

  .content-row-item button {
    background-color: #E5E4E3;
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.table-row-tab-mobile {
  display: none;
}

@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobile-custom-view {
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header {
    flex-direction: column;
    gap: 10px;

    span {
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid {
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}

</style>
