<template>
  <div class="table-row">
    <div class="table-row-tab rounded-lg shadow-md bg-white p-4">
      <div class="flex items-center justify-between">
        <h2 class="font-bold pb-2 ml-4 delete-margin-mobile">Posledné transakcie</h2>
        <RouterLink
            to="/transactions"
            class="router-link"
            :class="{'router-link-exact-active': $route.path === '/transactions'}"
        >
          <i class="pi pi-arrow-right pr-4" style="color: black"></i>
        </RouterLink>
      </div>
      <Table :columns="clientColumns" :data="clientList" :pagination="false" class="m-3 table-row-desktop">
      </Table>
    <div class="table-row-tab-mobile">
          <div class="custom-accordion">
            <CAccordion class="accordion-custom" @update:activeIndex="updateIndexTrades($event)">
              <CAccordionTab :pt="{
                        root: {
                          style: {
                            'margin':'0',
                            'padding-bottom': '0'
                          }
                        },
                        headerIcon: {
                          class: [
                            'display-delete'
                          ]
                        },
                        headerAction: {
                          class: [
                            'header-action-custom'
                          ],
                          style: {
                            'margin':'0',
                            'padding-top': '0',
                            'padding-bottom': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        },
                        content: {
                          style: {
                            'padding-top': '0',
                            'border-radius': '0',
                            'border': '0'
                          }
                        }
                      }"
                      v-for="(item, index) in clientList" :key="index"
              >
                <template #header>
                  <div class="customer-header-row">
                    <span>Typ transakcie</span>
                    <p>{{ item?.transaction_type }}</p>
                  </div>
                  <i  :id="'pi-plus-trades-' + index" class="pi pi-plus"></i>
                </template>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Dátum</h3>
                    <p>{{ item?.date }}</p>
                  </div>
                  </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Stratégia</h3>
                    <p>{{ item?.strategy }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Klient</h3>
                    <p>{{ item?.client }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Objem</h3>
                    <p>{{ item?.value }}</p>
                  </div>
                </div>
                <div class="content-row flex">
                  <div class="content-row-item">
                    <h3>Mena</h3>
                    <p>{{ item?.currency }}</p>
                  </div>
                </div>
<!--                <div class="content-row-buttons flex gap-2">-->
<!--                  <div class="content-row-item">-->
<!--                    <button>Zopakovať</button>-->
<!--                  </div>-->
<!--                  <div class="content-row-item">-->
<!--                    <button>Detail</button>-->
<!--                  </div>-->
<!--                </div>-->
              </CAccordionTab>
            </CAccordion>
          </div>
          <Paginator v-model:first="valueInPaginatorTrades" :rows="10" :totalRecords="clientList.length" template="PrevPageLink CurrentPageReport NextPageLink"></Paginator>
        </div>
      </div>
  </div>
</template>
<script>
import Table from "@/components/CustomUIComponents/Table.vue"
import { ref, computed } from 'vue'
import Paginator from "primevue/paginator";

export default {
  name: 'LatestTransactions',
  components: {Table, Paginator},
  props: {
    clientColumns: {},
    clientList: {}
  },
  setup(){
    const valueInPaginatorTrades = ref(0);
    const previousIndexTrades = ref(null);
    const tradesListPaginator = computed(() => {
      if (clientList.length > 10) {
        return clientList.slice(valueInPaginatorTrades.value, valueInPaginatorTrades.value + 10);
      } else {
        return clientList
      }
    });
    function updateIndexTrades(event) {
      const element = document.getElementById("pi-plus-trades-" + event);
      if(element === null){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
      } else if(previousIndexTrades.value === null){
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      } else if(element !== previousIndexTrades.value){
        previousIndexTrades.value.classList.add("pi-plus");
        previousIndexTrades.value.classList.remove("pi-minus");
        element.classList.add("pi-minus");
        element.classList.remove("pi-plus");
      }
      previousIndexTrades.value = element;
    }
    return {
      valueInPaginatorTrades,
      previousIndexTrades,
      tradesListPaginator,
      updateIndexTrades
    }
  }

}
</script>
<style lang="scss" scoped>

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row-buttons {
  justify-content: space-between;
  margin-top: 10px;

  .content-row-item button {
    background-color: black;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
  }

  .content-row-item:first-child button:first-child {
    background-color: #E5E4E3;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
  }
}

.last-button {
  background-color: white;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;

  button {
    background-color: black;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
  }
}

.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}

.table-row {
  display: flex;
  justify-content: center;

  .table-row-tab {
    width: 100%;
    margin: 1% 32px;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
}

.table-row-tab-mobile {
  display: none;
}


li {
  cursor: pointer;
  color: grey;
  padding-right: 10px;
}

li:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1310px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;

    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
}

.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }

  p {
    font-weight: 500;
    color: black;
  }
}

.content-row {
  width: 100%;
  justify-content: space-between;

  .content-row-item {
    min-width: 150px;

    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }

    p {
      font-weight: 500;
      color: black;
    }
  }

}
.customer-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.50);
  }
  p {
    font-weight: 500;
    color: black;
  }
}
.content-row {
  width: 100%;
  justify-content: space-between;
  .content-row-item {
    min-width: 150px;
    h3 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.50);
    }
    p {
      font-weight: 500;
      color: black;
    }
  }
}
.custom-accordion {
  h1 {
    background: white;
    font-weight: 400px;
    font-size: 22px;
    margin-bottom: -2px;
  }
}
.table-row-tab-mobile {
  display: none;
}
@media only screen and (max-width: 1372px) {
  .table-row-tab-mobile {
    display: block;
    width: 100%;
    h2 {
      font-size: 26px;
      font-weight: 400;
    }
  }
  .table-row-desktop, .no-mobile-display {
    display: none;
  }
  .width-mobile{
    flex-direction: column;
  }
  .custom-item-mobile{
    justify-content: space-between;
    margin: 5px;
  }
  .custom-item-mobile input{
    width: 100%;
  }
  .no-margin{
    margin: 0;
  }
}
@media only screen and (max-width: 800px) {
  .mobile-custom-view{
    flex-direction: column;
    gap: 10px;
  }
  .mobile-custom-view-header{
    flex-direction: column;
    gap: 10px;
    span{
      margin-left: 0 !important;
    }
  }
  .mobile-custom-view-grid{
    padding: 0.5rem;
  }
  .content-row .content-row-item {
    min-width: 120px;
  }
}
@media only screen and (max-width: 538px) {
  .content-row {
    flex-direction: column;
  }
  .mobile-view{
    padding: 1rem;
  }
  .delete-margin-mobile{
    margin-left: 0 !important;
  }
}

</style>
