<template>
  <div class="flex h-screen bg-white">
    <!-- Left Side: Login Form -->
    <div class="w-1/2 p-8 relative flex flex-col justify-center items-center custom-css-mobile-width">
      <!-- Logo at the very top -->
      <img src="../assets/Logo_Clear.svg" alt="Logo" class="w-[100px] h-[100px] absolute top-0 left-0 mt-12 ml-9"/>

      <!-- Body Section -->
      <form @submit.prevent="sendResetLink" class="text-left" v-if="!emailSent">
        <!-- Header Section -->
        <div class="mb-8 ">
          <!-- Header Text -->
          <h1 class="text-4xl">Bezpečnostné overenie</h1>
        </div>

        <!-- Username Input -->
        <p class="text-sm text-gray-400 py-1">Kód z e-mailu</p>
        <div class="mb-4">
          <input
              v-model="emailCode"
              autocomplete="one-time-code"
              type="text"
              class="w-420 px-4 py-2 border rounded-md"
          />
        </div>

        <div class="flex items-center mb-4 pt-4">
          <p class="text-sm">Pre prihlásenie zadajte overovací kód, ktorý sme zaslali na váš e-mail.</p>
        </div>

        <!-- horizontal line -->
        <div class="py-4">
          <hr class="mb-4">
        </div>

        <!-- Login Button -->
        <div class="mb-4">
          <button
              type="submit"
              class="w-420 bg-black text-white py-2 rounded-md"
          >
            Prihlásiť sa
          </button>
        </div>
      </form>
      <!--            <div v-if="emailSent">-->
      <!--              <div class="mb-8 ">-->
      <!--                &lt;!&ndash; Header Text &ndash;&gt;-->
      <!--                <h1 class="text-4xl">Overitť</h1>-->
      <!--              </div>-->
      <!--              <h2>Na Váš e-mail sme Vám zaslali link, prostredníctvom ktorého si môžete nastaviť nové heslo.</h2>-->
      <!--              &lt;!&ndash; horizontal line &ndash;&gt;-->
      <!--              <div class="py-4">-->
      <!--                <hr class="mb-4">-->
      <!--              </div>-->
      <!--              <div class="flex items-center mb-4 pt-4">-->
      <!--                <p class="text-sm">Spomenuli ste si na heslo?&nbsp</p><p class="text-sm forgot-password"><router-link to="/login">Prihláste sa</router-link></p>-->
      <!--              </div>-->
      <!--            </div>-->
    </div>


    <!-- Right Side: Video -->
    <div v-if="showVideo()" class="w-1/2 bg-black relative custom-css-mobile">
      <video autoplay loop muted class="w-full h-full absolute inset-0 object-cover">
        <source src="../assets/BallVideo.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/authStore";
import router from "@/router";

export default {
  name: "FAView",
  data() {
    return {
      emailCode: "",
      emailSent: false,
    };
  },
  methods: {
    showVideo() {
      return window.innerWidth > 950;
    },
    async sendResetLink() {
      let addedDate = false;
      let addedAgent = false;
      const actualUserAgent = window.navigator.userAgent;
      let lastLoginTimestamp = localStorage.getItem('lastLogin');
      // let lastLoginTimestamp = localStorage.getItem('lastLogin');

      try {
        const authStore = useAuthStore();
        const response = await authStore.verifyAuthCode(this.emailCode);

        if (response.message === "2FA kód bol overený") {
          const currentTimestamp = new Date().getTime();
          lastLoginTimestamp = new Date(lastLoginTimestamp).getTime();

          const isFiveDaysOld = (currentTimestamp - lastLoginTimestamp) > (5 * 24 * 60 * 60 * 1000); // 5 days in milliseconds
          if (isFiveDaysOld) {
            addedDate = true;
            lastLoginTimestamp = new Date().toISOString();
            localStorage.setItem('lastLogin', lastLoginTimestamp);
          }
          if (!localStorage.getItem('userAgent')) {
            addedAgent = true;
            // router.push('/fa');
            localStorage.setItem('userAgent', actualUserAgent);
          }


          if (!lastLoginTimestamp) {
            addedDate = true;
            // router.push('/fa');
            // If last login timestamp is not found, set it to current time
            lastLoginTimestamp = new Date().toISOString();
            localStorage.setItem('lastLogin', lastLoginTimestamp);
          } else {
            lastLoginTimestamp = new Date(lastLoginTimestamp).getTime();
          }
          await router.push("/");
        } else {
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.w-420 {
  width: 420px;
}

.forgot-password {
  color: #FF5C33;
  text-decoration: underline;
}

@media only screen and (max-width: 950px) {
  .custom-css-mobile {
    display: none;
  }

  .custom-css-mobile-width {
    width: 100%;

    form {
      width: 330px;

      input {
        width: 330px;
      }

      button {
        width: 330Px;
      }
    }
  }
}
</style>
