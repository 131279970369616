<template>
  <div class="flex h-screen bg-white">
    <!-- Left Side: Login Form -->
    <div class="w-1/2 p-8 relative flex flex-col justify-center items-center custom-css-mobile-width">
      <!-- Logo at the very top -->
      <img src="../assets/Logo_Clear.svg" alt="Logo" class="w-[100px] h-[100px] absolute top-0 left-0 mt-12 ml-9"/>

      <!-- Body Section -->
      <form @submit.prevent="submitLogin" class="text-left">
        <!-- Header Section -->
        <div class="mb-8 ">
          <!-- Header Text -->
          <h1 class="text-4xl">Prebieha údržba</h1>

        </div>
        <p class="text-2xl">Vážený partner, aktuálne prebieha údržba systémov. Za dočasné obmedzenie služieb sa ospravedlňujeme. Ďakujeme za pochopenie.</p>

        <!-- Username Input -->
<!--        <p class="text-sm text-gray-400 py-1">E-mail</p>-->
<!--        <div class="mb-4">-->
<!--          <CInputText-->
<!--              v-model="username"-->
<!--              type="text"-->
<!--              class="w-420 px-3 py-2 border rounded-md"-->
<!--          />-->
<!--        </div>-->

<!--        &lt;!&ndash; Password Input &ndash;&gt;-->
<!--        <p class="text-sm text-gray-400 py-1">Vaše heslo</p>-->
<!--        <div class="mb-4">-->
<!--          <CInputText-->
<!--              v-model="password"-->
<!--              type="password"-->
<!--              class="w-420 px-3 py-2 border rounded-md"-->
<!--          />-->
<!--        </div>-->

<!--        &lt;!&ndash; Remember Me Checkbox and Forgot Password Link &ndash;&gt;-->
<!--        <div class="flex items-center justify-between mb-4">-->
<!--          &lt;!&ndash;                    <div class="flex items-center">&ndash;&gt;-->
<!--          &lt;!&ndash;                      <CCheckbox inputId="remember-me" name="remember-me" binary :value="rememberMe" class="mr-2"/>&ndash;&gt;-->
<!--          &lt;!&ndash;                      <label for="remember-me" class="ml-2">Zapamätať prihlásenie</label>&ndash;&gt;-->
<!--          &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--          <p class="text-sm forgot-password">-->
<!--            <router-link to="/forgot-password">Zabudli ste heslo?</router-link>-->
<!--          </p>-->
<!--        </div>-->

        <!-- horizontal line -->
<!--        <div class="py-4">-->
<!--          <hr class="mb-4">-->
<!--        </div>-->

        <!-- Login Button -->
<!--        <div class="mb-4">-->
<!--          <button-->
<!--              type="submit"-->
<!--              class="w-420 bg-black text-white py-2 rounded-md hover-light-black"-->
<!--              style="padding: 17px 28px;"-->
<!--          >-->
<!--            Prihlásiť sa-->
<!--          </button>-->
<!--        </div>-->
      </form>
    </div>

    <!-- Right Side: Video -->
    <div v-if="showVideo()" class="w-1/2 bg-black relative custom-css-mobile">
      <video autoplay loop muted class="w-full h-full absolute inset-0 object-cover">
        <source src="../assets/BallVideo.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/authStore";

export default {
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false, // Initialize rememberMe to false
    };
  },
  methods: {
    async submitLogin() {
      try {
        // Access the authStore instance from main.ts
        const authStore = useAuthStore();

        const response = await authStore.login({
          username: this.username,
          password: this.password,
        });

        // this.$router.push('/');
      } catch (error) {
        // Handle login error (e.g., display an error message)
        // console.error("Login error:", error);
      }
    },
    showVideo() {
      return window.innerWidth > 950;
    }
  },
};
</script>

<style scoped>
/* Add any custom styles for this component */
.w-420 {
  width: 420px;
}

.forgot-password {
  color: #FF5C33;
  text-decoration: underline;
}

.hover-light-black:hover {
  background-color: #333; /* Use the desired light black color code */
}

@media only screen and (max-width: 950px) {
  .custom-css-mobile {
    display: none;
  }

  .custom-css-mobile-width {
    width: 100%;

    form {
      width: 330px;

      input {
        width: 330px;
      }

      button {
        width: 330Px;
      }
    }
  }
}
</style>
