<template>
  <div class="root-client-view xl:!h-full">
    <div class="root-client-view-first w-3/12 bg-white p-4 border-r-gray-200 border-r-2">
      <div class="flex flex-col" style="width: 100%;">
        <div class="items-center flex w-[283px] xl:w-full sm:max-v-full flex-col mt-4 mobile-panel">
          <div class="left-bar-header">
            <img v-if="false"
                 src="https://www.jeancoutu.com/globalassets/revamp/photo/conseils-photo/20160302-01-reseaux-sociaux-profil/photo-profil_301783868.jpg"
                 alt="" class="avatar-image">
            <h1 v-if="fullName">{{ fullName }}</h1>
            <!--            <p>CEO invertmens Co.</p>-->
          </div>
          <div class="flex flex-column gap-2 column-item" v-if="getClientStrategies">
            <CDropdown v-model="selectedClientRibbonStrategy" :options="getClientStrategies"
                       optionLabel="name"
                       optionValue="id"
                       show-clear
                       placeholder="Vyberte stratégiu" class=" md:w-14rem mt-4 ml-3 custom-dropdown "/>
          </div>
          <div v-if="client?.status === 'client'" class="margin-class" style="width: 95%;">
            <div class="left-bar-panel text-white">
              <h3>Objem majetku</h3>
              <div class="left-bar-panel-item">
                <template v-if="!loading && ribbonData !== undefined">
                  <p>{{ formatNumber(ribbonData?.portfolio_value) + " €" }}</p>
                  <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon cursor-pointer"
                                     @click="active = 2"/>
                </template>
                <template v-else>
                  <CSkeleton width="100%" height="3rem" borderRadius="16px" style="opacity: 0.4;"></CSkeleton>
                </template>
              </div>
            </div>
            <div class="left-bar-table pt-10">
              <!--              <h2>Výkonnosť</h2>-->
              <!--              <hr>-->
              <div class="left-bar-table-item">
                <h3>Kumulatívna výkonnosť</h3>
                <div class="flex items-center gap-2">
                  <p>{{ formatPercentage(ribbonData?.cumulative_performance) }}</p>
                  <i class="pi pi-question-circle" style="font-size: 1rem; color: gray; cursor: pointer;"
                     v-tooltip.bottom="'Kumulatívna výkonnosť klientskeho portfólia od začiatku jeho zmluvného vzťahu. Výkonnosť je počítaná metodikou TWRR (Time Weighted Rate of Return), ktorá zohľadňuje výkonnosť portfólia nezávisle od objemu a časovania vkladov alebo výberov.'"/>
                </div>
              </div>
              <hr>
              <div class="left-bar-table-item">
                <h3>Vklady</h3>
                <div class="flex items-center gap-2">
                  <p>{{ formatNumber(ribbonData?.total_deposits) + " €" }}</p>
                  <i class="pi pi-question-circle" style="font-size: 1rem; color: gray; cursor: pointer;"
                     v-tooltip.bottom="'Súčet všetkých vkladov klienta od jeho začiatku zmluvného vzťahu klienta do EUR. Pri prepočte je použitý oficiálny kurz ECB zo dňa vkladu.'"/>
                </div>
              </div>
              <hr>
              <div class="left-bar-table-item">
                <h3>Výbery</h3>
                <div class="flex items-center gap-2">
                  <p>{{ formatNumber(ribbonData?.total_withdrawals) + " €" }}</p>
                  <i class="pi pi-question-circle" style="font-size: 1rem; color: gray; cursor: pointer;"
                     v-tooltip.bottom="'Súčet všetkých výberov klienta od jeho začiatku zmluvného vzťahu prepočítaný do EUR. Pri prepočte je použitý oficiálny kurz ECB zo dňa vkladu.'"/>
                </div>
              </div>
              <hr>
              <!--              <h2>Zmlúvne Informácie</h2>-->
              <!--              <hr>-->
              <div class="left-bar-table-item">
                <h3>Klientom od</h3>
                <p>{{ formatDate(client?.created) }}</p>
              </div>
              <hr>
              <div class="left-bar-table-item">
                <h3>Rizikový profil</h3>
                <p>{{ client?.risk_profile?.name }}</p>
              </div>
              <hr>
              <div class="left-bar-table-item">
                <h3>Subjekt</h3>
                <p>{{ client?._company ? "Právnická osoba" : "Fyzická osoba" }}</p>
              </div>
              <hr>

            </div>
            <div class="left-bar-buttons pt-10">
              <button class="black-button mb-4" @click="visiblePaymentData = true">Inštrukcia na vklad</button>
              <button class="border-button mb-4" @click="showWithdrawalRequest">Žiadosť o výber</button>
              <button class="border-button" @click="showMoveStrategyRequest">Žiadosť o presun medzi stratégiami</button>
              <!--<div class="left-bar-buttons-item mt-4">
                <button class="gray-button">Predaj</button>
                <button class="black-button ml-4">Nákup</button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content area (10/12 width) -->
    <!--    {{client.status}}-->
    <div class="root-client-view-second w-9/12 h-full" v-if="client && client.status === 'client'">
      <CTabView v-model:activeIndex="active" :pt="{
    panelContainer:{
      style: 'background-color: #E5E4E3;',
      class:[
        'padding-for-mobile'
      ]
    },
    nav:{
      class:[
        'custom-width'
      ]
    }
  }">

        <CTabPanel header="Profil" class="bg-black root-ctab-panel"
                   :pt="{headerAction:{class:['padding-for-mobile']}}"
        >
          <ClientInformation/>
        </CTabPanel>
        <CTabPanel header="Zmluvy" :pt="{headerAction:{class:['padding-for-mobile']}}"
                   class="custom-gray-background padding-zero" style="padding: 0 !important;">
          <ClientContracts/>
        </CTabPanel>
        <CTabPanel header="Majetok" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientAssets/>
        </CTabPanel>
        <CTabPanel header="Transakcie" :pt="{headerAction:{class:['padding-for-mobile']}}"
                   class="custom-gray-background">
          <ClientTransactions/>
        </CTabPanel>
        <CTabPanel header="Obchody" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientTrades/>
        </CTabPanel>
        <CTabPanel v-if="shouldShowTab" header="Vývoj" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientPerformance/>
        </CTabPanel>
        <CTabPanel header="Dokumenty" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientDocuments/>
        </CTabPanel>
        -
        <CTabPanel header="Správy" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientMessages/>
        </CTabPanel>
      </CTabView>
    </div>
    <!--{{client.status}}-->
    <div class="root-client-view-second w-10/12 h-full" v-if="client && client.status == 'lead'">
      <CTabView v-model:activeIndex="active" :pt="{
                panelContainer:{
                   style: 'background-color: #E5E4E3;',
                    class:[
                        'padding-for-mobile'
                    ]}}">
        <CTabPanel header="Profil" class="bg-black root-ctab-panel"
                   :pt="{headerAction:{class:['padding-for-mobile']}}"
        >
          <ClientInformation/>
        </CTabPanel>
        <CTabPanel header="Zmluvy" :pt="{headerAction:{class:['padding-for-mobile']}}" class="custom-gray-background">
          <ClientContracts/>
        </CTabPanel>
        <CTabPanel header="Dokumenty" :pt="{headerAction:{class:['padding-for-mobile']}}">
          <ClientDocuments/>
        </CTabPanel>
      </CTabView>
    </div>
  </div>

  <CSidebar v-model:visible="visibleRight"
            position="right"
            header="Transakcia"
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="closeAndClear">
    <template #header>
      <h1 style="font-size: 1.5rem;">Žiadosť o výber</h1>
    </template>
    <div class="root-ctab-panel-items mt-7">
      <div class="flex flex-column gap-2 column-item">
        <label for="client" class="input-label">Klient</label>
        <CInputText id="client" aria-describedby="title-help" v-model="fullName"
                    disabled/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="fullName">
        <label for="selectedPaper" class="input-label">Stratégia</label>
        <CDropdown v-model="withdrawData.strategy" :options="clientsStore.getClientStrategies" filter
                   optionLabel="name"
                   placeholder="Vyberte stratégiu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="withdrawData.strategy">
        <label for="selectedPaper" class="input-label">Bankový účet</label>
        <CDropdown v-model="withdrawData.bank" :options="clientBankAccounts.content" filter
                   optionLabel="iban"
                   placeholder="Vyberte bankový účet" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="withdrawData.bank.id">
        <!--        {{withdrawData?.bank}}-->
        <label for="amount" class="input-label">Suma</label>
        <CInputText id="amount" aria-describedby="title-help" v-model="withdrawData.amount"
                    :disabled="withdrawData.allAmount"/>
        <div class="flex align-items-center pt-3">
          <CCheckbox v-model="withdrawData.allAmount" :binary="true"/>
          <label for="ingredient2" class="ml-2"> Celý objem </label>
        </div>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="withdrawData.bank.id">
        <label for="note" class="input-label">Poznámka</label>
        <CInputText id="note" aria-describedby="title-help" v-model="withdrawData.note"/>
      </div>
      <CButton label="Odoslať žiadosť" class="black-button mt-6 !align-self-center"
               v-if="withdrawData.bank.id" @click="sendWithdrawRequest"/>
    </div>
  </CSidebar>


  <CSidebar v-model:visible="visibleMoveStrategy"
            position="right"
            header="Transakcia"
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="closeAndClear">
    <template #header>
      <h1 style="font-size: 1.5rem;">Žiadosť o presun medzi stratégiami</h1>
    </template>
    <div class="root-ctab-panel-items mt-7">
      <div class="flex flex-column gap-2 column-item">
        <label for="client" class="input-label">Klient</label>
        <CInputText id="client" aria-describedby="title-help" v-model="fullName"
                    disabled/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="fullName">
        <label for="selectedPaper" class="input-label">Zdrojová stratégia</label>
        <CDropdown v-model="strategyFrom" :options="clientStrategies" filter
                   optionLabel="name"
                   placeholder="Vyberte stratégiu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="fullName">
        <label for="selectedPaper" class="input-label">Cieľová stratégia</label>
        <CDropdown v-model="strategyTo" :options="clientStrategiesFiltered" filter
                   optionLabel="name"
                   placeholder="Vyberte stratégiu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="strategyTo">
        <!--        {{withdrawData?.bank}}-->
        <label for="amount" class="input-label">Suma</label>
        <CInputText id="amount" aria-describedby="title-help" v-model="withdrawData.amount"
                    :disabled="withdrawData.allAmount"/>
        <div class="flex align-items-center pt-3">
          <CCheckbox v-model="withdrawData.allAmount" :binary="true"/>
          <label for="ingredient2" class="ml-2"> Celý objem </label>
        </div>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="strategyTo">
        <label for="note" class="input-label">Poznámka</label>
        <CInputText id="note" aria-describedby="title-help" v-model="withdrawData.note"/>
      </div>
      <CButton label="Odoslať žiadosť" class="black-button mt-6 !align-self-center"
               v-if="strategyTo" @click="sendMoveStrategyRequest"/>
    </div>
  </CSidebar>

  <CSidebar v-model:visible="visiblePaymentData"
            position="right"
            style="width: 500px; padding: 1rem;"
            :pt="{closeButton: {class: ['close-button']}}"
            @hide="close">
    <template #header>
      <h1 style="font-size: 1.5rem;">Vklad prostriedkov</h1>
    </template>

    <div class="root-ctab-panel-items mt-7">
      <!--            <h1 class="font-bold text-2xl pb-2">Vklad prostriedkov</h1>-->
      <!--            <p class="pb-2">Pre zobrazenie platobných údajov vyberte prosím stratégiu a menu, v ktorej si želáte vykonať vklad peňažných-->
      <!--              prostriedkov.</p>-->
      <div class="flex flex-column gap-2 column-item">
        <label for="selectedPaper" class="input-label">Klient</label>
        <CInputText id="date" aria-describedby="title-help" v-model="fullName"
                    disabled/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="fullName">
        <label for="selectedPaper" class="input-label">Stratégia</label>
        <CDropdown v-model="selectedClientStrategy" :options="clientsStore.getClientStrategies" filter
                   optionLabel="name"
                   placeholder="Vyberte stratégiu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedClientStrategy">
        <label for="selectedPaper" class="input-label">Mena</label>
        <CDropdown v-model="selectedCurrency" :options="currencyList" filter optionLabel="name"
                   placeholder="Vyberte menu" class="w-full md:w-14rem mb-2"/>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedCurrency">
        <label for="title" class="input-label">Názov banky</label>
        <span>{{ bankInfo.bankName }}</span>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedCurrency">
        <label for="title" class="input-label">IBAN</label>
        <span>{{ bankInfo.iban }}</span>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedCurrency">
        <label for="title" class="input-label">Variabilný symbol</label>
        <span>{{ client.birthnumber }}</span>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedCurrency">
        <label for="title" class="input-label">Špecifický symbol</label>
        <span>{{ bankInfo.specificSymbol }}</span>
      </div>
      <div class="flex flex-column gap-2 column-item" v-if="selectedCurrency">
        <label for="title" class="input-label">BIC</label>
        <span>{{ bankInfo.bic }}</span>
      </div>
      <CButton label="Odoslať údaje e-mailom klientovi" class="black-button mt-6 !align-self-center"
               v-if="selectedCurrency" @click="sendPaymentData()"/>
    </div>
  </CSidebar>
</template>

<script>
import {computed, onBeforeMount, onBeforeUnmount, ref, watch, onMounted} from 'vue';
import {useClientsStore} from '@/stores/clientsStore';
import {useRequestStore} from "@/stores/requestsStore";
import ClientDocuments from "@/components/Clients/ClientDocuments.vue";
import ClientInformation from "@/components/Clients/ClientInformation.vue";
import ClientTransactions from "@/components/Clients/ClientTransactions.vue";
import ClientAssets from "@/components/Clients/ClientAssets.vue";
import ClientTrades from "@/components/Clients/ClientTrades.vue";
import ClientMessages from "@/components/Clients/ClientMessages.vue";
import ClientContracts from "@/components/Clients/ClientContracts.vue";
import ClientPerformance from "@/components/Clients/ClientPerformance.vue";

export default {
  name: "ClientView",
  components: {
    ClientContracts,
    ClientInformation,
    ClientDocuments,
    ClientAssets,
    ClientTransactions,
    ClientTrades,
    ClientMessages,
    ClientPerformance
  },
  props: {
    uuid: String,
  },
  setup(props) {
    const active = ref(0);
    const clientsStore = useClientsStore();
    const requestStore = useRequestStore();
    const clientBankAccounts = computed(() => clientsStore.getBankAccounts);
    const clientStrategies = computed(() => clientsStore.getClientStrategies);

    onBeforeMount(async () => {
      await clientsStore.fetchClient(props.uuid);

      const year_ago = new Date();
      year_ago.setFullYear(year_ago.getFullYear() - 1);

      if (clientsStore.client && clientsStore.client?.status === 'client') {
        clientsStore.fetchClientBankAccounts(props.uuid);
        clientsStore.fetchClientPerformance(props.uuid, year_ago.toISOString().split('T')[0], false);
        clientsStore.fetchClientSecurities(props.uuid);
        clientsStore.fetchCashAccounts(props.uuid);
        clientsStore.fetchClientDocuments(props.uuid);
        clientsStore.fetchClientTrades(props.uuid);
        clientsStore.fetchClientTransactions(props.uuid);
        clientsStore.fetchClientMessages(props.uuid, true);
        clientsStore.fetchClientRibbonData(props.uuid, 0);
        clientsStore.fetchClientContracts(props.uuid, true);
        clientsStore.fetchClientStrategies(props.uuid, false);
      } else {
        clientsStore.fetchLeadBankAccounts(props.uuid);
        clientsStore.fetchLeadContracts(props.uuid, false);
      }
    });


    const client = computed(() => clientsStore.client)
    const ribbonData = computed(() => clientsStore.clientRibbonData)
    const fullName = computed(() => client.value?.name + ' ' + client.value?.surname);
    const clientCashTotal = computed(() => clientsStore.getClientTotalCash);
    const clientAssetsTotal = computed(() => clientsStore.getClientTotalAssets);
    const getClientStrategies = computed(() => clientsStore.getClientStrategies || []);
    const visiblePaymentData = ref(false);
    const selectedClientStrategy = ref(undefined);
    const selectedClientRibbonStrategy = ref(undefined);
    const selectedCurrency = ref(undefined);
    const visibleRight = ref(false);
    const visibleMoveStrategy = ref(false);
    const bankInfo = ref({});
    const strategyFrom = ref(undefined);
    const strategyTo = ref(undefined);
    const clientStrategiesFiltered = computed(() => {
      return clientStrategies.value.filter((strategy) => strategy.id !== strategyFrom.value?.id);
    });


    const shouldShowTab = ref(false);

    onMounted(() => {
      updateShouldShowTab();
      window.addEventListener('resize', updateShouldShowTab);
    });

    const updateShouldShowTab = () => {
      shouldShowTab.value = window.innerWidth > 728;
    };

    watch([selectedCurrency, selectedClientStrategy, fullName], ([newCurrency, newClientStrategy], [oldCurrency, oldClientStrategy]) => {
      updateBankInfo(newCurrency?.name, newClientStrategy?.specific_symbol);
    });

    watch(selectedClientRibbonStrategy, (newValue) => {
      clientsStore.fetchClientRibbonData(props.uuid, newValue || 0);
    });

    const currencyList = [
      {
        name: "EUR"
      },
      {
        name: "BGN"
      }
    ]

    const showWithdrawalRequest = (transaction) => {
      // Show the sidebar
      visibleRight.value = true;
    };

    const showMoveStrategyRequest = () => {
      // Show the sidebar
      visibleMoveStrategy.value = true;
    };

    const formatNumber = (num) => {
      if (typeof num !== 'number' || isNaN(num)) {
        return "0,00";  // Alebo môžeš vrátiť prázdny reťazec ""
      }

      const parts = num.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    };


    const formatPercentage = (value) => {
      if (typeof value !== 'number' || isNaN(value)) {
        return "0,00 %";  // Alebo môžeš vrátiť prázdny reťazec ""
      }

      return new Intl.NumberFormat('sk-SK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value) + ' %';
    };


    const formatDate = (transactionTime) => {
      // Parse the incoming date string
      const date = new Date(transactionTime);

      // Define options for formatting
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      // Format the date to SK format
      return date.toLocaleString("sk-SK", options);
    };

    const updateBankInfo = (currency, specificSymbol) => {
      if (currency === "EUR") {
        bankInfo.value = {
          bankName: "Tatra banka, a.s.",
          iban: "SK61 1100 0000 0029 4504 5506",
          variableSymbol: client.value.birthnumber,
          specificSymbol: specificSymbol,
          bic: "TATRSKBX"
        };
      } else {
        bankInfo.value = {
          bankName: "UniCredit Bulbank",
          iban: "BG73 UNCR 7000 1523 4392 40",
          variableSymbol: client.value.birthnumber,
          specificSymbol: specificSymbol,
          bic: "UNCRBGSF"
        };
      }
    };

    const  closeAndClear = () => {
      visibleMoveStrategy.value = false;
      strategyFrom.value = undefined;
      strategyTo.value = undefined;
      withdrawData.value = {
        amount: 0,
        allAmount: false,
        bank: {},
        strategy: undefined,
        note: ''
      }
    }

    const sendPaymentData = async () => {
      await clientsStore.sendPaymentDetailsEmail(bankInfo.value.variableSymbol, bankInfo.value.specificSymbol, bankInfo.value.iban, bankInfo.value.bic, props.uuid);
      visibleRight.value = false;
    };

    const sendMoveStrategyRequest = async () => {
      await requestStore.moveStrategyRequest(props.uuid, strategyFrom.value.id, strategyTo.value.id,  withdrawData?.value.amount, withdrawData?.value.allAmount, withdrawData?.value.note);
      visibleMoveStrategy.value = false;
    }

    const withdrawData = ref({
      amount: 0,
      allAmount: false,
      bank: {},
      strategy: undefined,
      note: ''
    })

    const sendWithdrawRequest = async () => {
      await requestStore.withdrawRequest(props.uuid, withdrawData?.value.strategy.id, 1, withdrawData?.value.amount, withdrawData?.value.allAmount, withdrawData?.value.bank.id, withdrawData?.value.note);
      visibleRight.value = false;
      withdrawData.value = {
        amount: 0,
        allAmount: false,
        bank: {},
        strategy: undefined,
        note: ''
      }
    }

    onBeforeUnmount(() => {
      clientsStore.client = undefined;
      clientsStore.clientBankAccounts = undefined;
      clientsStore.clientPerformance = undefined;
      clientsStore.clientSecurities = undefined;
      clientsStore.clientCashAccounts = undefined;
      clientsStore.clientDocuments = undefined;
      clientsStore.clientTrades = undefined;
      clientsStore.clientTransactions = undefined;
      clientsStore.clientMessages = undefined;
      clientsStore.clientContracts = undefined;
      clientsStore.clientStrategies = undefined;
      clientsStore.leadBankAccounts = undefined;
      clientsStore.leadContracts = undefined;
      clientsStore.totalCash = 0;
      clientsStore.totalAssets = 0;
      clientsStore.clientRibbonData = undefined;
    });


    return {
      client,
      ribbonData,
      fullName,
      clientCashTotal,
      clientAssetsTotal,
      formatNumber,
      active,
      formatDate,
      visibleRight,
      visibleMoveStrategy,
      showMoveStrategyRequest,
      sendMoveStrategyRequest,
      showWithdrawalRequest,
      visiblePaymentData,
      clientsStore,
      selectedClientStrategy,
      selectedClientRibbonStrategy,
      selectedCurrency,
      currencyList,
      bankInfo,
      updateBankInfo,
      sendPaymentData,
      withdrawData,
      clientBankAccounts,
      sendWithdrawRequest,
      shouldShowTab,
      formatPercentage,
      getClientStrategies,
      clientStrategies,
      clientStrategiesFiltered,
      strategyFrom,
      strategyTo,
      closeAndClear
    };
  },

};
</script>

<style>
@media only screen and (max-width: 1372px) {
  .padding-for-mobile {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 600px) {
  .custom-width {
    width: 700px;
  }
}
</style>

<style lang="scss" scoped>
.custom-dropdown {
  border-radius: 60px; /* Adjust the value as needed */
  border-color: #000000;
  align-self: center; /* Align with the card under */
}

.avatar-image {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.padding-zero {
  padding: 0 !important;
}

.background-color-input {
  background-color: #F2F1F1 !important;
  color: black;
}

.tab-panel-custom {
  background-color: red !important;
}


.custom-gray-background {
  background-color: black;
}

.left-bar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .left-bar-buttons-item {
    display: flex;
    justify-content: space-between;
  }

  button {
    padding: 10px 0;
    border-radius: 10px;
    min-width: 85px;
    font-size: 14px;
    width: 100%;
  }

  .delete-button {
    margin-top: 300px;
    border: 1px solid #949494;
  }
}

.left-bar-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    font-weight: bold;
  }

  .left-bar-table-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center !important;

    h3 {
      color: #949494;
    }

    p {
      font-weight: bold;
    }
  }
}

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.left-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    height: 115px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    color: #949494;
  }
}

hr {
  margin: 10px 0;
}

.root-client-view {
  display: flex;
}

@media only screen and (max-width: 1372px) {
  .root-client-view {
    flex-direction: column;
    align-items: center;
    height: 550px;
  }
  .root-client-view-first {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .root-client-view-second {
    width: 100% !important;
    margin-top: 10px;
    flex-direction: column;
    display: flex;
  }
  .mobile-panel {
    width: 100%;
  }
  .margin-class {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.delete-button {
  margin-top: 10px !important;
}
</style>
