<template>
  <div class="root-client-view">
    <div class="root-client-view-first w-3/12 bg-white p-4 border-r-gray-200 border-r-2 mobile-height"
         style="min-height: 1080px;">
      <div class="flex flex-col items-center">
        <div class="items-center flex w-[283px] xl:w-full sm:max-v-full flex-col mt-4 mobile-panel">
          <div class="left-bar-header">
            <!--             <img-->
            <!--                src="https://www.jeancoutu.com/globalassets/revamp/photo/conseils-photo/20160302-01-reseaux-sociaux-profil/photo-profil_301783868.jpg"-->
            <!--                alt="" class="avatar-image">  -->
            <div class="avatar">
              <div class="avatar-circle">{{ initials }}</div>
            </div>
            <h1>{{ fullName }}</h1>
            <div class="small-reg-pill gap-1" v-tooltip.bottom="'Registračné číslo pre online onboarding klientov'"
                 v-if="agent?.reg_number !== null">
              <p>{{ agent?.reg_number }}</p>
              <i class="pi pi-question-circle" style="font-size: 0.8rem; color: gray;"/>
            </div>
          </div>
          <div class="left-bar-panel  m-10 text-white">
            <h3>Objem majetku v správe</h3>
            <div class="left-bar-panel-item">
              <p>{{ formatNumber(agent?.agent_aum === null ? 0 : agent?.agent_aum) + " €" }}</p>
              <font-awesome-icon icon="fa-solid fa-arrow-right" size="lg" class="menu-icon cursor-pointer"/>
            </div>
          </div>
          <div class="left-bar-table" v-if="agent">
            <h2>Informácie</h2>
            <hr>
            <div class="left-bar-table-item">
              <h3>Užívateľské meno</h3>
              <p>{{ agent?.username }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item">
              <h3>E-mail</h3>
              <p>{{ agent?.email }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item">
              <h3>Telefón</h3>
              <p>{{ agent?.phone }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item">
              <h3>Pozícia</h3>
              <p>{{ agent?.position }}</p>
            </div>
            <hr>
            <div class="left-bar-table-item">
              <h3>Povoliť prihlásenie</h3>
              <CInputSwitch v-model="flipAccess"   @input="changeFlipAccess($event)"/>
              <!--              <p>{{ agent?.position }}</p>-->
            </div>
            <hr>
            <div class="left-bar-table-item">
              <h3>Administrátorský prístup</h3>
              <CInputSwitch v-model="flipAdmin"   @input="changeFlipAdmin($event)"/>
            </div>
          </div>
          <div class="left-bar-buttons pt-10">
            <button class="black-button mb-4" @click="toggleTransferClients" v-if="!transferClientsVisible">Presun
              klientov
            </button>
            <button class="border-button" @click="toggleDeleteAgent">Zrušiť prístup</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Content area (10/12 width) -->
    <div class="root-client-view-second w-10/12 h-full ">
      <CTabView v-if="!transferClientsVisible && !deleteAgentVisible"
                v-model:activeIndex="active" :pt="{
                panelContainer:{
                   style: 'background-color: #E5E4E3;',
                    class:[
                        'padding-for-mobile'
                    ]}}">
        <CTabPanel header="Prehľad" class="bg-black root-ctab-panel"
                   :pt="{headerAction:{class:['padding-for-mobile']}}"
        >
          <AgentOverview/>
        </CTabPanel>
        <CTabPanel header="Klienti" :pt="{headerAction:{class:['padding-for-mobile']}}" class="custom-gray-background">
          <AgentClients/>
        </CTabPanel>
      </CTabView>

      <div v-if="transferClientsVisible" class="w-auto">
        <div class="transfer-header flex items-center justify-between px-7">
          <h1>Presun klientov</h1>
          <div class="flex items-center space-x-4 pl-4">
            <p class="back-button cursor-pointer" @click="toggleTransferClients">
              Zavrieť
            </p>
          </div>
        </div>
        <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8 w-1/2 mx-8 my-9 w-auto">
          <!--<p class="upozornenie">Vyberte klientov, ktorých si prajete presunúť do starostlivosti ďalšieho
            agenta.</p>-->
          <CPickList v-model="clients" dataKey="uuid" listStyle="height:700px" responsive class="mb-5"
                     :showSourceControls="false" :showTargetControls="false">
            <template #sourceheader> Klienti sprostredkovateľa</template>
            <template #targetheader> Vybraní klienti</template>
            <template #item="slotProps">
              <div class="flex flex-row justify-between align-items-center">
                <div class="flex flex-col align-items-center">
                  <span class="font-bold">{{ slotProps.item.name }} {{ slotProps.item.surname }}</span>
                  <span>{{ formatDate(slotProps.item.birthday) }}</span>
                </div>
                <CChip :label="slotProps.item.risk_profile.name" :pt="{root : {class: ['risk-profile-chip']}}"
                       v-if="slotProps.item.risk_profile != null"/>
              </div>
            </template>
          </CPickList>
          <div class="flex flex-column gap-2 column-item">
            <label for="selectedClient" class="input-label">Cieľový agent</label>
            <CDropdown v-model="newTargetAgent" :options="agentsList" filter
                       optionLabel="fullName"
                       placeholder="Vyberte agenta..." class="w-1/3 md:w-14rem mb-2"/>
          </div>
          <CButton label="Potvrdiť presun" class="black-button mt-3" @click="transferCheck = true"/>
          <p class="upozornenie mt-3">UPOZORNENIE: Zmena agenta je záväzná, preto si skontrolujte prosím výber!</p>
        </div>
      </div>

      <div v-if="deleteAgentVisible" class="w-auto">
        <div class="transfer-header flex items-center justify-between px-7">
          <h1>Zrušiť prístup agentovi</h1>
          <div class="flex items-center space-x-4 pl-4">
            <p class="back-button cursor-pointer" @click="toggleDeleteAgent">
              Zavrieť
            </p>
          </div>
        </div>
        <div class="p-7 bg-white rounded-lg shadow-md m-3 mb-8 w-1/2 mx-8 my-9 w-auto text-center">
          <p class="upozornenie text-center">Ste si istý, že chcete zrušiť prístup pre uvedeného sprostredkovateľa?
            Obratom mu bude zablokovaný účet do platformy.</p>
          <p class="upozornenie mt-3 text-center">Zrušiť prístup je možné len v tom prípade, že sprostredkovateľ nemá
            pridelených do starostlivosti žiadnych klientov!</p>
          <CButton label="Zrušiť prístup" class="black-button mt-6 !align-self-center" @click="deleteAgentFunction"/>
        </div>
      </div>

      <CDialog v-model:visible="transferCheck" modal>
        <div class="dialog-content">
          <p class="upozornenie mt-3">UPOZORNENIE: Zmena agenta je záväzná, preto si skontrolujte prosím výber!</p>
          <p class="upozornenie text-center">Ste si istý, že chcete presunúť klientov na iného agenta?</p>
          <CButton label="Potvrdiť presun" class="black-button mt-3" @click="transferButton"/>
        </div>
      </CDialog>

    </div>
  </div>

</template>

<script>
import {computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref} from 'vue';
import {useAgentStore} from '@/stores/agentStore';
import {useAgentsStore} from '@/stores/agentsStore';
import AgentClients from "@/components/Agents/AgentClients.vue";
import AgentOverview from "@/components/Agents/AgentOverview.vue";

export default {
  name: "AgentView",
  components: {AgentOverview, AgentClients},
  props: {
    uuid: String,
  },
  setup(props) {
    const agentStore = useAgentStore();
    const agentsStore = useAgentsStore();
    const clients = ref(null);
    const newTargetAgent = ref(undefined);
    const selectedClients = ref([]);
    const currentInstance = getCurrentInstance();
    //filter our the agent that is being viewed
    const agentsList = computed(() => agentsStore
        .getFormattedAgentsList.filter(agent => agent.uuid !== props.uuid))


    const transferCheck = ref(false);

    onBeforeMount(() => {
      agentStore.fetchAgentInfo(props.uuid);
      agentStore.fetchAgentClients(props.uuid);
      agentStore.fetchAgentNoOfClients(props.uuid);
      agentStore.fetchAgentAum(props.uuid);
      agentStore.fetchAgentProvision(props.uuid);
      agentStore.fetchAgentAumByStrategy(props.uuid);
      agentStore.fetchAgentTopClients(props.uuid);
      agentsStore.fetchAgentsList();
    });

    onBeforeUnmount(() => {
      clients.value = null;
      agentStore.deleteAgentInfo();
    });

    onMounted(async () => {
      await agentStore.fetchAgentInfo(props.uuid);
      await agentStore.fetchAgentClients(props.uuid);
      clients.value = [agentStore.agentClients.content, []];
      flipAccess.value = agentStore.agentInfo.flip_access;
      flipAdmin.value = agentStore.agentInfo.is_admin;
    });

    const changeFlipAccess = async (event) => {
      await agentStore.setFlipAccess(props.uuid, flipAccess.value);
    }

    const changeFlipAdmin = async (event) => {
      await agentStore.setFlipAdmin(props.uuid, flipAdmin.value);
    }

    const agent = computed(() => agentStore.agentInfo);
    const fullName = computed(() => {
      if (agent?.value && agent?.value.name) {
        return agent?.value.name + ' ' + agent?.value.surname
      }
    });
    const transferClientsVisible = ref(false);
    const deleteAgentVisible = ref(false);
    const flipAccess = ref(false);
    const flipAdmin = ref(false);
    const toggleTransferClients = () => {
      // Show the sidebar
      transferClientsVisible.value = !transferClientsVisible.value;
    };
    const toggleDeleteAgent = () => {
      // Show the sidebar
      deleteAgentVisible.value = !deleteAgentVisible.value;
    };

    const routeUuid = computed(() => {
      // Get the current route object
      const route = currentInstance.appContext.config.globalProperties.$route;
      // Access the params property of the route to get URL parameters
      return route.params.uuid;
    });


    const transferButton = () => {
      console.log("Transfer button clicked")
      agentStore.moveClients(routeUuid.value, newTargetAgent?.value.uuid, clients.value[1])
      transferCheck.value = false;
    }

    const formatNumber = (num) => {
      if (num === undefined) {
        return ""; // or any other default value or behavior
      }

      const parts = num?.toFixed(2).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    };


    const formatDate = (transactionTime) => {
      // Parse the incoming date string
      const date = new Date(transactionTime);

      // Define options for formatting
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      // Format the date to SK format
      return date.toLocaleString("sk-SK", options);
    };

    const deleteAgentFunction = async () => {
      try {
        await agentStore.deleteAgent(props.uuid);
        console.log(props.uuid)
      } catch (error) {
        console.error("Failed to delete agent:", error);
      }
    }

    const initials = computed(() => {
      if (fullName.value) {
        const names = fullName.value.split(' ');
        return names.map(name => name.charAt(0)).join('').toUpperCase();
      } else {
        return '';
      }
    });

    return {
      agent,
      initials,
      fullName,
      formatNumber,
      formatDate,
      transferClientsVisible,
      toggleTransferClients,
      deleteAgentVisible,
      clients,
      transferButton,
      newTargetAgent,
      toggleDeleteAgent,
      agentsList,
      deleteAgentFunction,
      transferCheck,
      flipAccess,
      changeFlipAccess,
      flipAdmin,
      changeFlipAdmin
    };
  },

};
</script>

<style lang="scss" scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circle {
  font-size: 20px;
  color: #fff;
}

.upozornenie {
  font-size: 0.85rem;
  color: gray;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.risk-profile-chip {
  background-color: transparent !important;
  border-color: black !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 100px !important;
  color: black !important;
  font-size: 0.8rem;
  align-self: center;
  height: 2rem;
}

.column-item {
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.avatar-image {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.small-reg-pill {
  font-size: 0.8rem !important;
  padding: 0.20rem 0.6em !important;
  border-radius: 50px !important;
  background-color: #F2F1F1 !important;
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.background-color-input {
  background-color: #F2F1F1 !important;
  color: black;
}

.tab-panel-custom {
  background-color: red !important;
}

.transfer-header {
  background-color: white;
  width: 100%;
  height: 86px;

  h1 {
    font-size: 20px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 1372px) {
  .padding-for-mobile {
    padding: 5px !important;
  }
}

.custom-gray-background {
  background-color: black;
}

.left-bar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .left-bar-buttons-item {
    display: flex;
    justify-content: space-between;
  }

  button {
    padding: 10px 0;
    border-radius: 10px;
    min-width: 85px;
    font-size: 14px;
    width: 100%;
  }

  .delete-button {
    margin-top: 300px;
    border: 1px solid #949494;
  }
}

.left-bar-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    font-weight: bold;
  }

  .left-bar-table-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center !important;

    h3 {
      color: #949494;
    }

    p {
      font-weight: bold;
    }
  }
}

.left-bar-panel {
  background-color: #FF5C33;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  .left-bar-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 28px;
      font-weight: 600;
    }
  }
}

.left-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    height: 115px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    color: #949494;
  }
}

hr {
  margin: 10px 0;
}

.root-client-view {
  display: flex;
}

@media only screen and (max-width: 1372px) {
  .root-client-view {
    display: block !important;
  }
  .root-client-view-first {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .root-client-view-second {
    width: 100% !important;
  }

  .mobile-panel {
    width: 100vw;
  }
  .mobile-height {
    min-height: 100% !important;
  }
}

.delete-button {
  margin-top: 10px !important;
}

.left-bar-panel, .left-bar-table, .left-bar-buttons {
  width: 90% !important;
}

</style>
