import './assets/main.css'
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {plugin, defaultConfig} from '@formkit/vue'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import './index.css'
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
// @ts-ignore
import {createI18n} from 'vue-i18n'
import * as Sentry from "@sentry/vue";
// @ts-ignore
import config from "../formkit.config";
import dotenv from 'dotenv';
// @ts-ignore
import App from './App.vue'
import router from './router'
// import Tailwind_PT from "./assets/passthrough/tailwind";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import '@/assets/fonts/fonts.css';

// import "primeflex/primeflex.css";
// import Icon from '@mui/material/Icon';
import 'primeicons/primeicons.css';

import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputText from 'primevue/inputtext'
import Badge from "primevue/badge";
import Avatar from "primevue/avatar";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import SelectButton from "primevue/selectbutton";
import Sidebar from "primevue/sidebar";
import Calendar from "primevue/calendar";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import FileUpload from "primevue/fileupload";
import Checkbox from "primevue/checkbox";
import OverlayPanel from  "primevue/overlaypanel";
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { toast } from 'vue3-toastify';



import VueApexCharts from "vue3-apexcharts";

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faSignOutAlt,
    faColumns,
    faUsers,
    faChartColumn,
    faHandHoldingUsd,
    faCogs,
    faFileInvoiceDollar,
    faChartLine,
    faEnvelope,
    faArrowRight,
    faArrowLeft,
    faEllipsisV,
    faEllipsisH,
    faDownload,
    faEye,
    faCalendar,
    faXmark,
    faCheck,
    faCheckDouble,
    faFileMedical,
    faQuestionCircle,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons'

library.add(faFileMedical, faQuestionCircle, faSignOutAlt, faColumns, faUsers, faChartColumn, faHandHoldingUsd, faCogs, faFileInvoiceDollar, faChartLine, faEnvelope, faArrowRight, faArrowLeft, faEllipsisV, faEllipsisH, faDownload, faEye, faCalendar, faXmark, faCheck, faCheckDouble, faFilePdf)

// dotenv.config();
function loadLocaleMessages() {
    const locales = import.meta.globEager('./locales/*.json');
    const messages: Record<string, any> = {};
    for (const path in locales) {
        const locale = path.match(/([A-Za-z0-9-_]+)\.json$/i)![1];
        messages[locale] = locales[path];
    }
    return messages;
}

const i18n = createI18n({
    legacy: false, // Vue 3
    locale: 'sk', // Default locale
    fallbackLocale: 'sk', // Fallback locale
    messages: loadLocaleMessages(),
})

const app = createApp(App)
// app.component("CToast", toast);
app.component('CButton', Button);
app.component('CDataTable', DataTable);
app.component('CDataView', DataView);
app.component('CColumn', Column);
app.component('CDropdown', Dropdown);
app.component('CChip', Chip);
app.component('CChips', Chips);
app.component('CTabView', TabView);
app.component('CTabPanel', TabPanel);
app.component('CChart', Chart);
app.component('CAccordion', Accordion);
app.component('CAccordionTab', AccordionTab);
app.component('CInputText', InputText);
app.component('CInputNumber', InputNumber);
app.component('CInputMask', InputMask);
app.component('CBadge', Badge);
app.component('CAvatar', Avatar);
app.component('CCard', Card);
app.component('CInputSwitch', InputSwitch);
app.component('CSelectButton', SelectButton);
app.component('CSidebar', Sidebar);
app.component('CCalendar', Calendar);
app.component('CMessage', Message);
app.component('CMenu', Menu);
app.component('CSkeleton', Skeleton);
app.component('CDialog', Dialog);
app.component('CMultiSelect', MultiSelect);
app.component('CFileUpload', FileUpload);
app.component('CCheckbox', Checkbox);
app.component('CPickList', PickList)
app.component('COverlayPanel', OverlayPanel)
app.directive('tooltip', Tooltip);

app.component('font-awesome-icon', FontAwesomeIcon)

// app.component('CIcon', Icon);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    // dsn: "https://5b8dc45051c69a56440219ad36d71141@o4505425501683712.ingest.us.sentry.io/4506971456143360",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["*", "10.52.52.6",  "10.52.52.6:8094", "flip-partner.wem.sk", /^https:\/\/flip-partner\.wem\.sk/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pinia = createPinia()
app.use(PrimeVue, {unstyled: false});
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(VueApexCharts);
app.use(plugin, defaultConfig(config))
app.use(Vue3Toastify, {
    autoClose: 3000,
} as ToastContainerOptions);
pinia.use(piniaPluginPersistedState)


import {useApiStore} from '@/stores/apiStore';
import {useAuthStore} from '@/stores/authStore';
import Message from "primevue/message";
import DataView from "primevue/dataview";
import InputNumber from "primevue/inputnumber";
import Skeleton from "primevue/skeleton";
import InputMask from "primevue/inputmask";
import Tooltip from "primevue/tooltip";
import PickList from "primevue/picklist";

const apiStore = useApiStore();
const authStore = useAuthStore();

app.mount('#app')
